import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
//import Continuum from "../../clients/continuum";
import PubSub from "../../pubsub";
import "./dialogs.css";
//import { JQ, isEmpty } from "../../utils/helpers";

const maxImageWidth = 64;

const styles = theme => ({
  title: {
    flexGrow: 1,
    marginTop: theme.spacing.unit
  },
  fab: {
    width: 70,
    height: 70,
    backgroundColor: "transparent",
    margin: "auto",
    padding: theme.spacing.unit
  },
  paper: {
    height: 40,
    marginTop: theme.spacing.unit * 2
  },
  input_container: {
    height: 40,
    marginTop: theme.spacing.unit * 2
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%"
  },
  dialog_message: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "90%",
    paddingBottom: 0
  },
  nomargin: {
    margin: 0,
    padding: 0
  },
  dialog_content: {
    display: "flex",
    flexFlow: "wrap",
    flexDirection: "row",
    justifyContent: "spaceBetween",
    margin: theme.spacing.unit * 2,
    flexGrow: 1
  },
  noDisplay: {
    display: "none"
  },
  dialog_root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "spaceBetween",
    //maxWidth: 350,
    //maxHeight: 500,
    //overflow: "hidden"
    height: 800
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "spaceBetween"
  },
  textField: {
    //display: "block",
    width: "100%"
  },
  imageCard: {
    display: "flex",
    position: "relative",
    height: maxImageWidth,
    maxWidth: maxImageWidth,
    minWidth: maxImageWidth,
    float: "left"
  },
  cover: {
    objectFit: "contain",
    alignItems: "center"
  },
  coverCard: {
    display: "flex",
    position: "relative",
    height: maxImageWidth,
    maxWidth: "100%",
    marginTop: theme.spacing.unit * 2,
    float: "left",
    objectFit: "cover"
  },
  row: {
    marginTop: theme.spacing.unit * 2,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%"
  },
  rowItem: {
    margin: 30
  },
  column: {
    marginTop: theme.spacing.unit * 2,
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "space-between",
    padding: theme.spacing.unit
  }
});

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <div>{children}</div>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column"
    //padding: theme.spacing.unit * 5,
    // height: 100,
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit
  }
}))(MuiDialogActions);

class SaveDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      message: "",
      error: "",
      title: props.title,
      subtitle: props.subtitle,
      obj: props.obj,
      //selected content objs
    };
    PubSub.subscribe(this, PubSub.TOPIC_OPEN_SAVE, (msg, data) => {
      this.setState({
        open: true,
        checked: [],
        videos: [],
        error: "",
        message: "",
        obj: data
      });

      console.log("Save open: " + JSON.stringify(this.state));
    });
    PubSub.subscribe(this, PubSub.TOPIC_CLOSE_DIALOG, (msg, data) => {
      this.setState({ open: false });
    });
  }

  componentDidMount() {}

  handleConfirm = async event => {
    this.handleClose(event);
  };

  handleClose = event => {
    this.setState({ open: false });
  };

  handleFileChange = file => {
    this.setState({
      file
    });
  };


  renderContents = () => {
    const { classes } = this.props;

    return (
      <div position="relative">
        <Paper className={classes.input_container} elevation={1}>
          <div className={classes.row}>
                <InputBase
                    readonly
                />
                <input
                    ref={'file-download'}
                    type='file'
                    className={classes.noDisplay}
                    accept="image/*"
                    onChange={this.handleFileChange}
                />
                <Button
                    onClick={e => {
                        this.refs['file-download'].click()
                    }}
                >
                </Button>
          </div>
        </Paper>
      </div>
    );
  };

  render() {
    const {
      open,
      message,
      title,
      subtitle,
      error,
    } = this.state;
    const { classes } = this.props;
    //console.log("SignIn Render " + JSON.stringify(open));
    if (!open) {
      return "";
    }

    return (
      <Dialog
        disableBackdropClick
        open={open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div className={classes.dialog_root}>
          <DialogTitle id="form-dialog-title">
            <Typography gutterBottom variant="h5" className={classes.title}>
              {title}
            </Typography>
            <Typography gutterBottom variant="subtitle1" color="textSecondary">
              {subtitle}
            </Typography>
          </DialogTitle>
          <DialogContent>{this.renderContents()}</DialogContent>
          <div className={classes.dialog_message}>
            <Typography
              align="center"
              gutterBottom
              noWrap
              variant="subtitle1"
              color="textSecondary"
            >
              {message}
            </Typography>
            <Typography align="center" variant="subtitle1" color="error">
              {error}
            </Typography>
          </div>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button
              disabled={false}
              onClick={this.handleConfirm}
              color="primary"
            >
              Confirm
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

SaveDialog.propTypes = {
  open: PropTypes.bool.isRequired
};

export default withStyles(styles)(SaveDialog);
