import React, { Component } from 'react';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import VideoList from '../contentviews/videolist'
import PubSub from '../../pubsub'
import {JQ,isEmpty} from '../../utils/helpers'
import Continuum from '../../clients/continuum'
import Accounts from "../../storage/accounts"

const headerHeight = 400;

const styles = theme => ({
  header: {
    position: "absolute",
    display: "flex",
  },
  headerContainer: {
    display: "flex",
    //paddingTop: theme.spacing.unit*2,
    marginBottom: theme.spacing.unit*5,
    //height:headerHeight
  },
  headerBackground: {
    //position: "relative",
    height:headerHeight,
    //top: 0,
  },
  margins: {
    margin: theme.spacing.unit*2,
    paddingTop: theme.spacing.unit*4,
  },
  noMargins: {
    margin: 0,
    padding: 0,
  },
  spacing: {
    marginTop:theme.spacing.unit*2,
    marginBottom:theme.spacing.unit*2
  },
  avatar: {
    margin: 10,
    justifyContent:"center",
    verticalAlign: "middle",
    alignContent:"center",
  },
  bigAvatar: {
    marginTop: theme.spacing.unit,
    marginBottom:theme.spacing.unit, 
    marginLeft: theme.spacing.unit*5,
    width: 64,
    height: 64,
  },
  title: {
    //color: "black",
    fontSize: "2em",
    fontWeight: 400,
    width: "100%"
  },
  channeltitle: {
    flexGrow: 1,
    marginTop: theme.spacing.unit,
    color: "grey",
    fontSize: "1em",
  },
  container: {
    position: "relative",
    height: 200,
    //overflow: "hidden"
  },
  maincontents: {
    display:"flex",
    justifyContent:"space-between",
    alignItems:"flex-start",
    flexDirectxion: "colum",
    paddingTop: theme.spacing.unit*5,
    paddingBottom: theme.spacing.unit*5,
    overflow: "auto"
  },
  gridChannel: {
    display:"flex",
    flexFlow: "column",
    alignItems:"flex-start",
    textAlign: "left",
    minWidth: 250,
    maxWidth: 250,
    flexGrow: 0,
  },
  gridVideo: {
    float: "right",
    transform: 'translateZ(0)',
    flexGrow: 1,
  },
  gridItem: {
    marginLeft: theme.spacing.unit*2,
  },
  featureSpace: {
    marginLeft: theme.spacing.unit,
  },
  verticleSeparator: {
    borderRight: "1px solid rgba(100,100,100,.3)",
    marginTop: theme.spacing.unit*10,
    float:"right",
    height: 100
  },
  buttonContainer: {
    display: 'flex',
    marginRight: theme.spacing.unit*5,
    justifyContent: "flex-end",
    height: 60,
    textFlow: "nowrap"
  },
  button: {
    marginLeft:theme.spacing.unit,
    marginRight:theme.spacing.unit,
    backgroundColor: fade(theme.palette.common.black, 0.3),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.5),
    },
    color: theme.palette.common.white,
    whiteSpace: "nowrap"
  },
  coverImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    objectFit: "cover",
    backgroundColor:fade(theme.palette.primary.main, 0.8)
  },
  channelButton: {
    height: "100%",
    textAlign: "left",
    marginLeft: theme.spacing.unit*2,
    fontSize: "x-small"
  },
  feature_container: {
    flexWrap: 'nowrap',
    overflow: "hidden",
    //minWidth: 800,
  },
  feature_list: {
    flexWrap: 'nowrap',
    overflowY: "hidden",
    overflow: "auto",
    //minWidth: 800,
  },
  feature_title: {
    color: theme.palette.primary.light,
  },
  feature_titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  channel_list: {
    flexWrap: 'nowrap',
    height: 300,
    overflow: "auto",
  },
  cover: {
      objectFit: "cover",
      alignItems: 'center',
      width:"100%",
      height:"100%",
  },
  row: {
    marginTop: theme.spacing.unit*2,
    display:"flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width:"100%"
  },
  rowItem: {
      //margin:30
  },
  paper: {
    minHeight: 400
  },
  column: {
    //marginTop: theme.spacing.unit*2,
    display:"flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "space-between",
    padding:theme.spacing.unit,
  },
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
  }
});

class MyVideos extends Component {

  constructor(props){
    super(props);
    const account = Continuum.getCurrentAccount();
    let name = null;
    if(account){
      name = account.name;
    }
    this.state = {
      account: account,
      title:name? `${name}'s Page`: "",
      defaultChannel: null,
      selectedChannelId: "",
      channels: [],
      featured_videos: []
    };
    PubSub.subscribe(this, PubSub.TOPIC_NEED_REFRESH, (msg, data) => {
      console.log("My Videos TOPIC_NEED_REFRESH received");
      this.refresh();
    });
  }

  handleNewChannel = event => {
    PubSub.publish(PubSub.TOPIC_OPEN_NEWCHANNEL,this.state.selectedChannelId);
  }

  componentWillMount() {
    this.refresh();
  }

  refresh = async () => {
    if(/*!this.hasMounted() || */this.isFetching){
      return;
    }
    this.isFetching = true;

    try{
      /*
      let features = await Continuum.getMyContent();
      this.setState({featured_videos:features});
      */
      let channels = await Continuum.getChannels();
      this.setState({channels});
      
    }catch (err) {
      console.error("Could not fetch channel data: " + JSON.stringify(err));
      return;
    }
    this.isFetching = false;
  };

  render() {
    const { appState } = this.props;
    const { classes } = this.props;
    const { currentAccount } = appState;
    let {channels} = this.state;

    console.log("My Videos Render: " + JQ(currentAccount));
 
    return (
      <div className={classes.root}>
          <Paper className={classNames(classes.paper, classes.column)}>
              <div className={classes.headerContainer}>
                <div className={classes.buttonContainer}>
                  <Button color="primary" className={classes.button}
                    onClick={() => this.handleNewChannel()}
                  > 
                    New Channel
                  </Button>
                </div>
              </div>
            {channels? channels.map((lib, index) => {
              console.log("My Videos lib: " + JSON.stringify(lib));
              let account = Continuum.getAddress();
              if(isEmpty(lib) || lib.meta.owner !== account){
                return "";
              }
              console.log("lib id: " + JSON.stringify(lib));
              return(
                <VideoList key={index} qid={lib.id} channelObj={lib} contents={lib.contents} showButtons={true} showEmpty={true}/>
              );
            }) : ""}
          </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(MyVideos);