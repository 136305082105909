import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import PubSub from '../../pubsub'
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const maxImageWidth = 150;

const styles = theme => ({
    title: {
        flexGrow: 1,
        marginTop: theme.spacing.unit
    },
    fab: {
        width: 70,
        height: 70,
        backgroundColor: "transparent",
        margin: "auto",
        padding: theme.spacing.unit
    },
    image: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
    },
    accountName: {
        margin: "auto",
        align: 'center',
        maxWidth: 100,
        color: "grey"
    },
    dialog_message: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: "90%",
        paddingBottom: 0,
    },
    nomargin: {
        margin: "auto",
        padding: 0,
    },
    dialog_content: {
        display: "flex",
        flexFlow: "wrap",
        flexDirection: "row",
        justifyContent: "spaceBetween",
        flexGrow: 1,
        //width: 600,
        maxheight: 700,
        marginLeft: theme.spacing.unit*4,
        marginRight: theme.spacing.unit*4,
        marginBottom: theme.spacing.unit*2
    },
    noDisplay: {
        display:"none"
    },
    dialog_root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "spaceBetween",
        //maxWidth: 350,
        //maxHeight: 500,
        //overflow: "hidden"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "spaceBetween",
        //maxWidth: 350,
        //maxHeight: 500,
        //overflow: "auto"
    },

    textField: {
        //display: "block",
        width: "100%"
    },
    imageCard: {
        display: 'flex',
        position: 'relative',
        height: maxImageWidth,
        maxWidth: maxImageWidth,
        minWidth: maxImageWidth,
        marginTop: theme.spacing.unit*2,
        //float: "left",
    },
    cover: {
        objectFit: "contain",
        alignItems: 'center',
    },
    coverCard: {
        display: 'flex',
        position: 'relative',
        height: maxImageWidth,
        maxWidth: "100%",
        marginTop: theme.spacing.unit*2,
        float: "left",
        objectFit: "cover"
    },
    row: {
        marginTop: theme.spacing.unit*2,
        display:"flex",
        flexDirection: "row",
        //padding:theme.spacing.unit*2,
    },
    column: {
        marginTop: theme.spacing.unit*2,
        display:"flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignItems: "space-between",
        padding:theme.spacing.unit,
    }
});


const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },

}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <div >{children}</div>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing.unit * 5,
        // height: 100,
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit,
    },
}))(MuiDialogActions);


class NewChannelDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            message: "",
            error: "",
            title: props.title,
            subtitle: props.subtitle,
            channelType: "vod",
            channelInfo: {
                image: "./images/video_standin.png",
                cover_image: "./samples/cover1.jpeg",
                channel_type: "vod" //TODO: handle live
            }
        }
        PubSub.subscribe(this,PubSub.TOPIC_OPEN_NEWCHANNEL, (msg,data) => {
            this.setState({open:true});
        });
        PubSub.subscribe(this,PubSub.TOPIC_CLOSE_DIALOG, (msg, data) => {
            this.setState({ open: false });
        });
    }

    handleConfirm = event => {
        console.log("New Channel: HandleConfirm");
        PubSub.publish(PubSub.TOPIC_CREATE_CHANNEL,{channel:this.state.channelInfo});
        this.handleClose(event);
    }

    handleClose = event => {
        this.setState({ open: false });
    }

    handleTypeChange = event => {
        let channelInfo = this.state.channelInfo;
        channelInfo.type = event.target.value;
        this.setState({
            channelInfo : channelInfo
        });
    }

    handleNameChange = event => {
        let channelInfo = this.state.channelInfo;
        channelInfo.name = event.target.value;
        this.setState({
            channelInfo : channelInfo
        });
    }

    handleDescriptionChange = event => {
        let channelInfo = this.state.channelInfo;
        channelInfo.description = event.target.value;
        this.setState({
            channelInfo : channelInfo
        });
    }

    handleImageClick = event => {
        console.log("Image Clicked");
        let path = "./samples/channel1.png";
        this.handleImageChange(path);
    }

    imageChangeHelper = (event, stateKey) =>{
        let f = event.target.files[0];
        //let channelInfo = this.state.channelInfo;
        let that = this;
        var reader = new FileReader();
        // Closure to capture the file information.
        reader.onload = (function(theFile) {
            return function(e) {
                // Render thumbnail.
                /*
                channelInfo[stateKey] = e.target.result;
                that.setState({
                    channelInfo : channelInfo
                });
                */
                console.log("Done preview " + theFile);
                that.setState({[stateKey]:e.target.result});
            };
        })(f);

        reader.readAsDataURL(f);
    }

    handleImageChange = event => {
        this.imageChangeHelper(event,"imagePreview");
    }

    handleCoverClick = event => {
        console.log("Cover Clicked");
        let path = "./samples/cover1.jpeg";
        this.handleCoverChange(path);
    }

    handleCoverChange = event => {
        this.imageChangeHelper(event,"coverPreview");
    }

    handleTypeChecked = channelType => event => {
        console.log("handleTypeChecked: " + channelType);
        let channelInfo = this.state.channelInfo;
        channelInfo.channelType = channelType;
        this.setState({
            channelInfo : channelInfo
        });
        this.setState({ channelType });
    };
    

    renderContents = () => {
        const { channelInfo, imagePreview, coverPreview} = this.state;
        const { classes } = this.props;

        return (
            <form className={classes.form} noValidate autoComplete="off">
                <TextField
                required
                fullWidth
                id="name"
                label="Name"
                defaultValue="My New Channel"
                className={classes.textField}
                margin="normal"
                onChange={this.handleNameChange}
                />

                <TextField
                fullWidth
                id="description"
                label="Description"
                defaultValue="This is my new channel."
                className={classes.textField}
                margin="normal"
                onChange={this.handleDescriptionChange}
                />

                <div className={classes.row}>
                    <FormControlLabel
                        control={
                            <Checkbox
                            checked={this.state.channelType === 'vod'}
                            onChange={this.handleTypeChecked('vod')}
                            value="vod"
                            />
                        }
                        label="Video On Demand"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                            checked={this.state.channelType === 'linear'}
                            onChange={this.handleTypeChecked('linear')}
                            value="linear"
                            />
                        }
                        label="linear"
                    />
                </div>

                <div className={classes.row}>
                    <div className={classes.column}>
                    <Typography>Thumbnail:</Typography>
                    <Card className={classes.imageCard}>
                        <input
                        ref={'image-upload'}
                        type='file'
                        className={classes.noDisplay}
                        accept="image/*"
                        onChange={this.handleImageChange}
                        />
                        <Button
                            onClick={e => {
                                this.refs['image-upload'].click()
                            }}
                        >
                            <CardMedia
                                component="img"
                                alt="Channel Image"
                                className={classes.cover}
                                image= {imagePreview?imagePreview:channelInfo.image}
                                title="Image"
                            />
                        </Button>
                    </Card>
                    </div>

                    <div className={classes.column}>
                    <Typography>Cover photo:</Typography>
                    <Card className={classes.coverCard}>
                        <input
                            ref={'cover-upload'}
                            type='file'
                            className={classes.noDisplay}
                            accept="image/*"
                            onChange={this.handleCoverChange}
                            />
                        <Button
                                onClick={e => {
                                this.refs['cover-upload'].click()
                            }}
                        >
                            <CardMedia
                                component="img"
                                alt="Channel Cover"
                                className={classes.cover}
                                image={coverPreview?coverPreview: channelInfo.cover_image}
                                title="Cover"
                            />
                        </Button>
                    </Card>
                    </div>
                </div>

            </form>
        );
    }

    render() {
        const { open, message, title, subtitle, error, channelInfo } = this.state;
        const { classes} = this.props;
        //console.log("SignIn Render " + JSON.stringify(open));
        if (!open) {
            return "";
        }

        return (
            <Dialog
                disableBackdropClick
                open={open}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
            >
            <div className={classes.dialog_root}>
            <DialogTitle id="form-dialog-title">
                <Typography gutterBottom variant="h5" className={classes.title}>
                    {title}
                </Typography>
                <Typography gutterBottom variant="subtitle1" color="textSecondary">
                    {subtitle}
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.nomargin}>
                <div className={classes.dialog_content}>
                    {this.renderContents()}
                </div>
            </DialogContent>
                <div className={classes.dialog_message}>
                    <Typography align='center' gutterBottom noWrap variant="subtitle1" color="textSecondary">
                        {message}
                    </Typography>
                    <Typography align='center' variant="subtitle1" color="error">
                        {error}
                    </Typography>
                </div>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button disabled={!channelInfo.name} onClick={this.handleConfirm} color="primary">
                            Confirm
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        );
    }
}


NewChannelDialog.propTypes = {
    open: PropTypes.bool.isRequired
};

export default withStyles(styles)(NewChannelDialog);