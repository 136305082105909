import React, { Component } from 'react';
import classNames from 'classnames';
import { withStyles } from "@material-ui/core";
import Gallery from "../gallery"
import PubSub from "../../pubsub";
import Continuum from '../../clients/continuum'
import { JQ, isEmpty } from "../../utils/helpers";

const styles = theme => ({
  homeRoot: {
    display: "flex",
    flexWrap: "noWrap",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow:"auto",
    margin: theme.spacing(5),
    paddingTop: theme.spacing(4)
  }
});

var CACHE = {

}

//Renders the homepage with new sites
class HomeSites extends Component {

  constructor(props){
    console.log("Homepage constructor");
    super(props);
    this.state = {
      sites: [],
    }
    this.isFetching = false;
    PubSub.subscribe(this, PubSub.TOPIC_NEED_REFRESH, (msg, data) => {
      console.log("Homepage TOPIC_NEED_REFRESH");
      if(this.hasMounted() && !this.isFetching){
        this.getSites();
      }
    });

    PubSub.subscribe(this,PubSub.TOPIC_OPEN_SEARCH,async (msg,data)=>{
      //NOT CALLED!
      console.log("HOME RECEIVED SEARCH KEYWORDS: " + JQ(data));
      this.setState({searchData:data});
    });

  }

  componentWillMount() {

  }

  componentDidMount() { 
    //console.log("Homepage componentDidMount");
    this._ismounted = true;
    this.getSites();
  }
  
  componentWillUnmount() {
    //console.log("Homepage componentWillUnmount");
     this._ismounted = false;
  }

  hasMounted(){return this._ismounted === true;}

  //Calls Fabric api
  getSites = async () => {
    console.log("Homesites getSites");
    if(this.isFetching){
      console.log("Is fetching...");
      return;
    }

    this.isFetching = true;
    if(!Continuum.client){
      console.log("No client...");
      return;
    }
    PubSub.publish(PubSub.TOPIC_PROGRESS_START);

    try{
      let sites = await Continuum.getSites();
      PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
      CACHE={sites};
      if(this.hasMounted()){
        this.setState({sites});
      }
    }catch(error){
      console.error("Could not fetch homesites content. " + JQ(error));
    }
    this.isFetching = false;
    PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
  }
  
  getSitesTest = async () => {
    console.log("Homesites getSites");
    if(this.isFetching){
      return;
    }

    this.isFetching = true;
    PubSub.publish(PubSub.TOPIC_PROGRESS_START);
    if(!Continuum.client){
      return;
    }

    let sites = await Continuum.getSites();


    try{

      let meta = await Continuum.client.ContentObjectMetadata({
        libraryId: "ilibwQowEZNHPfxMBKxQLFhH7tPMb2P",
        objectId: "iq__3hgxWJ8uNVYWyT7QbEafrqfLMJTv",
      });

      console.log("SITE Meta: " + JQ(meta));
  
      meta.imageUrl = "https://picsum.photos/300/400";

      sites = [
        {
          id:"iq something",
          hash:"hash",
          meta
        },
        {
          "id":"iq2",
          "hash":"hash2",
          "meta":{
            "public":{
              "description": "",
              "name": "MGM On Demand",
            },
            "imageUrl": "https://picsum.photos/300/400"
          }
        },
        {
          "id":"3",
          "hash":"hash3",
          "meta":{
            "public":{
              "description": "",
              "name": "Fox Sports Live",
            },
            "imageUrl": "https://picsum.photos/300/400"
          }
        },
        {
          "id":"4",
          "hash":"hash4",
          "meta":{
            "public":{
              "description": "",
              "name": "SKY UK",
            },
            "imageUrl": "https://picsum.photos/300/400"
          }
        }
      ];
      CACHE={sites};
      if(this.hasMounted()){
        this.setState({sites});
      }
    }catch(error){
      console.error("Could not fetch homesites content. " + JQ(error));
    }
    this.isFetching = false;
    PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
  }

  render() {
    let { sites } = this.state;
    if(isEmpty(sites) && !isEmpty(CACHE.sites)){
      sites = CACHE.sites;
    }

    if(isEmpty(sites)){
      return null;
    }

    const { classes } = this.props;
    let currentAccount = Continuum.getCurrentAccount();
    if(!currentAccount){
      return "";
    }
    

    return (
      <div className={classNames(classes.homeRoot)}>
        <Gallery data={sites}/>
      </div>
    );
  }
}

export default withStyles(styles)(HomeSites);