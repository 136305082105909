import React, { PureComponent } from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TokenIcon from "../../assets/images/token.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
//import { JQ, isEmpty } from "../../utils/helpers";

const styles = theme => ({
  badge: {
    position: "absolute",
    alignItems:"center",
    padding: theme.spacing.unit * 0.5,
    backgroundColor:"rgba(0,0,0,.1)",
    top: 0,
    right: 0,
    float: "right",
    color: "white",
  },
  charge: {
    overflow: "hidden",
    //marginRight: theme.spacing.unit * 2,
    maxWidth: 100,
    textShadow: "2px 2px 4px #000000",
    fontSize: 14,
    //textAlign: "center"
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  iconSmall: {
  },
  row: {
    display: "flex",
    flexWrap: "noWrap"
  }
});

class ChargeBadge extends PureComponent {
  render() {
    //console.log("ChargeBadge props: " + JQ(this.props));

    let { classes, accessCharge, mandatory } = this.props;
    accessCharge = parseFloat(accessCharge).toFixed(2);
    if (accessCharge === "0.00") {
      accessCharge = "Free";
    }

    return (
      <div className={classNames(classes.badge)}>
        {mandatory ? 
          <FontAwesomeIcon icon="ad" />
         : 
         <div className={classes.row}>
          {accessCharge === "Free" ?
            ""
             : 
             <img
                src={TokenIcon}
                className={classNames(classes.leftIcon, classes.iconSmall)}
                alt="token"
                height="18"
                style={{ filter: "invert(1)" }}
              />
            }
            <Typography
              noWrap
              className={classNames(classes.charge)}
              color="inherit"
            >
              {accessCharge}
            </Typography>
          </div>
        }
      </div>
    );
  }
}

ChargeBadge.propTypes = {
  accessCharge: PropTypes.number.isRequired,
  mandatory: PropTypes.bool.isRequired
};

export default withStyles(styles)(ChargeBadge);
