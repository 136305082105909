import React, { Component } from 'react';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import VideoList from '../contentviews/videolist'
import PubSub from '../../pubsub'
import {isEmpty} from '../../utils/helpers'
import Continuum from '../../clients/continuum'
import Accounts from "../../storage/accounts"

const styles = theme => ({
  homeRoot: {
    display: "flex",
    flexWrap: "noWrap",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow:"auto",
    margin: theme.spacing(5),
    paddingTop: theme.spacing(4),
  },
});

class Home extends Component {

  constructor(props){
    super(props);
    const account = Continuum.getCurrentAccount();
    let defaultChannel = account.defaultChannel;
    this.state = {
      account: account,
      title:account? `${account.name}'s Page`: "",
      defaultChannel: null,
      selectedChannelId: defaultChannel ? defaultChannel : "",
      channels: account.publishedChannels,
      featured_videos: []
    };

    PubSub.subscribe(this, PubSub.TOPIC_NEED_REFRESH,(msg,data) =>{
      console.log("HOME: refresh");
      const account = Continuum.getCurrentAccount();
      Continuum.getChannels().then(results =>{
        this.setState({
          account:account,
          channels:results});
      });
    });
  }

  handleNewChannel = event => {
    PubSub.publish(PubSub.TOPIC_OPEN_NEWCHANNEL,this.state.selectedChannelId);
  }

  componentWillMount() {
    console.log("HOME componentWillMount");
    /*
    Continuum.getMyContent().then(results =>{
      this.setState({featured_videos:results});
    });
  */

  const account = Continuum.getCurrentAccount();
  Continuum.getChannels().then(results =>{
    this.setState({
      account:account,
      channels:results});
  });
    
  }

  componentDidMount() {
    console.log("HOME componentDidMount");
  }

  render() {
    const { classes } = this.props;
    let {channels} = this.state;
    console.log("HOME render");

    return (
      <div className={classNames(classes.homeRoot)}>
        {channels? channels.map((lib, index) => {
          if(isEmpty(lib)){
            return "";
          }
          return(
            <VideoList key={index} qid={lib.id} channelObj={lib} showEmpty={false} showButtons={false}/>
          );
        }) : ""}
      </div>
    );
  }
}

export default withStyles(styles)(Home);