import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { withRouter } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import mainLogo from '../../assets/images/app_logo.svg'
import TokenIcon from '../../assets/images/token.png'
import ViewListIcon from '@material-ui/icons/ViewList';
import UserIcon from '@material-ui/icons/Person';
import PubSub from '../../pubsub'
import Continuum from '../../clients/continuum';
import LinearProgress from '@material-ui/core/LinearProgress';
import {JQ, isEmpty} from '../../utils/helpers'
import configuration from "../../configuration.json";
import Accounts from "../../storage/accounts"

const DEFAULT_POLLING_MS = 2000;

const styles = theme => ({
    root: {
      width: '100%',
    },
    column: {
      display:"flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      overflow:"visible",
    },
    grow: {
      flexGrow: 1,
    },
    appBar: {
      display: "block",
      background: theme.palette.primary.dark,
      color: "white",
      zIndex: theme.zIndex.drawer + 1,
      height: 60,
      overflow:"visible"
    },
    progressBar: {
      position:"relative",
      zIndex: theme.zIndex.drawer + 1,
      bottom:3
    },
    toolBar: {
    },
    menuButton: {
      marginLeft: -20,
      marginRight: 10,
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing.unit * 5,
      marginLeft: theme.spacing.unit * 5,
      flexGrow: 1,
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing.unit * 3,
        width: 'auto',
      },
    },
    searchIcon: {
      width: theme.spacing.unit * 9,
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    inputInput: {
      paddingTop: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingLeft: theme.spacing.unit * 10,
      transition: theme.transitions.create('width'),
      width: '100%',
      color: "white"
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    balance: {
      overflow: "hidden",
      marginRight: theme.spacing.unit*2,
      maxWidth: 500,
      textAlign: "right",
      flexShrink: "0"
    },
    avatar: {
      '&:hover': {
        backgroundColor: fade(theme.palette.primary.main, 0.80),
      },
      backgroundColor:theme.palette.primary.main,
      height: 32,
      width: 32,
      marginLeft: 5,
    },
    leftIcon: {
    },
    rightIcon: {
      marginLeft: theme.spacing.unit,
    },
    iconSmall: {
      fontSize: 20,
    },
    signin_button: {
      display: "flex",
      maxWidth:200,
      minWidth:50,
    },
    refresh: {
      marginRight: theme.spacing.unit,
      marginLeft: theme.spacing.unit*2
    }
  });

class Header extends Component {
  state = {
    open: false,
    anchorEl: null,
    balance: 0,
    inProgress: false
  };

  constructor(props){
    super(props);
    this.getCurrentBalance = this.getCurrentBalance.bind(this);
  }

  componentWillMount(){
    PubSub.subscribe(this,PubSub.TOPIC_PROGRESS_START,(msg,data)=>{
      console.log("Progress start.");
      this.setState({inProgress:true});
    });

    PubSub.subscribe(this,PubSub.TOPIC_PROGRESS_DONE,(msg,data)=>{
      console.log("Progress done.");
      this.setState({inProgress:false});
    });

    this.getCurrentBalance();
    if(!this.interval){
      this.polling = DEFAULT_POLLING_MS;
      try{
        this.polling = configuration.ethereum.polling_ms;
        console.log("Polling interval from config: " + this.polling);
      }catch(error){
        console.log("No polling found in configuration.ethereum.polling_ms. Default " + this.polling);
      }

      if(isEmpty(this.polling)){
        this.polling = DEFAULT_POLLING_MS;
      }
      console.log("Balance polling interval: " + this.polling);
      this.interval = setInterval(this.getCurrentBalance, this.polling);
    }
    PubSub.subscribe(this, PubSub.TOPIC_CURRENT_USER, async (msg, data) => {
      if (!data || data === "") return;

      console.log("Header: New Current User " + JSON.stringify(data));

    });
  }

  async getCurrentBalance() {
    // console.log("Header getCurrentBalance");
    try {
      let eth = await Continuum.getAccountBalanceString();
      let currentAccount = Continuum.getCurrentAccount();
      if(!this.state.currentAccount || currentAccount !== this.state.currentAccount){
        this.setState({currentAccount,balance:eth});
      }else{
        this.setState({balance:eth});
      }
    } catch (e) {
        this.setState({error: "Error getting eth account: " + e});
    }
  }

  handleClose = event => {
    this.anchorEl = null;
    this.setState({ open: false, anchorEl: null });
    console.log("close menu");
  };

  handleLogout = event => {
    PubSub.publish(PubSub.TOPIC_OPEN_SIGNIN);
    this.handleClose(event);
    console.log("logout");
  }

  keyPress = async e =>{
    if(e.keyCode === 13){
      PubSub.publish(PubSub.TOPIC_OPEN_SEARCH, this.state.keywords);
    }
  }

  handleSearchChanged = async event => {
    let keywords = event.target.value;
    console.log("Search entered: " + keywords);
    this.setState({keywords});
    /*
    if(this.props.history !== undefined){
      if(this.props.history.location.pathname !== '/searchpage'){
          this.props.history.push({pathname:'/searchpage'});
      }
    }else{
        console.error("History object is undefined in searchpage");
    }

    let results = await Continuum.videoSearch(event.target.value, 10);
    PubSub.publish(PubSub.TOPIC_OPEN_SEARCH, results);
    */

  }

  userButtonClicked = event => {
  /* if(event.getModifierState("Shift")){
      console.log("Shift clicked on user button.");
      let objectId = "iq__3VQnZpK73ze9ohHdgff8XzLd6Vbe";
      PubSub.publish(PubSub.TOPIC_OPEN_DVR, { 
        objectId
        });

    }else{
      PubSub.publish(PubSub.TOPIC_OPEN_SIGNIN);
    }*/
    PubSub.publish(PubSub.TOPIC_OPEN_SIGNIN);
  }

  handleRefreshClicked = event => {
   console.log("Refresh cick");
   PubSub.publish(PubSub.TOPIC_NEED_REFRESH);
  }
  
  viewListClicked = event =>{
    PubSub.publish(PubSub.TOPIC_VIEW_LIST_TOGGLE);
  }

  render() {
    const { classes } = this.props;
    let { balance, inProgress, currentAccount} = this.state;
    if(!currentAccount){
      console.log("current: " + JQ(currentAccount))
      currentAccount = {name:''};
    }

    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <div className={classes.column} >
          <Toolbar className={classes.toolbar}>
              <IconButton color="inherit" className={classes.menuButton} onClick={this.viewListClicked}>
                  <ViewListIcon />
              </IconButton>
              <img src={mainLogo} alt='Eluvio Continuum' height='50'></img>
              <IconButton color="inherit" className={classes.refresh} onClick={this.handleRefreshClicked}>
                  <RefreshIcon />
              </IconButton>
              <div className={classes.search}>
                  <div className={classes.searchIcon}>
                      <SearchIcon />
                  </div>
                  <TextField
                      id='search'
                      autoComplete='off'
                      placeholder="Search…"
                      type="search"
                      name="search"
                      //Hack so the browser doesn't think we're the login name when saving passwords
                      onFocus={()=>{console.log("focus");this.setState({searchFocused:true})}}
                      onBlur={()=>{console.log("blur");this.setState({searchFocused:false})}}
                      classes={{
                          root: classes.inputRoot
                      }}
                      inputProps={
                      {
                        autoComplete: "off",
                        className:classes.inputInput,
                        color: "white",
                        form: {
                          autoComplete: "off",
                        },
                        readOnly: !this.state.searchFocused,
                      }}
                      onKeyDown={this.keyPress}
                      onChange={this.handleSearchChanged}
                  />
              </div>
              <img src={TokenIcon} className={classNames(classes.leftIcon, classes.iconSmall)} alt='token' height='24' style={{filter:"invert(1)"}}></img>
              <Typography noWrap className={classes.balance} color="inherit">{balance}</Typography>
              
              <Button variant="contained" color="secondary"
                className={classes.signin_button}
                onClick={this.userButtonClicked}
              >
              {
                !isEmpty(currentAccount.name)?
                <Typography color="inherit" variant="button" noWrap>{currentAccount.name}</Typography>
                :
                <UserIcon/>
              }
              </Button>
          </Toolbar>
          {inProgress? <LinearProgress className={classes.progressBar}/> : "" }
          </div>
        </AppBar>
      </div>
    );
  }
}
  
Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Header));