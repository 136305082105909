import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from "@material-ui/icons/Info";
import Continuum from "../../clients/continuum"
import PubSub from "../../pubsub"
import { JQ, isEmpty } from '../../utils/helpers';

const maxImageHeight = 120;
const maxImageWidth = maxImageHeight * 16/9;

const styles = theme => ({
    title: {
        flexGrow: 1,
        marginTop: theme.spacing.unit,
        fontSize:"2em"
    },
    fab: {
        width: 70,
        height: 70,
        backgroundColor: "transparent",
        margin: "auto",
        padding: theme.spacing.unit
    },
    image: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
    },
    accountName: {
        margin: "auto",
        align: 'center',
        maxWidth: 100,
        color: "grey"
    },
    paper: {
      padding: theme.spacing.unit*3,
      minHeight: 400
    },
    formRoot: {
      display: "flex",
      flexWrap: "wrap",
      margin: theme.spacing.unit*3,
    },
    formControl: {
      margin: theme.spacing.unit,
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing.unit * 2
    },
    subheading: {
      fontSize:"1.5em",
      color: "grey"
    },
    search_container:{
        //width: "100%",
        //backgroundColor: "white"
        //...theme.mixins.gutters(),
        //paddingTop: theme.spacing.unit * 2,
        //paddingBottom: theme.spacing.unit * 2,
        //position:"fixed",
        //width: "90%",
        height: 40,
        marginTop: theme.spacing.unit * 2,
        //backgroundColor: "blue"
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing.unit * 2,
        marginLeft: theme.spacing.unit * 2,
        flexGrow: 1,
        //width: "100%",
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      inputRoot: {
        color: 'inherit',
        width: '100%',
      },
      inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
      },
    dialog_message: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: "90%",
        paddingBottom: 0,
    },
    nomargin: {
        margin: 0,
        padding: 0,
    },
    noDisplay: {
        display:"none"
    },
    dialog_root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "spaceBetween",
        //maxWidth: 350,
        //maxHeight: 500,
        //overflow: "hidden"
        height: 800,
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "spaceBetween",
    },

    textField: {
        //display: "block",
        width: "100%"
    },
    imageCard: {
        display: 'flex',
        position: 'relative',
        height: maxImageHeight,
        maxWidth: maxImageWidth,
        minWidth: maxImageWidth,
        float: "left",
    },
    cover: {
        objectFit: "cover",
        alignItems: "center",
        width: "100%",
        flexGrow: 0
    },
    coverCard: {
        display: 'flex',
        position: 'relative',
        height: maxImageHeight,
        maxWidth: "100%",
        marginTop: theme.spacing.unit*2,
        float: "left",
        objectFit: "cover"
    },
    row: {
        marginTop: theme.spacing.unit*2,
        display:"flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        width:"100%"
    },
    rowItem: {
        margin:30
    },
    column: {
        marginTop: theme.spacing.unit*2,
        display:"flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignItems: "space-between",
        padding:theme.spacing.unit,
    },
    feature_title: {
        flexGrow: 0,
        fontWeight: 400,
        //fontSize: "0.8em"
      },
    feature_description: {
        color: "grey",
        //fontWeight: 200,
        fontSize: "0.7em",
        flexGrow: 1
    }
});


class SearchPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            error: "",
            title: props.title,
            subtitle: props.subtitle,
            //Result
            videos: props.videos? props.videos : []
        }
        
    }

    componentDidMount(){
        console.log("SEARCH PAGE: componentDidMount" + this.props.location.pathname);
    }

    onInfoClicked = obj => {
        console.log("onInfoClicked - " + JQ(obj));
        if (!isEmpty(obj)) {
          PubSub.publish(PubSub.TOPIC_OPEN_JSONVIEW, obj);
        }
    };

    handleImageClick = async obj => {
        console.log("Image Clicked " + obj.hash);
        let item = await Continuum.contentData.getByHash(obj.hash);
        if(this.props.history !== undefined){
            if(this.props.history.location.pathname !== '/playerpage'){
                this.props.history.push({pathname:'/playerpage', state: { obj: item }});
            }
          }else{
              console.error("History object is undefined in searchpage");
          }
    }

    renderContents = () => {
        console.log("SEARCH PAGE RENDER");
        //let { checked} = this.state;
        const { classes, videos} = this.props;
        let that = this;

        function ContentList({heading, lib, onClick}) {
            if(!lib || lib.length === 0){
              return (
                <Typography noWrap gutterBottom variant="h5" className={classNames(classes.subheading)}>
                    Sorry, no content found.
                </Typography>
              );
            }

            return (
              <List className={classes.noMargins}>
              
                <Typography noWrap gutterBottom variant="h5" className={classNames(classes.subheading)}>
                {heading}
                </Typography>


              <div />
                {lib.map((obj,index) => (
                <ListItem key={index} className={classes.row}>
                    <Card className={classNames(classes.imageCard, classes.checkbox)}>
                    <Button 
                    onClick={e=>{
                        that.handleImageClick(obj);
                    }}
                    >
                        <CardMedia
                                component="img"
                                alt="Cover Image"
                                className={classes.cover}
                                image= {!isEmpty(obj.meta.image)? obj.meta.imageUrl : "./images/video_standin.png"}
                                title="Image"
                        />
                    </Button>
                    </Card>
                    <div className={classNames(classes.column)}>
                        <Typography noWrap gutterBottom variant="h5" component="h2" className={classes.feature_title}>
                            {obj.meta.name ? obj.meta.name : ""}
                        </Typography>
                        <Typography component="p" className={classes.feature_description}>
                            {obj.meta.description ? obj.meta.description : ""}
                        </Typography>

                        <div className={classes.row} >
                        <IconButton
                            aria-label="Info"
                            color="primary"
                            onClick={e => {
                                that.onInfoClicked(obj);
                            }}>   
                                <InfoIcon />
                            </IconButton>
                        </div>
                    </div>
                </ListItem>  
                ))}
              </List>
            );
        }

        return (
        <div position="relative">
            <div className={classes.dialog_content}>
                {<ContentList heading="Search Results" lib={videos}/>}
            </div>
        </div>
        );
    }

    render() {
        const { classes } = this.props;
        return (
        <div>
        <Paper className={classes.paper}>
            {this.renderContents()}
        </Paper>
      </div>
        );
    }
}


export default withRouter(withStyles(styles)(SearchPage));