import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link } from "react-router-dom";
import PubSub from "../../pubsub";
import Continuum from '../../clients/continuum'
import { JQ, isEmpty } from "../../utils/helpers";
import LinesEllipsis from 'react-lines-ellipsis'

const cardWidth = 160;
const cardHeight = cardWidth * 9/16;
const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  cardGrid: {
    paddingBottom: theme.spacing(8),
    width: "100%",
    padding: 0,
    margin:0
  },
  link: {
    marginRight:theme.spacing(2),
  },
  card: {
    height: cardHeight,
    width: cardWidth,
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    position: "relative",
    left:0,
    top:0,
    height: "100%",
    width:"100%",
    objectFit: "cover"
  },
  cardTitle: {
    marginTop: theme.spacing.unit,
    width: cardWidth,
    textOverflow:"ellipsis",
    fontSize: "1em",
    fontWeight: 500,
    color: "grey"
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

export default function Gallery(props) {
  const classes = useStyles();
  const {data} = props

  if(!data){
    return "";
  }

  return (
    <React.Fragment >
        <Container className={classes.cardGrid}>
          <Grid container spacing={3}>
            {data.map(function (site) {
              console.log("GALLERY SITE: " + JQ(site));
              try {
                let image = site.meta.imageUrl;
                let title = site.meta.public.name;
                return (
                  <Grid item key={site.meta.id} xs="auto" sm="auto" md="auto">
                    <Card className={classes.card}>
                      <CardActionArea 
                        className={classes.cardMedia}
                        component={Link}
                        to={{ pathname: "/site", state: { site: site } }}
                      >
                        <CardMedia
                          component="img"
                          className={classes.cardMedia}
                          image={image}
                          alt={title}
                        />
                      </CardActionArea>
                    </Card>
                    <Typography className={classes.cardTitle} gutterBottom variant="h5" component="h2">
                            {title}
                    </Typography>
                  </Grid>
                );
              }catch(error){
                return null;
              }
            })}
          </Grid>
        </Container>
    </React.Fragment>
  );
}