import { ElvClient } from "@eluvio/elv-client-js/src/ElvClient";
import { FrameClient } from "@eluvio/elv-client-js/src/FrameClient";
import ContentObjectABI from "@eluvio/elv-client-js/src/contracts/BaseContent.js"
import CommercialOfferingABI from "@eluvio/elv-client-js/src/contracts/AdmgrCommercialOffering.js"
import nested from "nested-property";
import { JQ, isEmpty } from "../utils/helpers";
import Accounts from "../storage/accounts"
import configuration from "../configuration.json";
import PubSub from '../pubsub'
const BigNumber = require("bignumber.js");

/*
var hexToByte = function(valueHex) {
  let rawHex = valueHex.toLowerCase().replace(/^0x/, "");
  let bytes = [];
  for (var i = 0, strLen = rawHex.length / 2; i < strLen; i++) {
    bytes[i] = parseInt("0x" + rawHex.substr(i * 2, 2));
  }
  return bytes;
};
*/
var stringToHex = function(valueString, bytesNum) {
  var arr = [];
  for (var i = 0, strLen = valueString.length; i < strLen; i++) {
    let char = valueString.charCodeAt(i);
    let hex = char.toString(16);
    if (char < 16) {
      arr.push("0" + hex);
    } else {
      arr.push(hex);
    }
  }
  let rawHex = arr.join("");
  if (bytesNum != null) {
    if (rawHex.length < bytesNum * 2) {
      return "0x" + rawHex + "0".repeat(bytesNum * 2 - rawHex.length);
    } else {
      return "0x" + rawHex.substring(0, bytesNum * 2);
    }
  } else {
    return "0x" + rawHex;
  }
};

//privateKey: bf092a5c94988e2f7a1d00d0db309fc492fe38ddb57fc6d102d777373389c5e6


const Wei = 1000000000000000000;

class AppData {
  //qid: of the content object to store our app data
  address = null;
  fabricUrl = "";
  publicMeta = {
    emp: {
      publishLibs: [],
      publishedContents: []
    },
    continuum: {
      recentAccess: [],
      favorites: [],
      channelSubscriptions: [],
      userSubcriptions: [],
      purchased: [],
      ads: "view",
      language: "en"
    }
  };
  privateMeta = null;
  collectedTags = null;

  client = null;
  constructor(config, address, client) {
    console.log("AppData constructor: " + address);
    this.address = address;
    //TODO: convert address to qid to write the user data too.
    this.client = client;
    this.config = config;
  }

  checkErrors() {
    if (!this.address) {
      let error = "AppData Error: Address is not set.";
      throw error;
    }
    if (!this.config) {
      let error = "AppData Error: config is not set.";
      throw error;
    }

    if (!this.client) {
      let error = "AppData Error: Client is not set.";
      throw error;
    }
  }

  //Stores to the fabric the current state
  async store() {
    console.log("Storing account data.");
    try{
      this.checkErrors();
      let balance = await continuum.getAccountBalance();
      if(!balance.isGreaterThan(BigNumber(0))){
        PubSub.publish(PubSub.TOPIC_ERROR, "Please fund this account.");
        return;
      }
      console.log("STORE profile: " + JQ(this.publicMeta));
      await this.client.userProfileClient.ReplaceUserMetadata({
        metadata: this.publicMeta,
        requestor: "continuum"
      });
    } catch (err) {
      console.error("Could not update User data." + JSON.stringify(err));
      PubSub.publish(PubSub.TOPIC_ERROR, "Could not update user profile.");
    }
  }

  async load() {
    console.log("AppData load " + this.address);
    let profile = {};
    this.checkErrors();
    let balance = await continuum.getAccountBalance();
    console.log("AppData load balance: " + balance);
    /*
    try {
      this.privateMeta = await this.client.userProfileClient.UserMetadata({
        accountAddress: this.address,
        requestor: "continuum"
      });
    } catch (err) {
      console.error("Could not fetch User private data." + JSON.stringify(err));
    }

    console.log("LOADING collections.");
    this.client.userProfileClient.CollectedTags({
      address: this.address,
      requestor: "continuum"
    }).then(result =>{
      this.collectedTags = result;
      console.log("collectedTags: " + JQ(this.collectedTags));
    },error=>{
      console.error("Could not retrieve collected tags." + JQ(error));
    });
*/
    let publicMeta = {};
    try {
      publicMeta = await this.client.userProfileClient.PublicUserMetadata({
        address: this.address
      });

      console.log("Recieved user public meta: " + JQ(publicMeta));
    } catch (err) {
      console.error("Could not fetch User public data." + JSON.stringify(err));
    }

    if(balance.isGreaterThan(BigNumber(0))){
      console.log("LOADING account profile for: " + this.address);
      try {
        profile = await this.client.userProfileClient.UserMetadata({
          address: this.address
        });
      } catch (err) {
        console.error("Could not fetch User data." + JSON.stringify(err));
      }

      console.log("LOADED account profile: " + JQ(profile));
    }else{
      console.log("New Account.");
    }

    if(!profile){
      profile = {};
    }


    if(publicMeta && publicMeta.name){
      profile.name = publicMeta.name;
    }

    this.publicMeta = profile;
    let needsUpdate = false;
    if (!profile.emp) {
      let emp = {};
      emp.publishedChannels = [];
      emp.publishedContents = [];
      profile.emp = emp;
      needsUpdate = true;
    }

    if (!profile.continuum) {
      let continuum = {};
      continuum.recentAccess = [];
      continuum.favorites = [];
      continuum.channelSubscriptions = [];
      continuum.userSubcriptions = [];
      continuum.purchased = [];
      continuum.ads = "view";
      continuum.language = "en";
      profile.continuum = continuum;
      needsUpdate = true;
    }

    if (isEmpty(profile.continuum.ads)) {
      profile.continuum.ads = "view";
      needsUpdate = true;
    }

    if (needsUpdate && balance !== 0) {
      this.publicMeta = profile;
      await this.store();
    }
    return profile;
  }

  setPublicMeta(tree, value, store = true) {
    if (value === undefined) {
      return;
    }

    nested.set(this.publicMeta, tree, value);
    if (store) {
      this.store();
    }
    console.log("User state: " + JQ(this.publicMeta));
  }

  get(){
    return this.publicMeta;
  }

  getPublicMeta(tree) {
    return nested.get(this.publicMeta, tree);
  }

  hasPublicMeta(tree) {
    return nested.hashOwn(tree);
  }

  getPrefAds(){
      let ads = this.getPublicMeta("continuum.ads");
      if(isEmpty(ads)){
          ads = "view";
          this.setPublicMeta("continuum.ads",ads);
          try {
            this.store();
          }catch(err){
            console.error("Saving user preferences " + JQ(err));
          }
      }
      return ads;
  }

  getPrefLanguage(){
    let lang = this.getPublicMeta("continuum.language");
    if(isEmpty(lang)){
        lang = "en";
        this.setPublicMeta("continuum.language",lang);
        try {
          this.store();
        }catch(err){
          console.error("Saving user preferences " + JQ(err));
        }
    }
    return lang;
}

}

/*  EMP = Eluvio Media Platform meta
{
  "ads_marketplace": "ilib3csfCAJNoCKQXsEWa7FM8e13MrNj",
  "channels": "ilib2xShob9S2g5bQsyNkDMGbFbSyi4h",
  "content_types": {
    "advertisement": "iq__267zbtvPX6CoYN4Gu7mSyJxA6ZN8",
    "avmaster_imf": "iq__48thT2n5JoGqc93FdHvVeAXRR4Wh",
    "campaign": "iq__4CjPjwGFHA5adNLj1kmzt25eurww",
    "campaign_manager": "iq__4JmxtFYqtv8FQ5PLJsekvRPawE9P",
    "channel": "iq__Zv9X4nFhtX5nCEWufx8Gdgy3dcC",
    "sponsored_content": "iq__do9uqsfhUe6gg9XACymNZbPQRNx"
  },
  "contracts": {
    "advertisement": {
      "address": "0x2C44B92E8523288319425aBa2942dec0784E0e64"
    },
    "marketplace": {
      "address": "0x683C736246F2Dfe63ffcee892136C363b21FF204"
    },
    "sponsored_content": {
      "address": "0x5a40DF831C92f7b3178E80921A05B88A1B5A5De2"
    }
  }
}
*/

class ContentData {
  constructor(client, config, EMP, contentSpaceId) {
    this.client = client;
    this.config = config;
    this.EMP = EMP;
    this.contentSpaceId = contentSpaceId;
/*
    this.contentSpaceAddress = this.client.utils.HashToAddress(
      this.config.fabric.contentSpaceId
    );
    this.contentSpaceLibraryId = this.client.utils.AddressToLibraryId(
      this.contentSpaceAddress
    );

    */

    console.log(
      "Continuum ContentData contentSpace: " +
        this.contentSpaceId 
    );
  }
  async getAll() {
    console.log("Get All Content");
    //let contents = [];
    //let map = {};
    let results = this.state;

    let promises = [];
    for (var k in results) {
      let latest = results[k];
      if (Object.keys(latest.meta).length > 0) {
        if (!latest.meta.channelType) {
          latest.meta.channelType = "vod";
        }

        if (
          latest.meta.contents !== undefined &&
          latest.meta.contents.length > 0
        ) {
          for (var key in latest.meta.contents) {
            let stored = latest.meta.contents[key];
            let promise = this.getByHash(stored);
            promises.push(promise);
          }
        }
      }
    }

    //console.log("Promises found: " + promises.length);
    this.contents = await Promise.all(promises);
    //this.contents = contents;
    return this.contents;
  }

  async getAllOwner() {
    let contents = [];
    try {
      let results = continuum.appData.getPublicMeta("emp.publishedContents");
      if (!results) {
        return [];
      }

      for (var k in results) {
        let hash = results[k];
        let obj = await this.getByHash(hash);
        if (obj) {
          obj.meta.imageUrl = await continuum.getContentObjectImageUrl({
            versionHash: obj.hash,
            object:obj
          });
          contents.push(obj);
        }
      }

      this.contents = contents;
      return contents;
    } catch (err) {
      console.error("Could not get owner contents. " + JQ(err));
    }
    return [];
  }

  async get({ libraryId, objectId, validate=true, noAuth=true}) {
    console.log("ContentData get " + libraryId + " : " + objectId);

    if(validate){
      let isValid = await this.validateAvailability(objectId);

      if(!isValid){
        return null;
      }
    }

    if(!libraryId){
      libraryId = await this.client.ContentObjectLibraryId({
        objectId
      })
    }

    let item = await this.client.ContentObject({
      libraryId,
      objectId,
      noAuth
    });

    let latest = item;
    // console.log("ContentData get: \n" + JQ(item));
    if(!isEmpty(item.versions)){
      latest = item.versions[0];
    }

    let meta = await this.client.ContentObjectMetadata({
      versionHash: latest.hash,
      noAuth
    });

    latest.meta = meta;

    if(!isEmpty(meta)){
      latest.meta.imageUrl = await continuum.getContentObjectImageUrl({
        versionHash: latest.hash,
        object: latest
      });

      //TODO:
      if (!latest.meta.displayType) {
        latest.meta.displayType = "eluvio_video";
      }
    }

    latest.libId = libraryId;
    // console.log("ContentData getHash item " + JQ(latest));

    return latest;
  }

  async getByHash(qhash) {
    console.log("ContentData getByHash " + qhash);
    if(!qhash){
      return null;
    }
    let item = {};

    try {
      item = await this.client.ContentObject({
        versionHash: qhash
      });
    }catch(error){
      console.error("Continuum getByHash ContentObject error: " + JQ(error));
      return null;
    }

    //console.log("ContentData getHash item " + JQ(item));
    let isValid = true;
    try {
      //XXX: Does not work yet
      // isValid = await this.validateAvailability(item.id);
    }catch(error){
      console.error("Continuum getByHash availability: " + JQ(error));
    }

    if(!isValid){
      return null;
    }

    let meta = {};
    try {
      meta = await this.client.ContentObjectMetadata({
        versionHash: qhash,
        noAuth: true
      });
    }catch(error){
      console.error("Continuum getByHash ContentObjectMetadata error: " + JQ(error));
    }
    item.meta = meta;
    try {
      item.meta.imageUrl = await continuum.getContentObjectImageUrl({
        versionHash: qhash,
        object: item,
        noAuth: true
      });
    }catch(error){
      console.error("Continuum getByHash getContentObjectImageUrl error: " + JQ(error));
    }

    //console.log("ContentData getHash meta " + JQ(meta));

    //console.log("ContentData getHash final " + JQ(item));
    //console.log("Found!");

    return item;
  }

  async getChannel(qid) {
    //console.log("ContentData getChannel " + qid);
    if (isEmpty(this.EMP) || qid == null || qid === undefined || qid === "") return null;

    let item = await this.client.ContentObject({
      libraryId: this.EMP.channels,
      objectId: qid,
      noAuth: true
    });
    let meta = await this.client.ContentObjectMetadata({
      libraryId: this.EMP.channels,
      objectId: qid,
      noAuth: true
    });

    item.meta = meta;

    item.meta.imageUrl = await continuum.getContentObjectImageUrl({
      versionHash: item.hash,
      object: item,
      noAuth: true
    });

    //console.log("ContentData retrieved " + JQ(item));

    let latest = item;
    if (!latest.meta.channelType) {
      latest.meta.channelType = "vod";
    }
    return latest;
  }

  async getSites() {
    console.log("getSites");
    let contents = [];
    console.log("Continuum getSites from EMP: " + JQ(this.EMP.sites));

    if(!isEmpty(this.EMP)){
        for(const versionHash of this.EMP.sites){
          console.log("getSites: " + versionHash);
          try{
            var obj = await this.getByHash(versionHash);
            if(obj){
              contents.push(obj);
            }
          }catch(error){
            console.error("GetChannels error: " + JQ(error));
          }
        }
    }

    return contents;
  }

  async getChannels(typeFilter="", idsOnly=false) {
    console.log("getChannels: " + typeFilter);
    let contents = [];
    let results;
    console.log("Continuum getChannels from EMP: " + JQ(this.EMP.channels));

    if(!isEmpty(this.EMP)){
      try{
        results = await this.client.ContentObjects({
          libraryId: this.EMP.channels,
          noAuth: true
        });
      }catch(error){
        console.error("GetChannels error: " + JQ(error));
      }

      console.log("results: " + JQ(results));

      if(!results || !results.contents){
        console.error("unexpected result.");
        return contents;
      }

      for (var k = 0; k < results.contents.length; k++) {
        let item = results.contents[k];
        // console.log("item: " + k + " " + JQ(item));
        let latest = item.versions[0];

        if (Object.keys(latest.meta).length > 0) {
          let valid = true;
          //console.log("typeFilter: " + typeFilter+ " vs " + latest.meta.channelType);
          if(!isEmpty(typeFilter)){
            valid = false;
            //Support for old channels without a channelType
            if(latest.meta.channelType === typeFilter){
              //console.log("valid channel!");
              valid = true;
            }
          }

          if(valid){
            if(latest.meta.channelType === "linear"){
              //console.log("type is linear");
              if(latest.meta.contents.length > 0 ){
                try{
                  console.log("getChannels: " + JQ(latest.meta.contents[0]));
                  let contentItem = await this.getByHash(latest.meta.contents[0]);
                  latest.meta.scheduleContent = contentItem;
                }catch(error){
                  console.error("Could not get the content object: "+ JQ(error));
                  continue;
                }
              }
            }

            //FIXME: WHY doesn't this work??
/*
            try{
              if(!isEmpty(latest.meta.image)){
                let versionHash = latest.hash;
                console.log("Channel hash: " + JQ(versionHash));
                let imageUrl = await this.getContentObjectImageUrl({versionHash,noAuth: true});
                latest.meta.imageUrl = imageUrl;
                console.log("Adding image linear: " + JQ(imageUrl));
              }else{
                if(latest.meta.contents.length > 0){
                  let versionHash = latest.meta.contents[0];
                  console.log("Schedule hash: " + JQ(versionHash));
                  let imageUrl = await this.getContentObjectImageUrl({versionHash,noAuth: true});
                  latest.meta.imageUrl = imageUrl;
                  console.log("Adding image linear: " + JQ(imageUrl));
                }
              }
            }catch(error){
              console.error("Could not get the image: "+ JQ(error));
              //continue;
            }
            */

            console.log("contents added.");

            contents.push(latest);
          }
        }
      }
   }
    console.log("contents finished ");
    //TODO: setState function with signal
    this.state = contents;
    return contents;
  }

/*
async getChannels(typeFilter="") {
  let contents = [];
  if(!isEmpty(this.EMP)){
    let results = await this.client.ContentObjects({
      libraryId: this.EMP.channels,
      noAuth: true
    });
    for (var k in results) {
      let item = results[k];
      let latest = item.versions[0];
      if (Object.keys(latest.meta).length > 0) {
        let valid = true;
        //console.log("typeFilter: " + typeFilter+ " vs meta: " + latest.meta.channelType);
        if(!isEmpty(typeFilter)){
          valid = false;
          //Support for old channels without a channelType
          if(latest.meta.channelType == typeFilter){
            //console.log("valid channel!");
            valid = true;
          }
        }

        if(valid){
          if(latest.meta.channelType === "linear"){
            if(latest.meta.contents.length > 0 ){
              let item = await this.getByHash(latest.meta.contents[0]);
              latest.meta.schedule = item;
            }
          }
          contents.push(latest);
        }
      }
    }
 }
  //console.log("contents: " + JQ(contents));
  //TODO: setState function with signal
  this.state = contents;
  return contents;
}
*/

  async createChannel({ name, description,channelType="vod", image}) {
    console.log(`CONTENTDATA CREATE CHANNEL ${name} - ${description}`);
    if (!description) description = "";

    if (name === null || name === undefined || name === "") {
      console.error("Continuum createChannel - name is required.");
      return;
    }

    console.log(`CREATE CHANNEL ${name} - ${description}`);
    console.log(`CHANNELS LIBID ${this.EMP.channels}`);

    let owner = await this.client.CurrentAccountAddress();
    if(isEmpty(owner)){
      console.error("Continuum createChannel - missing owner");
      return;
    }

    if(isEmpty(this.EMP.content_types) || isEmpty(this.EMP.content_types["Channel"])){
      console.error("Continuum createChannel - missing content type 'channel'");
      return;
    }

    let channelHash = this.EMP.content_types["Channel"];

    console.log(`1 create object channelHash: ` + channelHash);

    // Make the channel object
    const chanDraft = await this.client.CreateContentObject({
      libraryId: this.EMP.channels,
      options: {
        type: channelHash,
        meta: {
          name: name,
          description: description,
          owner: owner,
          channelType,
          contents: []
        }
      }
    });

    console.log(`2 finalize ${JQ(chanDraft)}`);
    const chan = await this.client.FinalizeContentObject({
      libraryId: this.EMP.channels,
      objectId: chanDraft.id,
      writeToken: chanDraft.write_token
    });
    console.log(
      "    Channel - " + name + ": " + chan.id + " hash: " + chan.hash
    );
/*
    await this.client.SetContentObjectImage({
      libraryId: this.EMP.channels,
      objectId: chanDraft.id,
      image: image
    });
    */
    return chan;
  }

  async deleteContentObject({libraryId, objectId}){
    if(!objectId){
      return;
    }

    if(!libraryId){
      libraryId = await this.client.ContentObjectLibraryId({
      objectId
    });
    }

    console.log(`Deleting Content Object with libid: ${libraryId} objId: ${objectId}`);

    await this.client.DeleteContentObject({
      libraryId,
      objectId
    });
  }

  //TODO: write to the fabric directly
  //FIXME: validate data?
  async create(contentInfo) {
    //throw "ContentData create() not yet implemented.";
  }

  //TODO: write to the fabric directly
  //FIXME: validate data?
  async updateChannel(channelInfo) {
    //throw "not yet implemented.";
  }

  async validateAvailability(objectId){
    let errMsg="";
    let contractAddress = await this.client.utils.HashToAddress(objectId);

    //console.log("Accessing availability of contract: "+ contractAddress+" for " +requestor);
    if(isEmpty(contractAddress)){
      errMsg = "No ContractAddress for querying availability.";
      throw errMsg;
    }

    let response = await this.client.CallContractMethod({noAuth: true,contractAddress: contractAddress,
    abi: ContentObjectABI, methodName:"getAccessInfo", methodArgs: [0,[],[]]});

    //console.log("Availability code: "+ response[0]);
    switch (response[0]) {
        case 0: {
            return true;
        }
        default: {
            return false;
        }
    }
  }

  /*
  async validateAvailability(objectId){
    let errMsg="";
    let requestor = continuum.getAccountAddress();
    let contractAddress = await this.client.utils.HashToAddress(objectId);

    let customContract = await this.client.CustomContractAddress({ objectId: objectId });

    console.log("Accessing availability of contract: "+ contractAddress+ ", customContract"+ customContract +" for " +requestor);
    if(isEmpty(contractAddress)){
      errMsg = "No ContractAddress for querying availability.";
      throw errMsg;
    }

    //TODO: use getAccessInfo of the contract
    //Always available?
    if(isEmpty(customContract)){
      return true;
    }

    try {
        let response = await this.client.CallContractMethodAndWait({
            contractAddress: customContract,
            abi: CommercialOfferingABI,
            methodName: "isAvailable",
            methodArgs: [contractAddress, requestor]
        });
        console.log("Availability code: "+ response);
        switch (response) {
            case 0: {
               //console.log("User is cleared for the content");
               return true;
            }
            case 10: {
                errMsg = "Content unavailable for this territory";
                break;
            }
            case 20: {
                errMsg = "Content not available yet";
                break;
            }
            case 30: {
                errMsg = "Content not available anymore";
                break;
            }
            default: {
                errMsg = "Content unavailable (error code:" + response +")";
            }
        }
    } catch(err) {
        errMsg = "Content unavailable (unknown error)";
    }
    return false
  };
*/
}


class Continuum {
  client = null;
  signer = null;
  wallet = null;
  isFrameClient = false;
  config = null;
  appData = null;
  contentData = null;
  address = null;
  isStandalone = true;

  constructor(config) {
    this.setConfiguration(config);
  }

  checkSigner = () => {
    if(!this.signer){
      PubSub.publish(PubSub.TOPIC_OPEN_SIGNIN);
    }
  }

  checkBalance = async () => {
    let balance = await this.getAccountBalance();
    if(!balance.isGreaterThan(BigNumber(0))){
      PubSub.publish(PubSub.TOPIC_ERROR, "Insufficient Funds. Please contact an Eluvio Administrator to fund your account.");
    }
  }

  getAddress() {
    return this.address;
  }

  async jsonGet(url) {
    let res = await fetch(url, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    return await res.json();
  }

  async jsonSend(method, url, data = {}) {
    let res = await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });
    return await res.json();
  }

  isVod = (obj) => {
    if(!obj || !obj.meta){
      return false;
    }

    if(!obj.meta.playout_type){
      return true;
    }

    return obj.meta.playout_type === "vod";
  }

  isLinear = (obj) => {
    if(!obj || !obj.meta){
      return false;
    }

    //FIXME: Standardize
    return obj.meta.playout_type === "playout_linear" || 
      obj.meta.playout_type === "linear";
  }

  isLive = (obj) => {
    if(!obj || !obj.meta){
      return false;
    }
    return obj.meta.playout_type === "live" || !isEmpty(obj.meta.origin_url);
  }

  isRecording = (obj) => {
    if(!obj || !obj.meta){
      return false;
    }
    return obj.meta.playout_type === "recording";
  }


  sortUserTags(tags){
    var sorted = [];
    for (var tag in tags) {
      let data = tags[tag];
      data.tag = tag;
      sorted.push(data);
    }

    sorted = sorted.sort(function(a,b){return b.aggregate - a.aggregate;});
    return sorted;
  }

  async formatTagsCollection(requestor="continuum", numTags=5) {
    if(!this.client || !this.client.userProfileClient){
      return "";
    }
    let formattedString = "";
    //FIXME: Collected

    //Needs to be a unique requestor in the format "name : id"
    let tags = await this.client.userProfileClient.CollectedTags();

    let sorted = this.sortUserTags(tags);
    //console.log("User Tags sorted: " + JQ(sorted));
    var count = 0;
    for (var index in sorted) {
      var data = sorted[index];
      if (data.aggregate !== undefined && data.occurrences !== undefined) {
        let score = `${data.tag}:${data.aggregate}`;
        console.log(" tag: " + data.tag + " formatted: " + score);
        formattedString += score + ",";
      }
      count++;
      if(count > numTags){
        break;
      }
    }
    console.log("Formatted Tags Collection " + JQ(formattedString));
    if (formattedString.length > 0) {
      return formattedString.slice(0, -1);
    }

    return "";
  }

  async verifyContentObject(qhash) {
    //console.log("Verifying hash: " + qhash);
    if (!this.client) {
      var message = "Client is undefined.";
      console.error(message);
      throw message;
    }

    let result = await this.client.VerifyContentObject({
      versionHash: qhash
    });

    //console.log("Verifying finished: " + JQ(result));
    return result;
  }

  async accessRequestSponsorInfo(sponsorInfo) {
    console.log("accessRequestSponsorInfo " + JQ(sponsorInfo));

    let custom_values = [
      sponsorInfo.payout_b32,
      sponsorInfo.signature_V_hex,
      sponsorInfo.signature_R,
      sponsorInfo.signature_S
    ];
    custom_values.push(stringToHex(sponsorInfo.tag, 32));
    let profileTags = sponsorInfo.shared_with_sponsor;
    if (profileTags) {
      let tagNames = Object.keys(profileTags);
      for (let i = 0; i < tagNames.length; i++) {
        let tagName = tagNames[i];
        custom_values.push(
          stringToHex(tagName + ":" + profileTags[tagName], 32)
        );
      }
    }
    this.params = [
      0,
      "pke requestor",
      "pke AFGH",
      custom_values,
      [sponsorInfo.content_address, sponsorInfo.campaign_address]
    ];
    let adContract = await this.client.utils.HashToAddress(sponsorInfo.ad_id);
    await this.client.CallContractMethodAndWait({
      contractAddress: adContract,
      abi: ContentObjectABI,
      methodName: "accessRequest",
      methodArgs: this.params
    });
    let requestID = await this.client.CallContractMethod({
      contractAddress: adContract,
      abi: ContentObjectABI,
      methodName: "requestID",
      methodArgs: []
    });

    /*
       let custom_values = [sponsorInfo.payout_b32, sponsorInfo.signature_V_hex, sponsorInfo.signature_R, sponsorInfo.signature_S];
       custom_values.push(stringToHex(sponsorInfo.tag, 32));
       let profileTags = sponsorInfo.shared_with_sponsor;
       if (profileTags) {
           let tagNames = Object.keys(profileTags)
           for (let i = 0; i < tagNames.length; i++) {
               let tagName = tagNames[i];
               custom_values.push(stringToHex(tagName + ":" + profileTags[tagName], 32));
           }
       }
       let params = [0, "pke requestor", "pke AFGH",
           custom_values,
           [sponsorInfo.content_address, sponsorInfo.campaign_address]];

       let requestID = await Continuum.client.AccessRequest({
        objectId:sponsorInfo.id,
        args:params,
        noCache: true
        });
*/
    return requestID;
  }

  async accessComplete(qid, requestID, wait=false) {
    console.log(`accesComplete ${qid}, requestID: ${JQ(requestID)}`);
    let adContract = await this.client.utils.HashToAddress(qid);
    //TO DO: replace by api call to accessComplete
    let params = await this.client.FormatContractArguments({
      abi: ContentObjectABI,
      methodName: "accessComplete",
      args: [requestID, 100, ""]
    });
    console.log("AccessComplete params " + JQ(params));
    let response = {};
    if(wait){
      response = await this.client.CallContractMethodAndWait({
        contractAddress: adContract,
        abi: ContentObjectABI,
        methodName: "accessComplete",
        methodArgs: params
      });
    }else{
      response = await this.client.CallContractMethod({
        contractAddress: adContract,
        abi: ContentObjectABI,
        methodName: "accessComplete",
        methodArgs: params
      });
    }

    console.log("AccessComplete finished " + JQ(response));

    return response;
  }

  async accessComplete2(qid) {
    console.log(`accesComplete ${qid}`);

    let response = await this.client.ContentObjectAccessComplete({
      objectId: qid,
      score: 100
    });

    return response;
  }

  //TODO: Use client methods
  async getSponsorInfo(contentObject) {
    if(!this.client){
      return null;
    }
    if (contentObject == null) {
      let err = { error: "Content Object is null." };
      throw err;
    }

    let metadata = contentObject.meta;
    let objectId = contentObject.id;
    let versionHash = contentObject.hash;

    let bitcodeAddress = metadata["campaign_manager"];
    if(isEmpty(bitcodeAddress)){
      return null;
    }
    let libMeta = await this.client.PublicLibraryMetadata({
      libraryId: bitcodeAddress
    });
    let campaignManagerQueryID =
      libMeta.campaign_manager_name + " : " + libMeta.campaign_manager_objectId;
    let profileTags = await this.formatTagsCollection(campaignManagerQueryID);
    console.log("Profile tags: " + profileTags);
    /*
    let bitcode_arg =
      "?format=" + bitcodeAddress + "&content_hash=" + versionHash;
    if (profileTags !== "") {
      bitcode_arg += "&tags=" + profileTags;
    }

    let bitcode_url =
      fabricUrlFromConfig(this.config) +
      "/qlibs/" +
      libMeta.campaign_manager_libraryId +
      "/q/" +
      libMeta.campaign_manager_objectId +
      "/call/ads" +
      bitcode_arg;
      */
    let params = {
      format:bitcodeAddress,
      content_hash:versionHash,
    }

    if (profileTags !== "") {
      params.tags = profileTags;
    }

    //TODO: TEST THIS NEW METHOD:
    let bitcode_url = await this.client.BitcodeMethodUrl({
      libraryId:libMeta.campaign_manager_libraryId,
      objectId:libMeta.campaign_manager_objectId,
      method:"ads",
      queryParams:params
    })

    let response = await this.jsonGet(bitcode_url);
    console.log("Sponsoring response: " + JQ(response));
    var bitcodeResponse = response;
    return await this.formatAdManagerResponse(bitcodeResponse, objectId);
  }

  //Returns a formatted sponsorInfo
  async formatAdManagerResponse(response,objectId){
    if(isEmpty(response) || isEmpty(response.length) || response.length === 0){
      let message = "Wrong ad manager response format.";
      throw message;
    }

    var sponsorInfo = response[0];

    if(isEmpty(sponsorInfo)){
      let message = "First element from response is empty.";
      throw message;
    }

    var profileTags = {}

    try{
      profileTags = response[response.length - 1];

      if (isEmpty(profileTags) || profileTags.ad_id) {
        console.log("Could not find profile tags");
        profileTags = {};
      }
    }catch(error){
      console.log("Could not find profile tags");
    }


    let adMetadata = await this.client.ContentObjectMetadata({
      libraryId: sponsorInfo.ad_library,
      objectId: sponsorInfo.ad_id,
      noAuth: true
    });
    let adContract = await this.client.utils.HashToAddress(sponsorInfo.ad_id);
    //TO DO: remove next 2 lines when AccessComplete is added to API
    let requestID = await this.client.CallContractMethod({
      contractAddress: adContract,
      abi: ContentObjectABI,
      methodName: "requestID",
      methodArgs: []
    });
    window.adRequestId = parseInt(requestID["_hex"], 16); //approximation, assumes no concurrent accesses
    sponsorInfo["ad_metadata"] = adMetadata;
    sponsorInfo["ad_contract"] = adContract;
    if (sponsorInfo.payout_b32 == null) {
      let payoutHex = Math.floor(parseFloat(sponsorInfo.payout) * Wei).toString(
        16
      );
      sponsorInfo.payout_b32 =
        "0x" + "0".repeat(64 - payoutHex.length) + payoutHex;
      sponsorInfo.signature_V_hex =
        "0x0000000000000000000000000000000000000000000000000000000000000000";
      sponsorInfo.signature_R =
        "0x0000000000000000000000000000000000000000000000000000000000000000";
      sponsorInfo.signature_S =
        "0x0000000000000000000000000000000000000000000000000000000000000000";
    }
    if (sponsorInfo.content_address == null) {
      sponsorInfo.content_address = await this.client.utils.HashToAddress(
        objectId
      );
    }
    if (sponsorInfo.campaign_address == null) {
      sponsorInfo.campaign_address = this.client.utils.HashToAddress(
        sponsorInfo.campaign_id
      );
    }
    sponsorInfo.shared_with_sponsor = profileTags;
    return sponsorInfo;
  }

  async getOfferingContractInfo(qid, hash) {
    let contract = await this.client.CustomContractAddress({ objectId: qid });
    console.log("GetOffering custom contract: " + contract);
    if(isEmpty(contract)){
      return null;
    }
    let response = await this.client.CallContractMethod({
      contractAddress: contract,
      abi: CommercialOfferingABI,
      methodName: "mandatorySponsoring",
      methodArgs: []
    });
    //console.log("GetOffering mandatory: " + JQ(response));
    let contractInfo = {};
    contractInfo.mandatorySponsoring = response;
    return contractInfo;
  }

  async refreshUserAppData(address) {
    console.log("refreshUserAppData " + this.address);
    if (!address) {
      this.address = await this.client.CurrentAccountAddress();
      address = this.address;
      console.log("got address from client: " + this.address);
    }else{
      this.address = address;
    }

    if (!this.client) {
      let error =
        "ensureCurrentUserSetup requires elv client to be set up first.";
      throw error;
    }

    if (!this.appData) {
      console.log("Creating appData");
      if (!this.address) {
        let error = "No Address specified for refreshUserAppData.";
        throw error;
      }
      this.appData = new AppData(this.config, this.address, this.client);
      this.account = await this.appData.load();
    } else {
      if (this.appData.address !== this.address) {
        this.appData.address = this.address;
      }
      console.log("appData load");
      this.account = await this.appData.load();
    }
    return this.account;
  }

  async deleteObject({libraryId,objectId}){
      return this.contentData.deleteContentObject({libraryId,objectId});
  }

  async deleteChannel({channelId}){
    console.log("deleteChannel: " + channelId);
    let channels = this.contentData.EMP.channels;
    try{
      await this.deleteObject({libraryId:channels, objectId:channelId});
    }catch(e){
      console.error("Error deleting channel: " + JQ(e));
    }
    /*
    let profile = await this.refreshUserAppData();

    for( var i = 0; i < profile.emp.publishedChannels.length; i++){
      if ( profile.emp.publishedChannels[i] === channelId) {
        profile.emp.publishedChannels.splice(i, 1);
      }
   }

    this.appData.setPublicMeta(profile);
    await this.appData.store();
    */
  }
  

  async createChannel({ name, description, owner, image, coverImage, channelType }) {
    if (!this.contentData) {
      console.error("CREATE CHANNEL - contentData is null");
      return {};
    }
    let channel = await this.contentData.createChannel({
      name,
      description,
      owner,
      image,
      coverImage,
      channelType
    });
    console.log("Create channel: " + JQ(channel));
    if (!channel.id) {
      console.error("CREATE CHANNEL - channel is empty after creation.");
      return {};
    }
    /*
    let profile = await this.refreshUserAppData();
    if (!profile.emp.publishedChannels.includes(channel.id)) {
      profile.emp.publishedChannels.push(channel.id);
    }

    this.appData.setPublicMeta(profile);
    await this.appData.store();
    */
    return channel;
  }

  async updateChannel({channelId}){
    if (!this.contentData) {
      console.error("UPDATE CHANNEL - contentData is null");
      return;
    }

    console.log(`UPDATE CHANNEL - ${channelId}`);

    let meta = await this.client.ContentObjectMetadata({
      libraryId: this.EMP.channels,
      objectId: channelId
    });

    if (meta == null) {
      console.error("UPDATE CHANNEL - channel meta is null");
      return;
    }

    let contents = [];

    console.log(`UPDATE CHANNEL current contents - ${JQ(contents)}`);

    if (!isEmpty(meta.contents)) {
      for(const versionHash of meta.contents){
        let objectId = await this.client.utils.DecodeVersionHash(versionHash).objectId;
        let libraryId = await this.client.ContentObjectLibraryId({versionHash});
        let obj = await this.client.ContentObject({libraryId, objectId});
        // console.log("Found object: " + JQ(obj));
        if(obj){
          var newHash = obj.hash;
          contents.push(newHash);
        }
      }

      meta.contents = contents;
      console.log(`ADD TO CHANNEL pushing new contents - ${JQ(meta.contents)}`);

      //Careful, adding meta in here merges
      const chanDraft = await this.client.EditContentObject({
        libraryId: this.EMP.channels,
        objectId: channelId
      });

      let resp = await this.client.ReplaceMetadata({
        libraryId: this.EMP.channels,
        objectId: channelId,
        writeToken: chanDraft.write_token,
        metadata: meta
      });

      console.log("Channel updated:  - " + JQ(resp));

      let chan = await this.client.FinalizeContentObject({
        libraryId: this.EMP.channels,
        objectId: channelId,
        writeToken: chanDraft.write_token
      });
      console.log("Channel finalized:  - " + JQ(chan));
    }
  }

  async addToChannel({ parentId, newObj }) {
    if (!this.contentData) {
      console.error("ADD TO CHANNEL - contentData is null");
      return {};
    }

    console.log(`ADD TO CHANNEL - ${parentId} , ${JQ(newObj.hash)}`);

    let meta = await this.client.ContentObjectMetadata({
      libraryId: this.EMP.channels,
      objectId: parentId
    });

    if (meta == null) {
      console.error("ADD TO CHANNEL - channel metas is null");
      return {};
    }

    if ((meta.contents === undefined) | (meta.contents === null)) {
      meta.contents = [];
    }

    console.log(`ADD TO CHANNEL contents - ${JQ(meta.contents)}`);

    let chan = {};
    if (!meta.contents.includes(newObj.hash)) {
      meta.contents.push(newObj.hash);
      console.log(`ADD TO CHANNEL pushing new contents - ${JQ(meta.contents)}`);

      //Careful, adding meta in here merges
      const chanDraft = await this.client.EditContentObject({
        libraryId: this.EMP.channels,
        objectId: parentId
      });

      let resp = await this.client.ReplaceMetadata({
        libraryId: this.EMP.channels,
        objectId: parentId,
        writeToken: chanDraft.write_token,
        metadata: meta
      });

      console.log("Channel updated:  - " + JQ(resp));

      chan = await this.client.FinalizeContentObject({
        libraryId: this.EMP.channels,
        objectId: parentId,
        writeToken: chanDraft.write_token
      });
      console.log("Channel finalized:  - " + JQ(chan));
    }

    let newMeta = await this.client.ContentObjectMetadata({
      libraryId: this.EMP.channels,
      objectId: parentId
    });

    console.log("New Channel meta:  - " + JQ(newMeta));

    return newMeta;
  }

  //Categories and Display Content
  async getDashboardData() {
    let channels = []
    try{
      console.log("continuum getDashboardData");
      channels = await this.contentData.getChannels("vod");
      JQ("Channels: " + JQ(channels));

    }catch(error){
      console.error("Continuum getDashboardData error: " + JQ(error));
    }

    return channels;
  }

  async getNewContent() {
    let list = [];
    try{
      list = await this.contentData.getAll();
    }catch(error){
      console.error("Continuum getNewContent error: " + JQ(error));
    }
    return list;
  }

  availableDRMs = async () => {
 
    const availableDRMs = ["aes-128"];

    if (typeof window.navigator.requestMediaKeySystemAccess !== "function") {
      // eslint-disable-next-line no-console
      console.log("requestMediaKeySystemAccess not available");

      return availableDRMs;
    }

    try {
      const config = [{
        initDataTypes: ["cenc"],
        audioCapabilities: [{
          contentType: "audio/mp4;codecs=\"mp4a.40.2\""
        }],
        videoCapabilities: [{
          contentType: "video/mp4;codecs=\"avc1.42E01E\""
        }]
      }];

      await navigator.requestMediaKeySystemAccess("com.widevine.alpha", config);

      availableDRMs.push("widevine");
    } catch (e) {
      console.log("No Widevine support detected");
    }

    return availableDRMs;
   
    // return this.client.AvailableDRMs();
  };

  async getLiveChannels() {
    /*
    let channels = [];

    let libraryId = this.config.fabric.liveLib;
    let objectId = this.config.fabric.liveId;
    try{
      if(isEmpty(libraryId) || isEmpty(objectId)){
        let error = "configuration.fabric needs liveLib and liveId to show live content";
        throw error;
      }

      let liveObj = await this.contentData.get({libraryId, objectId, validate:false});
      if(!isEmpty(liveObj)){
        liveObj.meta.imageUrl = "./images/live.jpg";
        channels.push(liveObj);
      }
    }catch(error){
      console.error("Continuum getLiveChannels error: " + JQ(error));
    }
    return channels;
    */
    let channels = []
    try{
      channels = await this.contentData.getChannels("linear");
    }catch(error){
      console.error("Continuum getLiveChannels error: " + JQ(error));
    }

      for(var index in channels){
        let channel = channels[index];
        //console.log("Linear Channel: " + JQ(channel));

        if(!isEmpty(channel.meta.image)){
          let versionHash = channel.hash;
          //console.log("Channel hash: " + JQ(versionHash));
          let imageUrl = await this.getContentObjectImageUrl({
            versionHash,
            object:channel,
            noAuth: true});
          channel.meta.imageUrl = imageUrl;
          //console.log("Adding image linear: " + JQ(imageUrl));
        }else{
          if(channel.meta.contents.length > 0){
            let versionHash = channel.meta.contents[0];
            //console.log("Schedule hash: " + JQ(versionHash));
            let imageUrl = await this.getContentObjectImageUrl({
              versionHash,
              object:channel,
              noAuth: true});
            channel.meta.imageUrl = imageUrl;
            //console.log("Adding image linear: " + JQ(imageUrl));
          }
        }

      }


    /*
    try{
      //Getting the test live object
      let libraryId = this.config.fabric.liveLib;
      let objectId = this.config.fabric.liveId;
      if(!isEmpty(libraryId) && !isEmpty(objectId)){
        let liveObj = await this.contentData.get({libraryId, objectId, validate:false});
        if(!isEmpty(liveObj)){
          liveObj.meta.imageUrl = "./images/live.jpg";
          channels.push(liveObj);
        }
      }
    }catch(error){
      console.error("Continuum getLiveChannels error: " + JQ(error));
    }
    */
    return channels;
  }


  async getMyContent() {
    if(!this.contentData){
      return [];
    }
    let list = await this.contentData.getAllOwner();
    return list;
  }
  async getChannels() {
    if(!this.contentData){
      return [];
    }
    let list = await this.contentData.getChannels();
    return list;
  }

  async getSites() {
    if(!this.contentData){
      return [];
    }
    let list = await this.contentData.getSites();
    return list;
  }

  getUserAppData() {
    this.appData.get();
  }

  async getAccountBalance(address=null) {
    if(!this.isFrameClient){
      // console.log("Not Frame client");
      if(address===null){
        let account = Accounts.getCurrent();
        if(account){
          address = account.address;
        }else{
          return new BigNumber(0);
        }
      }
    }else{
      if(address === null){
        address = this.address;
      }
    }

    // console.log("getAccountBalance for address: " + address);

    if(!this.client || !address){
      return new BigNumber(0);
    }
    let balance = await this.client.GetBalance({ address });
    balance = new BigNumber(balance);
    return balance;
  }

  async getAccountBalanceString(address=null) {
    // console.log("get balance")
    let balance = await this.getAccountBalance(address);
    return balance.toFixed(2);
  }

  async setConfiguration(config) {
    if (!config) return;
    this.config = config;
    try {
      if (window.self === window.top) {
        console.log("Contnuum running standalone using config url " + config.configUrl);
        this.isStandalone = true;
        this.client = await ElvClient.FromConfigurationUrl({ configUrl: config.configUrl });
        this.wallet = this.client.GenerateWallet();
        console.log("Continuum standalone: Client configured.");
        this.checkSigner();
/*
        if (!isEmpty(window.ethereum)) {
          await window.ethereum.enable();
        }

       if (!isEmpty(window.web3)) {
          if (window.web3.currentProvider.isMetaMask === true) {
            console.log('MetaMask is active');
            await this.setSignerFromProvider(window.web3.currentProvider);
            var version = window.web3.version.api;
            console.log("Web3 api version: " + version);
          } else {
            console.log('MetaMask is not available')
          }

        } else {
          console.log('No web3 found.')
        }
*/
      } else {
        console.log("Contnuum running under a host.")
        this.isStandalone = false;
        this.client = new FrameClient({
          target: window.parent,
          timeout: 30
        });

        this.isFrameClient = true;
        this.hideCore();
        this.accountUpdated();
      }
/*
      console.log("Getting content libraries:");
      let libraries = await this.client.ContentLibraries();
      console.log("Content Libraries: " + JQ(libraries));
      console.log(await this.client.FabricUrl({noAuth:true}));
      await this.accountUpdated();
      */
    } catch (e) {
      console.error("Could not set configuration for Continuum " + JQ(e));
      PubSub.publish(PubSub.TOPIC_ERROR, "Problem contacting server.");
    }
  }

  async accountUpdated(){
    /*
    let libraryId="ilib2qnFQN9zUZtkPURU4kQSuD8Vg287";
    let objectId="iq__w4E25ZSsWhAPG8Cr8fJUa18JtEd";
    let typeHash = "hq__FRmCT3iv7Q1tVP9N8yxRAJMkzGZBLzWMNj375iQiB8jCHh8SVPGnuxys2wQU45eHsVXaRTZ4hH";

    let elvSchedule = new ElvAVSchedule({libraryId, objectId, typeHash:typeHash, client:this.client, ElvAVStream});
    let schedule = await elvSchedule.getScheduleByTime();

    console.log("Schedule: " + JQ(schedule));
    */

    try {
      console.log("Loading EMP: " + this.config.fabric.mediaPlatform);

      const libraryObjectId = this.config.fabric.mediaPlatform.replace("ilib", "iq__");

      const meta = await this.client.ContentObjectMetadata({
        libraryId: this.config.fabric.mediaPlatform,
        objectId: libraryObjectId,
        metadataSubtree: "/emp"
      });

      console.log("EMP objid: " + JQ(meta));
      this.EMP = meta;

      if(isEmpty(this.EMP.content_types)){
        const contentTypes = await this.client.ContentTypes();
        // console.log("Content Types: " + JQ(contentTypes));
        let typeMap = {};
        let keys = Object.keys(contentTypes);
        for (const key of keys){
          const type = contentTypes[key];
          console.log("key: " + key + " type: " + JQ(type.name));
          if(isEmpty(type.name)){
            continue;
          }

          typeMap[type.name] = type.hash
        }

        this.EMP.content_types = typeMap;
        console.log("EMP with content_types: " + JQ(this.EMP));
      }

      console.log("Creating ContentData.");
      let contentSpaceId = await this.client.ContentSpaceId();
      this.contentData = new ContentData(this.client, this.config, this.EMP, contentSpaceId);
     }catch(err){
      console.error("Error retrieving EMP: " + JQ(err));
      PubSub.publish(PubSub.TOPIC_ERROR, "Could not retrieve the Eluvio Media Platform");
     }

     let address = await this.refreshAccountAddress();
     console.log("Account address: " + address);
     console.log("Balance: " + await this.getAccountBalance());
     this.account = await this.refreshUserAppData(address);
     console.log("Account: " + JQ(this.account));
     PubSub.publish(PubSub.TOPIC_CURRENT_USER, address);
     PubSub.publish(PubSub.TOPIC_NEED_REFRESH);
  }

  async videoSearch(keywords,maxResults=50){
    if(!this.client){
      return [];
    }
    /*
    const namespace = ["eluvio", "search"];
    const libname = "demo";

    let table = await CreateTable([fabricUrlFromConfig(this.config)], namespace, libname);


    let results = await Search(table, keywords, maxResults);
    let jdfs = JSON.parse(results.toJSON());
    let contents = [];


    for (var i in jdfs["content_id"]) {
        console.log("" + jdfs.content_id[i] + " - " + jdfs.text_title[i] + " - " + jdfs.score[i]);
        let searchId = jdfs.content_id[i];
        let map = this.config.search.map;
        let obj = map[searchId];
        console.log("CONFIG MAPPING: " + JQ(obj));
        if(!isEmpty(obj)){
            if(obj.qhash !== ""){
                let fabricObject = await this.contentData.getByHash(obj.qhash);
                console.log("FABRIC OBJ: " + JQ(fabricObject));
                if(!isEmpty(fabricObject)){
                    contents.push(fabricObject);
                }
            }
        }
    }
    return contents;
    */

    //FIXME: uses port 5000
    let searchUrl = this.client.FabricUrl({noAuth:true}) + "/search/demo?";
    let tags = keywords.split(" ");
    let queryParams = "tags=" + tags.join('+');
    searchUrl += queryParams;
    console.log("Search url: " + searchUrl);
    let response = await this.jsonGet(searchUrl);

    let contents = [];
    try{
      for (var i in response) {
        var content = response[i]
          var item = content.eluvio.content;
          if(!isEmpty(item) && this.isValidOffering(item.content_hash)){
            item.hash = item.content_hash;
            item.meta.imageUrl = await continuum.getContentObjectImageUrl({
              versionHash: item.hash,
              object:item,
              noAuth:true
            });
            contents.push(item);
          }
        if(i > maxResults){
          break;
        }
      }
    }catch(err){
      console.error("Search response content format not recognized: " + JQ(err));
      PubSub.publish(PubSub.TOPIC_ERROR, "There was an error in the search. Please check console for logs.");
    }

    return contents;
  }

  async isValidOffering(qhash) {
    try {
      if (qhash == null || qhash === "") {
        return false;
      }
      let item = await this.client.ContentType({ versionHash: qhash });
      if (
        item == null ||
        item.meta === undefined ||
        item.meta.name === undefined
      ) {
        return false;
      }

      console.log("Content Type name: " + item.meta.name);
      var name = item.meta.name.toLowerCase();

      //TODO: this will change
      return (
        name.includes("sponsored") ||
        name.includes("offering") ||
        name.includes("commercial")
      );
    } catch (err) {
      console.error("isValidOffering error: " + JQ(err));
      return false;
    }
  }
  async openAccountsView() {
    if (!this.client) {
      console.error("Open Accounts Error: Client not configured.");
    }

    if (this.isFrameClient) {
      await this.client.SendMessage({
        options: {
          operation: "ShowAccountsPage"
        }
      });
    }
  }

  async hideCore() {
    if (!this.client) {
      console.error("Hide Core Error: Client not configured.");
    }

    if (this.isFrameClient) {
      await this.client.SendMessage({
        options: {
          operation: "HideHeader"
        }
      });
    }
  }

  async refreshAccountAddress() {
    let result = await this.client.CurrentAccountAddress();
    this.address = result;
    console.log("client CurrentAccountAddress " + result);
    return result;
  }

  getAccountAddress() {
    return this.address;
  }

  getCurrentAccount(){
    if(!this.isFrameClient){
      return Accounts.getCurrent();
    }

    if(this.account){
      return this.account;
    }else{
      this.refreshUserAppData();
    }

    return null;
  }

  setSigner(name,password) {
    if (this.isFrameClient) {
      console.error("Trying to add signer but we are using the FrameClient");
      return;
    }
    if (!this.client) {
      console.error("Client not configured.");
      return;
    }

    let signer = this.wallet.GetAccount({
      accountName: name.toLowerCase()
    });

    console.log("set signer address: " + signer.address);

    if (signer) {
      this.client.SetSigner({ signer: signer });
      this.signer = signer;
      this.address = this.client.utils.FormatAddress(signer.address);
      console.log("address: " + this.address);
      this.accountUpdated();
    } else {
      console.error("Could not find signer to set: " + this.name);
    }
  }

  async addSignerWithMnemonic(name, image, mnemonic, password) {
    console.log("addSignerWithMnemonic: " + name);
    if (this.isFrameClient) {
      console.error("Trying to add signer but we are using the FrameClient");
      return;
    }
    if (!this.client || !this.wallet) {
      console.error("Client not configured.");
      return;
    }

    let signer = await this.wallet.AddAccountFromMnemonic({
      accountName: name.toLowerCase(),
      mnemonic: mnemonic
    });

    this.addSigner(name, image, signer.privateKey,password);
  }

  async addSignerFromEncrypted(name, image, encryptedPrivateKey, password){
    console.log("addSignerFromEncrypted: " + name);
    if (this.isFrameClient) {
      console.error("Trying to add signer but we are using the FrameClient");
      return;
    }
    if (!this.client || !this.wallet) {
      console.error("Client not configured.");
      return;
    }
    try {
      await this.wallet.AddAccountFromEncryptedPK({
        accountName: name.toLowerCase(),
        encryptedPrivateKey: encryptedPrivateKey,
        password
      });
    }catch(error){
      console.log("Failed decrypting private key.");
      throw error;
    }

    this.setSigner(name,password);
    this.account = {
      name,
      address:this.address,
      image,
      encryptedPrivateKey
    };    
    
    Accounts.addAccount(this.account);
    Accounts.setCurrent(this.address);


  }

  async addSigner(name, image, privateKey, password) {
    console.log("addSigner: " + name);
    if (this.isFrameClient) {
      console.error("Trying to add signer but we are using the FrameClient");
      return;
    }
    if (!this.client || !this.wallet) {
      console.error("Client not configured.");
      return;
    }

    let signer = await this.wallet.AddAccount({
      accountName: name.toLowerCase(),
      privateKey: privateKey
    });

    this.setSigner(name);

    const encryptedPrivateKey = await this.wallet.GenerateEncryptedPrivateKey({
      signer,
      password,
      options: {scrypt: {N: 16384}}
    });

    this.account = {
      name,
      address:this.address,
      image,
      encryptedPrivateKey
    };

    Accounts.addAccount(this.account);
    Accounts.setCurrent(this.address);
  }

  async setSignerFromProvider(provider) {
    if (this.isFrameClient) {
      return;
    }
    if (!this.client) {
      console.error("Client not configured.");
    }

    console.log("Set signer from Provider.");
    await this.client.SetSignerFromWeb3Provider({ provider });
    this.signer = null;
  }

  async getObjectMeta(qid) {}

  generateMnemonic() {
    if(!this.client || !this.wallet){
      console.error("generateMnemonic - Client not configured.");
      return "";
    }

    return this.wallet.GenerateMnemonic();
  }

  async getMyVideos() {
    if (!this.appData) {
      let error = "getMyVideos() requires appData to be set.";
      throw error;
    }

    if (this.appData.get().publishedContents.length === 0) {
      return [];
    }

    let contents = {};

    for (var qid in this.appData.get().publishedContents) {
      let meta = await this.getObjectMeta(qid);
      let obj = this.appData.get().publishedContents[qid];
      obj["meta"] = meta;
      contents[qid] = obj;
    }

    return contents;
  }

  addVideo(qid) {
    if (!this.appData) {
      let error = "getMyVideos() requires appData to be set.";
      throw error;
    }

    if (!this.appData.get().publishedContents) {
      return [];
    }
  }

  //Finds the objs using Fabric Search based on keyword.
  //TODO: options (by name, type, description, owner etc)
  async find({ searchString, options }) {
    if (!this.client) {
      let error = "find requires elv-client to be set up first.";
      throw error;
    }
    let contents = [];
    try{
      console.log(
        "Continuum find: " + JQ(searchString) + " \noptions:" + JQ(options)
      );
      let libraries = await this.listContentLibraries();

      //console.log("libraries: " + JQ());

      await Promise.all(
      Object.entries(libraries).map(async ([libId,lib]) => {
        for (var index in lib.contents) {
          let obj = lib.contents[index];
          let latest = obj.versions[0];
          //convert to continuum obj

          //FIXME: Extremely slow, maybe auth?
          //TODO: based on options
          //console.log("testing object: " + JQ(latest));

          let isValid = await this.isValidOffering(latest.type);
          if (isValid) {
            latest.meta.imageUrl = await this.getContentObjectImageUrl({
              versionHash: latest.hash,
              object:latest
            });
            latest.libid = lib.id;
            //console.log("FOUND OFFERING! " + latest.hash);
            contents.push(latest);
          }else{
            //console.log("Not valid offering.");
          }
        }
      })
    );
    }catch(err){
      console.error("Error searching content: " + err);
    }

    return contents;
  }

  fabricTypeConvert(type) {
    if (type === "imf") {
      return "eluvio_video";
    } else if (type === "") {
      return "eluvio_content_type";
    } else if (type === "library") {
      return "eluvio_library";
    }

    return "unknown";
  }

  async listContentLibraries() {
    if (!this.client) {
      return {};
    }
    const libraryIds = await this.client.ContentLibraries();

    //console.log("listContentLibraries: " + JQ(libraryIds));
    let contentLibraries = {};
    await Promise.all(
      libraryIds.map(async libraryId => {
        try {
          let lib = await this.getContentLibrary({
            libraryId
          });
          if(!isEmpty(lib)){
            contentLibraries[libraryId] = lib;
          }
          //console.log("Found lib: " + JSON.stringify(contentLibraries[libraryId]));
        } catch (error) {
          console.error("Failed to get content library:");
          console.error(error);
        }
      })
    );

    //console.log("listContentLibraries result: " + JQ(contentLibraries));

    return contentLibraries;
  }

  async getContentLibrary({ libraryId }) {
    if (!this.client) {
      return {};
    }

    //console.log("getContentLibrary: " + libraryId);
    const libraryInfo = await this.client.ContentLibrary({ libraryId,noAuth:true});
    //console.log("libraryInfo: " + JQ(libraryInfo));
    const contents = await this.client.ContentObjects({ libraryId,noAuth:true});
    //console.log("contents: " + JQ(contents));
    const imageUrl = await this.getContentObjectImageUrl({
      libraryId,
      objectId: libraryId.replace("ilib", "iq__")
    });
    //console.log("image: " + JQ(imageUrl));
    //const objectId = libraryId.replace("ilib", "iq__");
    /*
    const privateMeta = await this.client.ContentObjectMetadata({
      libraryId,
      objectId,
      noAuth:true
    });
    */

    //console.log("privateMeta: " + JQ(privateMeta));
    //const owner = await this.client.ContentLibraryOwner({ libraryId,noAuth:true});
    //const currentAccountAddress = await this.CurrentAccountAddress();
    //console.log("owner: " + JQ(owner));
    return {
      ...libraryInfo,
      libraryId,
      name: libraryInfo.meta["eluv.name"],
      description: libraryInfo.meta["eluv.description"],
      //contractAddress: FormatAddress(client.utils.HashToAddress({hash: libraryId})),
      libraryObjectId: libraryId.replace("ilib", "iq__"),
      //privateMeta,
      imageUrl,
      contents: contents,
      //owner
    };
  }

  async getContentObjectImageUrl({ versionHash, object }) {
    console.log("getContentObjectImageUrl " + JQ(object));
    try{ 
      if(object.meta.public.asset_metadata){
          let image = await this.client.LinkUrl({versionHash:object.hash,
            linkPath:"public/asset_metadata/images/poster/default"
          });
          console.log("Asset poster! " + image);
          return image;
      }
    }catch(error){

    }
    
    console.log("getContentObjectImageUrl getting rep/image: " + versionHash);
    return await this.client.Rep({ versionHash, rep: "image", noAuth: true });
    //return `http://localhost:8008/q/${versionHash}/rep/image`;
  }

  async getContentObjectVideoUrl({ versionHash, objectId, params={},lang }) {
    if (!lang) lang = "en";

    console.log("getContentObjectVideoUrl versionHash:"
    + versionHash + " objectId:" + objectId + " params: " + JQ(params));

    //This doesn't do access request for some reason
    return await this.client.Rep({
      versionHash,
      objectId,
      noAuth: true,
      rep: `dash/${lang}.mpd`,
      queryParams:params
    });
    //return `http://localhost:8008/q/${versionHash}/rep/dash/${lang}.mpd`;
  }

  async getContentRep({ libraryId, versionHash, objectId, params={},rep}) {
    //This doesn't do access request for some reason
    return await this.client.Rep({
      libraryId,
      versionHash,
      objectId,
      queryParams:params,
      noAuth: true,
      rep: rep
    });
  }

  async callContentRep({ libraryId,versionHash, objectId, rep, params ={}, contentType="application/json"}){
    let url = await this.client.Rep({
      libraryId,
      versionHash,
      objectId,
      queryParams:params,
      noAuth: true,
      rep: rep
    });

    console.log("Fetching: " + url);

    let res = await fetch(url, {
      headers: {
        "Content-Type": contentType
      }
    });
    return res

  }

  async getContentObjectDownloadUrl({ versionHash, objectId, start,end }) {
    //start = Math.floor(start);
    //end = Math.floor(end);
    let queryParams = {start,end};
    //This doesn't do access request for some reason
    return await this.client.Rep({
      versionHash,
      objectId,
      noAuth: true,
      rep: `download`,
      queryParams,
    });
  }

  async getVideoUrlPart({ versionHash, partHash }) {
    if(!this.client){
      return "";
    }
    console.log("getVideoUrlTest: " + versionHash + " part: " + partHash);
    let fabricUrl = this.client.FabricUrl({noAuth:true});
    return `${fabricUrl}/q/${versionHash}/data/${partHash}`;
  }
}

/*
var testSearchResults = [
  {
    _id: "BKZ7TjSYW7CnrjIe",
    name: "Solo",
    qid: "5",
    description: "Star Wars Solo",
    libid: "",
    owner: "0x76bbb0984ac61f295a9363bfeb091ae2cfae8e47",
    type: "eluvio_video",
    contents: [],
    video: "samples/video2.mp4",
    image: "samples/video2.png",
    cover_image: "samples/video2.png"
  },
  {
    _id: "Q2cw64ys6mHyJjvN",
    name: "The Incredibles 2",
    qid: "4",
    description: "Test Movie",
    libid: "",
    owner: "0x76bbb0984ac61f295a9363bfeb091ae2cfae8e47",
    type: "eluvio_video",
    contents: [],
    video: "samples/video1.mp4",
    image: "samples/video1.png",
    cover_image: "samples/video1.png"
  },
  {
    _id: "np6YeRUL0T8xwSQs",
    name: "Big Buck Bunny",
    qid: "6",
    description: "Test Movie",
    libid: "",
    owner: "0x76bbb0984ac61f295a9363bfeb091ae2cfae8e47",
    type: "eluvio_video",
    contents: [],
    video: "samples/video3.mp4",
    image: "samples/video3.png",
    cover_image: "samples/video3.png"
  },
  {
    _id: "sdsddsdfsd",
    name: "Coral",
    qid: "iq__sd2l3kmfl",
    description: "Coral Test Movie",
    libid: "",
    owner: "0x76bbb0984ac61f295a9363bfeb091ae2cfae8e47",
    type: "eluvio_video",
    contents: [],
    video: "samples/video3.mp4",
    image: "samples/video3.png",
    cover_image: "samples/video3.png"
  }
];
*/

let continuum = new Continuum(configuration);

export default continuum;
