import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import PubSub from '../../pubsub'
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
//import { JQ } from "../../utils/helpers";
import JSONTree from 'react-json-tree'

const maxImageWidth = 150;

const theme = {
    scheme: 'google',
    author: 'seth wright (http://sethawright.com)',
    base00: '#1d1f21',
    base01: '#282a2e',
    base02: '#373b41',
    base03: '#969896',
    base04: '#b4b7b4',
    base05: '#c5c8c6',
    base06: '#e0e0e0',
    base07: '#ffffff',
    base08: '#CC342B',
    base09: '#F96A38',
    base0A: '#FBA922',
    base0B: '#198844',
    base0C: '#3971ED',
    base0D: '#3971ED',
    base0E: '#A36AC7',
    base0F: '#3971ED'
  };

const styles = theme => ({
    title: {
        flexGrow: 1,
        marginTop: theme.spacing.unit,
        fontSize:"1.5em",
        color: "grey"
    },
    fab: {
        width: 70,
        height: 70,
        backgroundColor: "transparent",
        margin: "auto",
        padding: theme.spacing.unit
    },
    image: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
    },
    accountName: {
        margin: "auto",
        align: 'center',
        maxWidth: 100,
        color: "grey"
    },
    dialog_message: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: "90%",
        paddingBottom: 0,
    },
    nomargin: {
        margin: "auto",
        padding: 0,
    },
    dialog_content: {
        display: "flex",
        flexFlow: "wrap",
        flexDirection: "row",
        justifyContent: "spaceBetween",
        flexGrow: 1,
        marginLeft: theme.spacing.unit*4,
        marginRight: theme.spacing.unit*4,
        marginBottom: theme.spacing.unit*2,
        overflow:"auto"
    },
    noDisplay: {
        display:"none"
    },
    dialog_root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "spaceBetween",
        //maxWidth: 350,
        //maxHeight: 500,
        //overflow: "hidden"
        height:"100%"
    },
    dialog: {
        height:"80%"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "spaceBetween",
        //maxWidth: 350,
        //maxHeight: 500,
        //overflow: "auto"
    },

    textField: {
        //display: "block",
        width: "100%",
        height: "100%",
        overflow: "auto"
    },
    imageCard: {
        display: 'flex',
        position: 'relative',
        height: maxImageWidth,
        maxWidth: maxImageWidth,
        minWidth: maxImageWidth,
        marginTop: theme.spacing.unit*2,
        //float: "left",
    },
    cover: {
        objectFit: "contain",
        alignItems: 'center',
    },
    coverCard: {
        display: 'flex',
        position: 'relative',
        height: maxImageWidth,
        maxWidth: "100%",
        marginTop: theme.spacing.unit*2,
        float: "left",
        objectFit: "cover"
    },
    row: {
        marginTop: theme.spacing.unit*2,
        display:"flex",
        flexDirection: "row",
        alignItems:"center"
        //padding:theme.spacing.unit*2,
    },
    column: {
        marginTop: theme.spacing.unit*2,
        display:"flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignItems: "space-between",
        padding:theme.spacing.unit,
    }
});


const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },

}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <div >{children}</div>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing.unit * 5,
        // height: 100,
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit,
    },
}))(MuiDialogActions);


class JsonView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            message: "",
            error: "",
            title: props.title,
            subtitle: props.subtitle,
            verification: props.verification,
        }
        PubSub.subscribe(this,PubSub.TOPIC_OPEN_JSONVIEW, (msg,data) => {
            this.setState({open:true, verification:data});
        });
        PubSub.subscribe(this,PubSub.TOPIC_CLOSE_DIALOG, (msg, data) => {
            this.setState({ open: false });
        });
    }

    handleConfirm = event => {
        console.log("New Channel: HandleConfirm");
        this.handleClose(event);
    }

    handleClose = event => {
        this.setState({ open: false });
    }

    renderContents = () => {
        const {verification} = this.state;
        const { classes } = this.props;

        return (
           <JSONTree data={verification} theme={theme} invertTheme={true} className={classes.textField}/>
        );
    }

    render() {
        const { open, message, title, error } = this.state;
        const { classes } = this.props;
        //console.log("SignIn Render " + JSON.stringify(open));
        if (!open) {
            return "";
        }

        return (
            <Dialog
                disableBackdropClick
                open={open}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
                classes={{ paper: classes.dialog}}
            >
            <div className={classes.dialog_root}>
            <DialogTitle id="form-dialog-title">
                <div className={classes.row} >
                    <IconButton
                        aria-label="verification"
                        color="primary"
                    >
                        <InfoIcon />
                    </IconButton> 
                <Typography gutterBottom variant="h2" className={classes.title}>
                    {title}
                </Typography>
                </div>
            </DialogTitle>
            <DialogContent className={classes.nomargin}>
                <div className={classes.dialog_content}>
                    {this.renderContents()}
                </div>
            </DialogContent>
                <div className={classes.dialog_message}>
                    <Typography align='center' gutterBottom noWrap variant="subtitle1" color="textSecondary">
                        {message}
                    </Typography>
                    <Typography align='center' variant="subtitle1" color="error">
                        {error}
                    </Typography>
                </div>
                    <DialogActions>
                        <Button onClick={this.handleConfirm} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        );
    }
}


JsonView.propTypes = {
    open: PropTypes.bool.isRequired
};

export default withStyles(styles)(JsonView);