import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import CardMedia from '@material-ui/core/CardMedia';
import ScheduleIcon from "@material-ui/icons/Schedule";
import Typography from '@material-ui/core/Typography'
import PubSub from "../../pubsub";
import { JQ, isEmpty } from "../../utils/helpers";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    width:"100%",
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: "white",
  },
  recorded: {
    color: "red",
  },
  time: {
    color: "rgba(80,80,90)"
  },
  playing: {
    color: "white",
    fontSize: ".8em",
    position: "relative",
    top: theme.spacing(1),
    left: theme.spacing(2),
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  image: {
    objectFit:"cover",
    maxWidth:300,
    height:200
  }
}));

function formattedTimeRange(startTime, duration_sec){
  // console.log("formattedTimeRange");
  var startDate = new Date(0); // The 0 there is the key, which sets the date to the epoch
  startDate.setUTCSeconds(startTime);

  var endDate= new Date(0); // The 0 there is the key, which sets the date to the epoch
  endDate.setUTCSeconds(startTime + duration_sec);

  return startDate.toLocaleTimeString(navigator.language,{hour: 'numeric', minute:'2-digit'}) + " - " 
  + endDate.toLocaleTimeString(navigator.language,{hour: 'numeric', minute:'2-digit'});
}

export default function Guide(props) {
  const classes = useStyles();
  const {streamRunning, elvSchedule, schedule, obj, recordings, recordable} = props;
  if(!schedule || !obj || !recordings){
    return "";
  }

  return (
    <div className={classes.root}>
      <GridList className={classes.gridList} cols={2.5}>
        {schedule.map((program,index) => (
          <GridListTile key={program.start_time_epoch} className={classes.image}>
            <Typography className={classes.time}>
              {formattedTimeRange(program.start_time_epoch, program.duration_sec)}
            </Typography>
            <CardMedia
                    className={classes.image}
                    image={program.program_image_url}
                    title={program.title}
                  >
            <Typography className={classes.playing}>
              {index === 0 ? "Playing" : "Upcoming"}
            </Typography>
            </CardMedia>
            <GridListTileBar
              title={program.title}
              subtitle={program.description}
              classes={{
                root: classes.titleBar,
                title: classes.title,
              }}
              actionIcon={
                recordable && streamRunning?
                <IconButton 
                  aria-label={`star ${program.title}`}
                  onClick = {event => {
                    let data = {
                      obj, 
                      recordings,
                      program,
                      elvSchedule,
                      recordable
                    };
                    PubSub.publish(PubSub.TOPIC_OPEN_DVR, data);
                  }}
                  >
                    <ScheduleIcon className={program.recorded ? classes.recorded : classes.title} />
                </IconButton> : ""
              }
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}