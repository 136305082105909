import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import Continuum from "../../clients/continuum"
import {JQ, isEmpty} from "../../utils/helpers"
import {ElvAVStream} from "../../clients/elv-avstream-module"
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  content:{
    padding: theme.spacing.unit*3,
  },
  paper: {
    padding: theme.spacing.unit*3,
    width: 500,
    height: 600,
    marginLeft: "auto",
    marginRight: "auto"
  },
  formRoot: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing.unit*3,
  },
  formControl: {
    margin: theme.spacing.unit*3,
    minWidth: 120
  },
  libraryId: {
    margin: theme.spacing.unit*3,
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },
  title: {
    fontSize:"2em"
  }
});

class PreferencesPage extends Component {

  state = {
    ads:"view",
    language:"en"
  }

  async componentWillMount() {
    try{
      if(Continuum.client){
        ElvAVStream.GetRecordingsLibraryId(Continuum.client).then(recordingLibraryId => {
          this.setState({recordingLibraryId});
        });
      }

      let store = false;
      let ads = Continuum.appData.getPublicMeta("continuum.ads");
      if(isEmpty(ads)){
        ads = "view";
        store = true;
        Continuum.appData.setPublicMeta("continuum.ads",ads);
      }
      let language = Continuum.appData.getPublicMeta("continuum.language");
      if(isEmpty(language)){
        language = "en";
        store = true;
        Continuum.appData.setPublicMeta("continuum.language",language);
      }
      this.setState({
          ads:ads,
          language: language
      });

      if(store){
        Continuum.appData.store();
      }
    }catch(e){
      console.error("Error fetching metadata: " + JQ(e));
    }
  }

  handleAdsChange = (e) => {
    Continuum.appData.setPublicMeta("continuum.ads",e.target.value);
    this.setState({ads:e.target.value});
  }

  handleLangChange = (e) => {
    Continuum.appData.setPublicMeta("continuum.language",e.target.value);
    this.setState({language:e.target.value});
  }

  render() {
    const { classes } = this.props;
    const { recordingLibraryId } = this.state;

    return (
      <div>
        <Paper className={classes.paper}>
          <Typography
            variant="h2"
            align="center"
            className={classes.title}
          >
            User Preferences
          </Typography>
          <form className={classes.formRoot} autoComplete="off">
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="ads-label-placeholder">
                Sponsored Content
              </InputLabel>
              <Select
                value={this.state.ads}
                onChange={this.handleAdsChange}
                input={<Input name="ads" id="ads-label-placeholder" />}
                name="ads"
                className={classes.selectEmpty}
              >
                <MenuItem value={"view"}>View ads and get paid</MenuItem>
                <MenuItem value={"pay"}>Pay for content</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="language-select">
                Language
              </InputLabel>
              <Select
                value={this.state.language}
                onChange={this.handleLangChange}
                input={<Input name="language" id="language-select" />}
                name="lang"
                className={classes.selectEmpty}
              >
                <MenuItem value={"en"}>English</MenuItem>
                <MenuItem value={"it"}>Italian</MenuItem>
                <MenuItem value={"ja"}>Japanese</MenuItem>
                <MenuItem value={"de"}>German</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.libraryId}>
              <InputLabel shrink htmlFor="recording-id">
                Recording Library ID
              </InputLabel>
            <TextField
              readOnly
              value={recordingLibraryId}
              margin="normal"
            />
            </FormControl>
          </form>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(PreferencesPage);
