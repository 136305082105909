import React, { Component } from "react";
import Header from "../header";
import SideBar from "../sidebar";
import Content from "../content";
import { withRouter } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import {
  blue,
  lightBlue,
} from "@material-ui/core/colors";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAd } from "@fortawesome/free-solid-svg-icons";
import PubSub from "../../pubsub";
import getUserLocale from 'get-user-locale';
import {
  NewChannelDialog,
  ImportDialog,
  SaveDialog,
  VerificationDialog,
  JsonView,
  SignInDialog,
  AddAccountDialog,
  DVRDialog
} from "../dialogs";
import Continuum from "../../clients/continuum";
import configuration from "../../configuration.json";
import { JQ, isEmpty } from "../../utils/helpers";
import Notification from "../notification";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Accounts from "../../storage/accounts";
import './App.css';

library.add(faAd);

const theme = createMuiTheme({
  palette: {
    primary: {
      dark: "#1a1e33",
      main: blue[400],
      contrastText: "white"
    },
    secondary: {
      dark: blue[700],
      main: blue[400],
      light: lightBlue[400],
      contrastText: "white"
    }
  }
});

class App extends Component {
  // Initialize the state
  constructor(props) {
    super(props);
    this.state = {
      config: configuration,
      currentAccount: null,
      pendingAccount: false,
      notificationMessage: "",
      openNotification: false
    };

    console.log("App constructor: " + JSON.stringify(this.state));
    console.log("User locale detected: " + getUserLocale());
    Accounts.load();
    PubSub.publish(PubSub.TOPIC_OPEN_SIGNIN);

    let that = this;
    PubSub.subscribe(this, PubSub.TOPIC_CURRENT_USER, async (msg, data) => {
      if (!data || data === "") return;

      console.log("App: New Current User " + JSON.stringify(data));
      Continuum.checkBalance();
      that.setState({ currentAccount: data });
      PubSub.publish(PubSub.TOPIC_NEED_REFRESH);
    });

    PubSub.subscribe(this, PubSub.TOPIC_OPEN_SIGNIN, (msg, data) => {
      console.log("Accounts requested.");
      if(Continuum.isFrameClient){
        Continuum.openAccountsView();
      }
    });

    PubSub.subscribe(this, PubSub.TOPIC_CREATE_CHANNEL, async (msg, data) => {
      console.log("App create channel " + JSON.stringify(data));
      if (!Boolean(data)) {
        console.error("data is null ");
        return;
      }

      const { channel } = data;
      if (!channel) {
        return;
      }

      //TODO: image, coverImage
      //const { name, description, image, type, coverImage } = channel;

      //TEMP: handle ownership automatically
      PubSub.publish(PubSub.TOPIC_PROGRESS_START);
      try {
        await Continuum.createChannel(channel);
      } catch (error) {
        console.log(error);
        PubSub.publish(PubSub.TOPIC_ERROR, "Error creating channel.");
      }
      PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
      let userData = await Continuum.refreshUserAppData();
      console.log("Fetched user data: " + JSON.stringify(userData));
      that.setState({ currentAccount: userData });
      console.log("App signaling needs refresh.");
      PubSub.publish(PubSub.TOPIC_NEED_REFRESH);
    });

    PubSub.subscribe(this, PubSub.TOPIC_IMPORT_CONTENT, async (msg, data) => {
      console.log("App import content ");
      const { parentId, imports } = data;
      if (isEmpty(imports)) {
        console.error("data is null ");
        return;
      }

      PubSub.publish(PubSub.TOPIC_PROGRESS_START);
      for (var index in imports) {
        let newObj = imports[index];
        newObj.owner = await Continuum.getAccountAddress();
        try {
          await Continuum.addToChannel({ parentId, newObj });
          console.log("Import success! " + JQ(newObj.hash));
        } catch (error) {
          console.log(error);
          PubSub.publish(PubSub.TOPIC_ERROR, "Error importing content.");
        }
      }
      PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
      let userData = await Continuum.refreshUserAppData(
      );
      //console.log("Fetched user data: " + JSON.stringify(userData));
      that.setState({ currentAccount: userData });
      console.log("App signaling needs refresh.");
      PubSub.publish(PubSub.TOPIC_NEED_REFRESH);
    });

    //TODO: Provide switching with elvcore?s
    PubSub.subscribe(this, PubSub.TOPIC_ERROR, (msg, data) => {
      console.log("Error!: " + data);
      that.setState({
        notificationMessage: data,
        openNotification: true
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    console.log("Page changed " + JQ(this.props.location));
    PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
  }

  handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ openNotification: false });
  };

  render() {
    //console.log("App render() " + JSON.stringify(this.state));
    const { notificationMessage, openNotification } = this.state;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MuiThemeProvider theme={theme}>
          <Header theme={theme} appState={this.state} />
          <SideBar theme={theme}
              appState={this.state}
            />
          <Content theme={theme} appState={this.state} />
          <DVRDialog open={false} appState={this.state} />
          <NewChannelDialog
            title="New Channel"
            subtitle="Fill in your new channel details."
            open={false}
            appState={this.state}
          />
          <ImportDialog
            title="Import Existing Content"
            open={false}
            appState={this.state}
          />
          <SaveDialog
            title="Save Dialog"
            subtitle="Choose the location to save your video clip."
            open={false}
            appState={this.state}
          />
          <VerificationDialog
            title="Content Verification Dialog"
            subtitle=""
            open={false}
            appState={this.state}
          />
          <JsonView
            title="Content Info Dialog"
            subtitle=""
            open={false}
            appState={this.state}
          />
          <SignInDialog
            title="Sign In"
            subtitle=""
            open={false}
            appState={this.state}
          />
          <AddAccountDialog
            title="Add Account"
            subtitle=""
            open={false}
            appState={this.state}
          />
          <Notification
            message={notificationMessage}
            open={openNotification}
            onClose={this.handleCloseNotification}
          />
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withRouter(App);
