import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
});

class ChannelPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            obj: props.location.state.obj
        }
      }

      componentDidMount() {
        //console.log("ObjectTile componentDidMount " + JSON.stringify(this.props.obj));
        //this.getObjectMeta();
    }

    render(){
        const { obj } = this.state;
        //console.log("Channel obj: " + JSON.stringify(obj));
        const { classes} = this.props;

        function Page(){ 
            return (
                <div className={classes.content}>
                    <h1>{`Channel Page  ${obj.name}`}</h1>
                </div>
            );
        }

        return <Page />;
    }
}

ChannelPage.propTypes = {
    obj: PropTypes.object.isRequired
};

export default withStyles(styles)(ChannelPage);