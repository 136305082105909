import { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  content:{
    padding: theme.spacing.unit*3,
  },
  paper: {
    padding: theme.spacing.unit*3,
    width: 500,
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    overflow: "auto"
  },
  formRoot: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing.unit*3,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },
  title: {
    fontSize:"2em"
  },
  subheading: {
    fontSize:"1.5em",
    color: "grey"
  }
});

class SettingsPage extends Component {

  componentDidMount() {

  }

  render() {

    //XXX: FIXME
    return "";
/*
    function SettingsRow({heading, settings}){
      return (
        <List>
        <ListItem>
        <Typography noWrap variant="h2" className={classes.subheading}>
          {heading}
        </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
        <Grid container spacing={8}>
        {Object.keys(settings).map((key) => {
          return(
            <Grid container item spacing={8} justify="flex-start" direction="row">
              <Grid item xs={3} zeroMinWidth> <Typography noWrap >{key} </Typography> </Grid>
              <Grid item zeroMinWidth> <Typography noWrap className={classes.secondaryText}>{`${settings[key]}`}</Typography></Grid>
            </Grid>
          );
        })}
        </Grid>
        </ListItem>
        </List>
      );
    }

    return (
      <div>
          <Paper className={classes.paper}>
            <Typography variant="h2" align="center" className={classes.title}>
              Settings
            </Typography>
            <List>
            {Object.keys(config).map((key) => {
              let value = config[key];
              return(
                <ListItem key={key}>
                <SettingsRow heading={key} settings={value}/>
                </ListItem>
              );
            })}
            </List>

          </Paper>
      </div>
    );
    */
  }
}

export default withStyles(styles)(SettingsPage);