import React, { Component } from "react";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import { withStyles } from "@material-ui/core";
import ChargeBadge from "./chargebadge";
import PubSub from "../../pubsub";
import Continuum from "../../clients/continuum";
import { JQ, isEmpty } from "../../utils/helpers";
import { Link } from "react-router-dom";
import { green, grey, deepOrange } from "@material-ui/core/colors";
import LinearIcon from "@material-ui/icons/PlaylistPlay";

const cardHeight = 270;
const cardWidth = 260;

const styles = theme => ({
  feature_title: {
    flexGrow: 0,
    fontWeight: 400,
    fontSize: "0.8em"
  },
  feature_description: {
    color: "grey",
    fontWeight: 200,
    fontSize: "0.7em",
    flexGrow: 1
  },
  card_controls: {
    // position: "absolute",
    //bottom: 0,
    flexGrow: 0
  },
  imageCard: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    overflow: "hidden",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    //alignItems: "stretch",
    height: cardHeight,
    maxWidth: cardWidth,
    minWidth: cardWidth,
    margin: theme.spacing.unit,
    backgroundColor: "rgba(0,0,0,.02)"
  },
  cardContainer: {
    display: "flex",
    position: "relative"
  },
  cover: {
    objectFit: "cover",
    alignItems: "center",
    width: "100%",
    flexGrow: 0
  },
  row: {
    display: "flex",
    flexWrap: "nowrap",
    overflow: "hidden"
  },
  verified: {
    color: green[600]
  },
  unverified: {
    color: grey[300]
  },
  failedVerification: {
    color: deepOrange[600]
  },
  expand: {
    marginLeft: 'auto',
  },
  liveType: {
    position: "absolute",
    width: 32,
    height: 32,
    padding: theme.spacing.unit * 0.5,
    alignItems:"center",
    objectFit: "contain",
    top: 0,
    left: 0,
    float: "left",
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main
  },
  linearType: {
    position: "absolute",
    width: 30,
    height: 30,
    padding: theme.spacing.unit * 0.5,
    alignItems:"center",
    objectFit: "contain",
    top: 0,
    left: 0,
    float: "left",
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main
  }
});

function PlayableIcon({obj, classes}){
  if(Continuum.isLive(obj)){
    return (
      <svg className={classes.liveType} height="597pt" viewBox="-18 -128 597.33331 597" width="597pt" xmlns="http://www.w3.org/2000/svg"><path d="m560 22.832031c0-16.566406-13.433594-30-30-30h-500c-16.566406 0-30 13.433594-30 30v280c0 16.570313 13.433594 30 30 30h500c16.566406 0 30-13.429687 30-30zm-410 230h-80c-5.523438 0-10-4.476562-10-10v-160c0-5.519531 4.476562-10 10-10s10 4.480469 10 10v150h70c5.523438 0 10 4.480469 10 10 0 5.523438-4.476562 10-10 10zm60-10c0 5.523438-4.476562 10-10 10s-10-4.476562-10-10v-160c0-5.519531 4.476562-10 10-10s10 4.480469 10 10zm159.371094-156.488281-60 160c-1.464844 3.90625-5.199219 6.496094-9.371094 6.488281h-.277344c-4.269531-.117187-7.996094-2.921875-9.273437-7l-50-160c-1.65625-5.273437 1.273437-10.894531 6.550781-12.550781 5.273438-1.652344 10.894531 1.277344 12.550781 6.554688l41.308594 132.207031 49.769531-132.738281c1.933594-5.175782 7.695313-7.804688 12.871094-5.871094s7.804688 7.695312 5.871094 12.871094zm100.628906 66.488281c5.523438 0 10 4.480469 10 10 0 5.523438-4.476562 10-10 10h-50v60h70c5.523438 0 10 4.480469 10 10 0 5.523438-4.476562 10-10 10h-80c-5.523438 0-10-4.476562-10-10v-160c0-5.519531 4.476562-10 10-10h80c5.523438 0 10 4.480469 10 10 0 5.523438-4.476562 10-10 10h-70v60zm0 0"/>
      </svg>
    );
  }else if(Continuum.isLinear(obj)){
    return (
      <LinearIcon color="primary" className={classes.liveType}/>
    );
  }
  return "";
}

function ContentCard(props) {
  const {obj, classes} = props;
  if (isEmpty(obj) || !obj.meta || isEmpty(obj.meta)) {
    return "";
  }
  let offeringInfo = obj.meta.offeringInfo;
  let mandatory = false;
  let accessCharge = 0.0;

  if (!isEmpty(obj.meta.access_charge)) {
    accessCharge = parseFloat(obj.meta.access_charge);
  }

  if (!isEmpty(offeringInfo)) {
    mandatory = offeringInfo.mandatorySponsoring;
  }

  const onInfoClicked = (obj) =>{
    console.log("onInfoClicked - " + JQ(obj));
    if(!isEmpty(obj)){
      PubSub.publish(PubSub.TOPIC_OPEN_JSONVIEW, obj);
    }
  }

  return (
    <Card key={obj.id} className={classes.imageCard}>
      <CardActionArea
        component={Link}
        to={{ pathname: "/playerpage", state: { obj: obj } }}
      >
        <CardMedia
          component="img"
          alt="Video Poster"
          className={classes.cover}
          height="150"
          image={obj.meta.imageUrl ? obj.meta.imageUrl : ""}
          title="Video"
        >
        </CardMedia>
      </CardActionArea>
      <CardContent>
      <Typography noWrap gutterBottom variant="h5" component="h2" className={classes.feature_title}>
          {obj.meta.asset_metadata ? obj.meta.asset_metadata.title: obj.meta.name}
      </Typography>
      <Typography noWrap component="p" className={classes.feature_description}>
          {obj.meta.asset_metadata ? obj.meta.asset_metadata.synopsis: obj.meta.description}
      </Typography>
      </CardContent>

      <CardActions className={classes.card_controls}>
        <IconButton aria-label="Add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="Share">
          <ShareIcon />
        </IconButton>
        <div className={classNames(classes.expand)}>
          <IconButton aria-label="Info" color="primary"
            onClick={e => {onInfoClicked(obj)}}
          >
            <InfoIcon />
          </IconButton>
        </div>
      </CardActions>
      <ChargeBadge mandatory={mandatory} accessCharge={accessCharge} />
      {PlayableIcon({obj,classes})}
    </Card>
  );
}

export default withStyles(styles)(ContentCard);