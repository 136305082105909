import React, { Component } from 'react';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import DeleteIcon from "@material-ui/icons/Clear";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Card from '@material-ui/core/Card';
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import Typography from "@material-ui/core/Typography";
import { withStyles } from '@material-ui/core';
import PubSub from '../../pubsub'
import {JQ,isEmpty,localeDateTime} from '../../utils/helpers'
import Continuum from '../../clients/continuum'
import Accounts from "../../storage/accounts"
import {ElvAVStream} from "../../clients/elv-avstream-module"
import { Link } from 'react-router-dom';
import getUserLocale from 'get-user-locale';
import { DateTimePicker } from "@material-ui/pickers";
import { ElvAVSchedule } from '../../clients/elv-schedule-module';
// import ContentObjectABI from "@eluvio/elv-client-js/src/contracts/BaseContent.js"
// import LvRecording from "@eluvio/elv-client-js/src/contracts/LvRecording.js"



const RECORDING_POLLING_MS = 1000*10;

const styles = theme => ({
  margins: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },
  padding: {
    padding: theme.spacing(4),
  },
  noMargins: {
    margin: 0,
    padding: 0,
  },
  title: {
    fontSize: "2em",
    width: "100%"
  },
  recording_title: {

  },
  stream_title: {
    fontSize: "1.2em",
    marginTop: theme.spacing(2)
  },
  container: {
    position: "relative",
    height: 200,
    //overflow: "hidden"
  },
  row: {
    display:"flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width:"100%"
  },
  paper: {
    minHeight: 400
  },
  deleteButton: {

  },
  column: {
    //marginTop: theme.spacing.unit*2,
    display:"flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "space-between",
    padding:theme.spacing.unit,
  },
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
  },    
  datetime_container: {
    display: "flex",
    flexWrap: "noWrap",
    fontWeight: 200,
    fontSize: "0.7em",
    flexGrow: 0,
    width: "100%",
    marginBottom: theme.spacing(1),
    paddingLeft:theme.spacing.unit
  },
  datetime: {
    fontWeight: 200,
    fontSize: "0.7em",
    marginRight: theme.spacing(2)
  },

});

class MyRecordings extends Component {

  constructor(props){
    super(props);
    const account = Accounts.getCurrent();
    let name = null;
    if(account){
      name = account.name;
    }
    this.state = {
      account: account,
      title:name? `${name}'s Page`: "",
      defaultChannel: null,
      selectedChannelId: "",
      channels: [],
      featured_videos: [],
      streamMap: {},
      disableButtons: false
    };

    PubSub.subscribe(this, PubSub.TOPIC_NEED_REFRESH, (msg, data) => {
      console.log("My Videos TOPIC_NEED_REFRESH received");
      this.refresh();
    });

    PubSub.subscribe(this, PubSub.TOPIC_PROGRESS_START, (msg, data) => {
      this.setState({disableButtons: true });
    });

    PubSub.subscribe(this, PubSub.TOPIC_PROGRESS_DONE, (msg, data) => {
      this.setState({disableButtons: false });
    });

    /*if(!this.recordingInterval){
      this.recordingInterval = setInterval(this.refresh, RECORDING_POLLING_MS);
    }*/
  }

  componentWillMount() {
    this._ismounted = true;
    this.refresh();
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  hasMounted(){return this._ismounted === true;}

  onInfoClicked = obj => {
    console.log("onInfoClicked - " + JQ(obj));
    if (!isEmpty(obj)) {
      PubSub.publish(PubSub.TOPIC_OPEN_JSONVIEW, obj);
    }
  };

  handleRecordingClick = obj => {
    console.log("handleRecordingClick - " + JQ(obj));
    if (!isEmpty(obj)) {
      this.props.history.push({pathname:'/playerpage'});
      PubSub.publish(PubSub.TOPIC_PLAY_RECORDING, obj);
    }
  };

  onDeleteRecordingClicked = async obj => {
    console.log("onDeleteRecordingClicked - " + JQ(obj));
    if (!isEmpty(obj)) {
      PubSub.publish(PubSub.TOPIC_PROGRESS_START);
      try{
          await Continuum.deleteObject({
            libraryId: await ElvAVStream.GetRecordingsLibraryId(Continuum.client), 
            objectId:obj.id});
          let recordingsMap = this.state.recordings;
          console.log("RecordingsMap: " + JQ(recordingsMap));
          if(!isEmpty(recordingsMap)){
            for(var i in recordingsMap){
              var recordings = recordingsMap[i];
              if(!isEmpty(recordings)){
                recordings = recordings.filter(function( recording ) {
                  return recording.id !== obj.id;
                });
                recordingsMap[i] = recordings;
              }
            }
            this.setState({recordings:recordingsMap});
  
            this.refresh();
          }
      }catch(e){
        console.error("Error deleting recording: " + JQ(e));
      }
      PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
    }
  };


  refresh = async () => {
    if(!this.hasMounted() || this.isFetching){
      return;
    }
    this.isFetching = true;
    console.log("MyRecordings refresh.");
    PubSub.publish(PubSub.TOPIC_PROGRESS_START);
    if(Continuum.client){
      if(!Continuum.getAccountAddress()){
        PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
        return;
      }
      ElvAVStream.FindRecordings(Continuum.client).then(async recordingsMap => {
        if(!recordingsMap){
          PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
          return;
        }
        // console.log(`Found ${JQ(Object.keys(recordings).length)} recordings}`);
        let newRecordingsMap = {};
        for(var k in recordingsMap){
          let recordings = recordingsMap[k];
          if(isEmpty(recordings)){
            continue;
          }
          try{
            let streamMap = this.state.streamMap;
            if(isEmpty(streamMap[k])){
              let result = await Continuum.contentData.get({objectId:k,validate:false,noAuth:false});
              if(result){
                console.log(`Stream found: ${JQ(result.meta.name)}`);
                if(!streamMap){
                  streamMap = {};
                }
                streamMap[k] = result;
                this.state.streamMap = streamMap;
              }
            }
          }catch(error){
            console.log("Error fetching stream info " + JQ(error));
          }

          let newRecordings = [];
          for(var i=0; i < recordings.length; i++){
            let recording = recordings[i];
            if(isEmpty(recording)){
              continue;
            }
            // console.log(`Recording ${JQ(recording)}`);
            
            try{

              let result = await ElvAVStream.ProvisionRecording(recording.id,Continuum.client, ElvAVSchedule);
              console.log("result: " + JQ(result));
              let {startTime, endTime} = result;
              if(startTime.getFullYear() > 2000){
                recording.startTime = startTime;
              }
              
              if(endTime.getFullYear() > 2000){
                recording.endTime = endTime;
              };

            }catch(error){
              console.log("Error fetching recording extra info for " + recording.id + "." + JQ(error));
            }
            newRecordings.push(recording);
          }

          // console.log(`Recording for ${k}: ${JQ(newRecordings)}}`);
          newRecordingsMap[k]=newRecordings;

        }
        this.setState({recordings:newRecordingsMap});
        PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
      },
      error =>{
        console.log("Error refreshing recordings: " + JQ(error));
        PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
      });
    }
    this.isFetching = false;
  }

  renderRecordings = ({classes, heading, recordings, streamId, disableButtons}) => {
    if(!recordings || recordings.length === 0){
      return (
        <Typography noWrap gutterBottom variant="h5" className={classes.subheading}>
            No recordings.
        </Typography>
      );
    }

    console.log("render recordings: " + JQ(streamId));

    let stream = this.state.streamMap[streamId];
    if(stream && stream.meta){
      console.log("Stream: " + stream.meta.name);
    }

    return (
      <>
      <Typography noWrap gutterBottom variant="h5" className={classes.stream_title}>
        {stream && stream.meta.name ? stream.meta.name : ""}
      </Typography>
      <List key={streamId} className={classNames(classes.noMargins, classes.recordings)}>
        {recordings.map((obj,index) => (

        <ListItem key={index} >
            <Card key={index} className={classNames(classes.row)}>
              <div key={index}>
                <IconButton
                      aria-label="Info"
                      color="primary"
                      size="medium"
                      disabled={disableButtons}
                      onClick={e => {
                          this.onInfoClicked(obj);
                      }}>   
                        <InfoIcon fontSize="inherit" />
                </IconButton>
              </div>

              <Button 
                component={Link} to={{ pathname: "/playerpage", state: { recording: obj} }}
                className={classes.row}
                disabled={disableButtons}
              >
            
              <div className={classNames(classes.column)}>
                <Typography noWrap gutterBottom variant="subtitle1" className={classes.recording_title}>
                    {obj.meta.program_title ? obj.meta.program_title : obj.meta.name}
                </Typography>
                  <div className={classes.datetime_container}>
                  { 
                  !isEmpty(obj.startTime)?
                      <DateTimePicker
                        disableToolbar
                        label="Start Date"
                        value={obj.startTime}
                        onError={console.log}
                        readOnly
                        format={localeDateTime()}
                        className={classes.datetime}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        locale={getUserLocale()}
                      /> : null
                  }
                  { 
                  !isEmpty(obj.endTime)?
                      <DateTimePicker
                        disableToolbar
                        label="End Date"
                        value={obj.endTime}
                        onError={console.log}
                        readOnly
                        format={localeDateTime()}
                        className={classes.datetime}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        locale={getUserLocale()}
                      />
                   : null }
                  </div>
                {obj.meta.description ? 
                <Typography component="p" className={classes.feature_description}>
                    {obj.meta.description}
                </Typography>
                : ""}
              </div>
              </Button>
              <div>
                <IconButton 
                  size="small"
                  onClick={e => {
                      this.onDeleteRecordingClicked(obj);
                  }}
                  disabled={disableButtons}
                >
                  <DeleteIcon fontSize="inherit" color="grey" />
                </IconButton>
              </div>
            </Card>
        </ListItem>
        ))}
      </List>
      </>
    );
  }

  render() {
    const { classes } = this.props;
    const {recordings, streamId, disableButtons} = this.state;
    
    if(!recordings){
      return "";
    }

    return (
      <div className={classNames(classes.root, classes.margins)}>
      <Paper className={classNames(classes.paper, classes.padding)}>
        <Typography
          variant="h2"
          align="center"
          className={classes.title}
        >
          My Recordings
        </Typography>
              {
                Object.keys(recordings).map((streamId) => {
                  console.log(" STREAM: " + streamId);
                  var streamRecordings = recordings[streamId];
                  if(isEmpty(streamRecordings)) return "";
                
                  return(
                    this.renderRecordings({
                      streamId,
                      classes,
                      heading:"Recordings",
                      recordings:streamRecordings,
                      disableButtons
                    })
                  );
                })
              }
      </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(MyRecordings);