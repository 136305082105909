import { JQ } from "../utils/helpers";

//TODO: apply to favric accounts as well?
class Accounts {
  accountsMap = {}
  async load(){
    try{
      let storedAccounts = localStorage.getItem("elv-accounts");
      // console.log("Accounts read from localstorage: " + storedAccounts);
      if(storedAccounts) {
        let str = atob(storedAccounts);
        // console.log("Accounts string: " + str);
        this.accountsMap = JSON.parse(str);
        console.log("Accounts loaded successfully: " + JQ(this.accountsMap));
      }
    }catch(e){
      console.error("Error loading accounts: " + JQ(e));
    }
  }

  getAccounts(){
    return this.accountsMap;
  }

  addAccount({name,address,image,encryptedPrivateKey}) {
    if(address == null){
      let error = "Could not add account. Address is null.";
      throw error;
    }
    this.accountsMap[address] =
      {name,address,image,encryptedPrivateKey};

    this.save();
    this.setCurrent(address);
  }

  deleteAccount(address){
    delete this.accountsMap[address];
    this.save();
  }

  setCurrent = (address) => {
    localStorage.setItem(
      "elv-current-account",
      address
    );
    console.log("Saved current " + address);
  }

  getCurrent = () => {
    let address = localStorage.getItem(
      "elv-current-account"
    );

    // console.log("Retrieved current " + address);
    return this.accountsMap[address];
  }

  save = async () => {
    try{
      //XXX:
      let savedAccount = btoa(JSON.stringify(this.accountsMap));
      // console.log("Accounts save: " + JQ(this.accountsMap));
      localStorage.setItem(
        "elv-accounts",
        savedAccount
      );
      console.log("Accounts saved successfully." );
    }catch(e){
      console.error("Error loading accounts: " + JQ(e));
    }
  };
};

let accounts = null;

if (!accounts) {
  accounts = new Accounts();
}
export default accounts;