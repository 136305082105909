import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import PubSub from '../../pubsub'
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CloseIcon from '@material-ui/icons/Close';
import Continuum from "../../clients/continuum"
import UserIcon from '@material-ui/icons/Person';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { isEmpty, JQ } from '../../utils/helpers';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const downscale = require('downscale');
const maxImageWidth = 150;

const styles = theme => ({
    title: {
        flexGrow: 1,
        marginTop: theme.spacing.unit
    },
    fab: {
        width: 70,
        height: 70,
        backgroundColor: "transparent",
        margin: "auto",
        padding: theme.spacing.unit
    },
    image: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
    },
    accountName: {
        margin: "auto",
        align: 'center',
        maxWidth: 100,
        color: "grey"
    },
    dialog_message: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: "90%",
        paddingBottom: 0,
    },
    nomargin: {
        margin: "auto",
        padding: 0,
    },
    dialog_content: {
        display: "flex",
        flexFlow: "wrap",
        flexDirection: "row",
        justifyContent: "spaceBetween",
        flexGrow: 1,
        width: "100%",
        maxHeight: 350,
        margins: theme.spacing.unit*2
    },
    dialog_root: {
        display: "flex",
        flexDirection: "column",
        justifContent: "center"
    },
    textField: {
        width: 350,
    },
    input: {
        color: "grey"
    },
    row: {
      margins: theme.spacing.unit,
      display:"flex",
      flexDirection: "row",
      padding:theme.spacing.unit*2,
      justifContent:"flex-start",
      margin: "0 auto"
    },
    column: {
        marginTop: theme.spacing.unit*2,
        display:"flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignItems: "flex-start",
        padding:theme.spacing.unit,
    },
    imageCard: {
      display: 'flex',
      position: 'relative',
      height: maxImageWidth,
      maxWidth: maxImageWidth,
      minWidth: maxImageWidth,
      margins: 0,
      marginTop: theme.spacing.unit*2,
      padding: 0,
    
      objectFit: "cover",
    },
    cover: {
      objectFit: "cover",
      alignItems: 'center',
      width: "100%",
      height: "100%",
      margins: 0
    },
    noDisplay: {
      display:"none"
    },
});

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },

}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <div >{children}</div>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing.unit * 5
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit,
    },
}))(MuiDialogActions);

class AddAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            error: "", 
            message: "", 
            name: "New Account",
            password: "",
            privateKey: "",
            title: props.title || "Add Account",
            confirmed: false
        };

        PubSub.subscribe(this,PubSub.TOPIC_OPEN_ADDACCOUNT, (msg, data) => {
            if(!Continuum.isFrameClient){
                const mnemonic = Continuum.generateMnemonic();
                this.setState({ open: true, 
                    mnemonic, 
                    error: "", 
                    message: "", 
                    name: "New Account",
                    password: "",
                    importType: "mnemonic",
                    privateKey: "",
                    confirmed: false });
            }
        });
        PubSub.subscribe(this,PubSub.TOPIC_CLOSE_DIALOG, (msg, data) => {
            this.setState({ open: false });
        });
    }

    componentWillMount() {

    }

    handlePrivateKeyChange = (event) => {
        this.setState({ privateKey: event.target.value });
        console.log("handlePrivateKeyChange");
    }


    handlePasswordChange = (event) => {
        this.setState({ password: event.target.value });
        console.log("handlePasswordChange");
    }

    handleNameChange = (event) => {
        this.setState({ name: event.target.value });
    }

    handleMnemonicChange = (event) => {
        let mnemonic = event.target.value;
        this.setState({ mnemonic});
        console.log("handleMnemonicChanged");
    }


    handleGenerateMnemonic = (event) => {
        let mnemonic = Continuum.generateMnemonic();
        this.setState({ mnemonic});
        console.log("handleGenerateMnemonic");
    }


    handleConfirm = async(event) => {
        console.log("Signin handleConfirm");
        const {password,name,mnemonic, imagePreview, importType, privateKey} = this.state;
        if (isEmpty(password)){
            this.setState({error:"Please enter a password"});
            return;
        }

        if (isEmpty(name)){
            this.setState({error:"Please enter a password"});
            return;
        }

        if (isEmpty(mnemonic) && importType === "mnemonic"){
            this.setState({error:"Please enter a mnemonic or generate a new one."});
            return;
        }


        if (isEmpty(privateKey) && importType === "privateKey"){
            this.setState({error:"Please enter a mnemonic or generate a new one."});
            return;
        }

        try{
            if (importType === "mnemonic"){
                Continuum.addSignerWithMnemonic(name,imagePreview,mnemonic,password);
                this.handleClose(event);
            }else if(importType === "privateKey"){
                Continuum.addSigner(name,imagePreview,privateKey,password);
                this.handleClose(event);
            }else{
                console.error("Could not add account. Uknown import type: " + importType);
            }
        }catch(e){
            console.error("Could not add account: " + JQ(e));
        }
    }

    handleClose = (event) =>  {
        this.setState({ open: false });
    }

    componentDidMount() {
        console.log("Singin componentDidMount()");
    }

    imageChangeHelper = (event, stateKey) =>{
        let f = event.target.files[0];
        let that = this;
        var reader = new FileReader();
        // Closure to capture the file information.
        reader.onload = ( function(theFile) {
            return function(e) {
            console.log("Done preview " + JQ(theFile));
            console.log("image: " + JQ(e.target.result));
            downscale(e.target.result,128,128).then(
                function(thumb){
                    console.log("downsize: " + JQ(thumb));
                    that.setState({[stateKey]:thumb});
                }
            );
            };
        })(f);

        reader.readAsDataURL(f);
    }

    handleImageChange = event => {
        this.imageChangeHelper(event,"imagePreview");
    }

    RenderAccountCreation = (props) => {
      const {classes, mnemonic, imagePreview, password, privateKey, account, importType} = props;

      let image = imagePreview;

      if(!image && account && account.image){
        image = account.image;
      }

      return (
        <div className={classes.row}>
            <div className={classes.column}>
            <Card className={classes.imageCard}>
                <input
                ref={'image-upload'}
                type='file'
                className={classes.noDisplay}
                accept="image/*"
                onChange={this.handleImageChange}
                />
                <Button
                    onClick={e => {
                        this.refs['image-upload'].click()
                    }}
                    className={classes.cover}
                > {
                image ? 
                    <CardMedia
                    component= "img"
                    alt="Profile Picture"
                    className={classes.cover}
                    image={image}
                    title="Image"
                    /> 
                : 
                    <UserIcon color="primary" className={classes.cover}/>
                }
                </Button>
            </Card>
            </div>
            <div className={classes.column}>
                <TextField
                    id="textfield-name"
                    label="Name"
                    defaultValue="New Account"
                    className={classes.textField}
                    margin="normal"
                    onChange={this.handleNameChange}
                    InputProps={{
                        className:classes.input
                    }}
                />

                <RadioGroup aria-label="Import Type" name="Import Type" value={importType} 
                    className={classes.row}
                    onChange={(event)=>{
                        this.setState({importType:event.target.value}
                    )}}
                >
                    <FormControlLabel color="primary" value="mnemonic" control={<Radio />} label="Mnemonic" />
                    <FormControlLabel color="primary" value="privateKey" control={<Radio />} label="Private Key" />
                </RadioGroup>
                
                
                {importType ==="mnemonic" ?
                    <>
                    <Button color="primary"
                            onClick={this.handleGenerateMnemonic}
                        >
                        Generate
                    </Button>
                    <TextField
                        id="textfield-mnemonic"
                        value={mnemonic}
                        multiline = {true}
                        rowsMax={importType ==="mnemonic" ? "4" : "0"}
                        variant='outlined'
                        className={classes.textField}
                        margin="normal"
                        onChange={this.handleMnemonicChange}
                        InputProps={{
                            className:classes.input,
                            color:"primary"
                        }}
                    />
                    </>
                :
                    <TextField
                        id="textfield-privatekey"
                        value={privateKey}
                        multiline = {false}
                        rowsMax="1"
                        variant='outlined'
                        className={classes.textField}
                        margin="normal"
                        onChange={this.handlePrivateKeyChange}
                        InputProps={{
                            className:classes.input,
                            color:"primary"
                        }}
                />
                }
                <TextField
                    label="Password"
                    placeholder="Enter Password"
                    type = "password"
                    className={classes.textField}
                    value={password}
                    onChange={this.handlePasswordChange}
                    margin="normal"
                />
            </div>
        </div>
      );
  };  

    render() {
        const { open, message, error, title, password, imagePreview, privateKey, name, account, mnemonic, importType} = this.state;
        const {classes} = this.props;

        let description = "Create new account or Import existing 12 word phrase.";

        if (!open) {
            return "";
        }   

        return (
            <Dialog
                disableBackdropClick
                open={open}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <div className={classes.dialog_root}>
                    <DialogTitle id="form-dialog-title">
                        <Typography gutterBottom variant="h5" className={classes.title}>
                            {title}
                        </Typography>
                        <Typography gutterBottom variant="subtitle1" color="textSecondary">
                            {description}
                        </Typography>
                    </DialogTitle>
                    <DialogContent className={classes.nomargin}>
                        {this.RenderAccountCreation({classes, mnemonic, imagePreview, privateKey, account, importType})}
                    </DialogContent>
                    <div className={classes.dialog_message}>
                        <Typography align='center' gutterBottom noWrap variant="subtitle1" color="textSecondary">
                            {message}
                        </Typography>
                        <Typography align='center' variant="subtitle1" color="error">
                            {error}
                        </Typography>
                    </div>
                    <DialogActions>
                        <Button disabled={false} onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button disabled={name === "" || password === "" || mnemonic === ""} onClick={this.handleConfirm} color="primary">
                            Confirm
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        );
    }
}


AddAccount.propTypes = {
    open: PropTypes.bool.isRequired
};

export default withStyles(styles)(AddAccount);