import React, { Component } from 'react';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import GridList from "@material-ui/core/GridList";
import ContentCard from "../contentviews/contentcard";
import PubSub from '../../pubsub'
import {JQ,isEmpty} from '../../utils/helpers'
import Continuum from '../../clients/continuum'
import Avatar from '@material-ui/core/Avatar';
import { Divider } from '@material-ui/core';

const styles = theme => ({
  homeRoot: {
    display: "flex",
    flexWrap: "noWrap",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow:"auto",
    margin: theme.spacing(5),
    paddingTop: theme.spacing(4),
  },
  paper: {
    height: "100%",
    width: "100%",
  },
  divider: {
    paddingBottom: theme.spacing(5),
  },
  titleRow: {
    display: "flex",
    flexWrap: "nowrap",
    overflow: "hidden",
    alignItems: "center",
    width: "100%"
  },
  titleColumn: {
    display:"flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "flex-start",
    width: "100%"
  },
  titleImage: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  heading: {
    margin: theme.spacing.unit,
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing.unit,
    fontWeight: 400,
    fontSize: "1.5em",
    width: "100%"
  },
  subheading: {
    margin: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    fontSize: "1.2em",
  },
  feature_container: {
    flexWrap: "nowrap",
    overflow: "hidden",
    marginBottom: theme.spacing.unit * 5
  },
  feature_list: {
    flexWrap: "nowrap",
    overflow: "hidden",
    justifyContent: "flex-start",
    '&:hover': {
      overflowX: "auto",
    },
  },
});

class Site extends Component {

  constructor(props){
    super(props);

    let site = props.site;

    if(isEmpty(site) && !isEmpty(props.location.state)){
      if(!isEmpty(props.location.state.site)){
        site = props.location.state.site;
      }
    }

    this.state = {
      title: null,
      categories: null,
      site: site,
    };

    console.log("Site contructor: " + JQ(site));
    this.refresh();

    PubSub.subscribe(this, PubSub.TOPIC_NEED_REFRESH, (msg, data) => {
      console.log("My Videos TOPIC_NEED_REFRESH received");
      this.refresh();
    });
  }

  refresh = async () => {
    console.log("Site refresh");
    if(this.isFetching || !this.state.site){
      return;
    }
    this.isFetching = true;

    //try{
      let site = this.state.site;
      let title = site.meta.public.asset_metadata.display_title ||
        site.meta.public.asset_metadata.title ||
        site.meta.public.name;
      let image = site.meta.imageUrl;

      console.log("Site title: " + title);
      let playlists = site.meta.public.asset_metadata.playlists;
      let categories = {};
      for(const label in playlists){
        console.log("category label: " + label);
        let playlist = playlists[label];
        console.log("playlist: " + JQ(playlist));
        let category = [];

        for(const key in playlist){
          let link = playlist[key];
          if(!link["/"]){
            continue;
          }

          let targetHash = null;
          if(link["/"].startsWith("/qfab/")) {
            targetHash = link["/"].split("/")[2];
          }

          if(isEmpty(targetHash)){
            continue;
          }

          let video = await Continuum.contentData.getByHash(targetHash);
          if(!isEmpty(video)){
            category.push(video);
          }
        }

        categories[label] = category;
      }

      console.log("Site categories: " + JQ(categories));
      this.setState({title, image, categories});
    //}catch (err) {
      // console.error("Could not fetch site data: " + JSON.stringify(err));
    //}
    this.isFetching = false;
  };

  render() {
    const { classes } = this.props;
    let {title, image, categories} = this.state;
    console.log("Site Render: " + title);

    if(!title){
      return null;
    }

    try {
      function RenderCategory(props){
        let {label, videos} = props;
        console.log("RenderCategory " + label);
        console.log(videos.length + " videos in category");

        if(isEmpty(videos)){
          console.log(label + " videos are empty.");
          return null;
        }

        return (
          <div className={classes.feature_container}>
          <Typography
            noWrap
            variant="h5"
            className={classNames(classes.subheading, classes.margins)}
          >
            {label}
          </Typography>
          <GridList justify="flex-start" className={classes.feature_list}>
            {videos &&
              videos.map((obj, index) => <ContentCard key={index} obj={obj} />)}
          </GridList>
          </div>
        );
      }
  
      return (
        <div className={classNames(classes.homeRoot)}>
        
          <div className={classes.titleRow}>
            <Avatar alt="Remy Sharp" src={image} className={classes.titleImage} />
            <div className={classes.titleColumn}>
              <Typography variant="title" className={classes.heading}>
                {title}
              </Typography>
            </div>
          </div>
          <div className={classes.divider}>
            <Divider />
          </div>

          {categories && Object.keys(categories).map((label,index) => {
            let videos = categories[label];
            if(isEmpty(videos)){
              console.log(label + " videos are empty.");
              return null;
            } 
          
            return(
              <RenderCategory label={label} videos={videos} />
            );
          })
          }

        </div>
      );
    }catch(error){
      console.error("Error rendering site: " + JSON.stringify(error));
      return null;
    }
  }
}

export default withStyles(styles)(Site);