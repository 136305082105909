import React, { Component } from 'react';
import axios from 'axios';

class WalletPage extends Component {
  // Initialize the state
  constructor(props){
    super(props);
    this.state = {
      list: []
    }
  }

  componentDidMount() {
    this.getAccounts();
  }

  getAccounts = () => {
    axios.get('/api/accounts')
    .then(res => {
      this.setState({list:res.data});
    })
    .catch(error => {
      console.log(error);
    });
  }

  render() {
    //const { list } = this.state;
    //const { classes } = this.props;
    
    return (
      <div>
      </div>
    );
  }
}

export default WalletPage;