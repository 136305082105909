import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import VerifyIcon from "@material-ui/icons/VerifiedUser";
import SaveIcon from "@material-ui/icons/SaveAlt";
import ClipIcon from "@material-ui/icons/LinearScale";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeMuteIcon from "@material-ui/icons/VolumeMute";
import Fullscreen from "@material-ui/icons/Fullscreen";
import FullscreenExit from "@material-ui/icons/FullscreenExit";
import DVRIcon from "@material-ui/icons/FiberManualRecord";
import ScheduleIcon from "@material-ui/icons/Schedule";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ReactPlayer from "react-player";
import Slider from "rc-slider";
import MUISlider from '@material-ui/core/Slider';
import Link from '@material-ui/core/Link';
import Fade from '@material-ui/core/Fade';
import { DateTimePicker } from "@material-ui/pickers";
import getUserLocale from 'get-user-locale';
import {
  green,
  blue,
  grey,
  deepOrange
} from "@material-ui/core/colors";

import PubSub from "../../pubsub";
import Continuum from "../../clients/continuum";
import { isEmpty, JQ, localeDateTime } from "../../utils/helpers";
import "rc-slider/assets/index.css";
import BBoxIcon from '@material-ui/icons/CheckBoxOutlineBlank' 
import BBoxCheckedIcon from '@material-ui/icons/CheckBox' 
import {toSeconds, parse} from 'iso8601-duration';
import {FormattedTime, PlayerIcon} from 'react-player-controls'
import screenfull from 'screenfull'
import { findDOMNode } from 'react-dom'
import {ElvAVStream} from "../../clients/elv-avstream-module"
//import {Schedule} from "../../clients/schedule"
import {ElvAVSchedule} from "../../clients/elv-schedule-module"
import Guide from "./guide"
import ContentObjectABI from "@eluvio/elv-client-js/src/contracts/BaseContent.js"
var ab2str = require('arraybuffer-to-string')
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const REFRESH_MS = 1000*10;

const styles = theme => ({
  player_root:{
    display: "flex",
    flexWrap: "noWrap",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow:"auto"
  },
  verified: {
    color: green[600]
  },
  unverified: {
    color: grey[300]
  },
  failedVerification: {
    color: deepOrange[600]
  },
  video1: {
    width: "100%",
    height: "100%",
    backgroundColor: "black"
  },
  video: {
    width: "100%",
    height: "100%",
    backgroundColor: "black",
    opacity: "1 !important"
  },
  annotation_layer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1,
    backgroundColor: "transparent",
    pointerEvents: "none"
  },
  ad_img:{
    display: "block",
    margin: "0 auto",
    objectFit: "contain",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#17ABC0",
    backgroundColor: "black"
  },
  ad_layer:{
    display: "block",
    width: "500px",
    height: "120px",
    backgroundColor: "transparent",
    margin: "0 auto",
    opacity: ".8",
    color: "#ffffff",
    marginBottom: 100,
    pointerEvents: "auto"
  },
  grow:{
    flex:1
  },
  ad_container:{
    position: "absolute",
    display:"flex",
    top:"0px",
    left:"0px",
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
    justifyContent: "space-between",
    flexDirection: "column",
    zIndex: 2,
    pointerEvents: "none"
  },
  video_controls_container: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    zIndex: 5,
    backgroundColor: "rgba(0,0,0,0.6)"
  },
  videoMessage:{
    textAlign:"center",
    position: "relative",
    left: 0,
    top: "40%",
    width: "100%",
    height: "100%",
    fontSize: "1.3em",
    color: "#ededed"
  },
  video_container:{
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundColor: "black"
  },
  videoContainer: {
    width: "100%",
    height: 700,
    [theme.breakpoints.down(1000)]: {
      height: 500
    },
    [theme.breakpoints.down(800)]: {
      height: 400
    },
    [theme.breakpoints.down(600)]: {
      height: 300
    },
    [theme.breakpoints.down(400)]: {
      height: 200
    },
    objectFit: "contain",
  },
  titleContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit,
    verticalAlign: "middle",
  },
  videoCard: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    backgroundColor: "black",
  },
  playerTitle: {
    flexGrow: 1,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  controls: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    display:"flex",
    flexDirection: "row",
    alignItems:"center",
  },
  seekbar: {
    width: "100%",
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  },
  range: {
    width: "100%",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 2
  },
  padding: {
    padding: theme.spacing.unit,
    margin: theme.spacing.unit,
    display: "flex"
  },
  advanced_controls: {
    display: "flex",
    flexWrap: "noWrap",
    overflow: "hidden",
    float: "right"
  },
  row: {
    display: "flex",
    flexWrap: "noWrap",
    overflow: "hidden",
    width: "100%",
    alignItems:"center"
  },  
  volume_container: {
    display: "flex",
    flexWrap: "noWrap",
    overflow: "hidden",
    width: 120,
    alignItems:"center"
  },  
  video_controls_row: {
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
    width: "100%",
    alignItems:"center",
  },
  controls_left: {
    flexGrow: 1,
    display: "flex",
    flexWrap: "nowrap"
  },
  controls_right: {
    display: "flex",
    flexWrap: "nowrap",
    flexGrow: 0,
    alignItems:"center",
  },
  description: {
    color: "grey",
    flexGrow: 1
  },
  tags: {
    color: "grey",
    fontWeight: 200,
    fontSize: "0.8em",
    flexGrow: 0
  },
  datetime_container: {
    display: "flex",
    flexWrap: "noWrap",
    fontWeight: 200,
    fontSize: "0.7em",
    flexGrow: 0,
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  datetime: {
    fontWeight: 200,
    fontSize: "0.7em",
    marginRight: theme.spacing(2)
  },
  time_links:{
    fontWeight: 200,
    fontSize: "0.8em",
    marginLeft: theme.spacing.unit
  },
  tagSearch:{
    color: "grey",
    fontWeight: 200,
    flexGrow: 0,
  },
  ControlIcon: {
    marginRight: theme.spacing.unit
  },
  advanced_control_button: {
    color: "white",
    backgroundColor: "black",
    fill: "white",
    marginTop: theme.spacing.unit,
    "&:hover": {
      backgroundColor: "black"
    },
  },
  videoControl: {
    color: "white",
    backgroundColor: "transparent",
    fill: "white",
    width: 18,
    height: 18,
  },
  videoControlButton: {
    "&:hover": {
      backgroundColor: "rgba(100,100,100,.3)"
    },
  },
  videoControlMUI: {
    color: "white",
    backgroundColor: "transparent",
    fill: "white",
    width: 24,
    height: 24,
  },
  dvrNormal: {
    color: "white",
    backgroundColor: "transparent",
    fill: "white",
    width: 24,
    height: 24,
  },
  dvrOn: {
    color: "white",
    backgroundColor: "transparent",
    fill: "red",
    width: 24,
    height: 24,
  },
  scheduleRecording: {
    color: "red"
  },  
  scheduleNormal: {
    color: theme.palette.primary.main
  },
  videoTime: {
    color: "white",
    marginLeft: theme.spacing.unit,
    fontFamily: "Arial",
    display: "flex",
    flexWrap: "wrap",
    alignItems:"center",
    justifyContent: "start",
    overflow: "auto",
    width: "100%",
  },
  formControl: {
    minWidth: 120,
    marginRight: theme.spacing.unit
  },
  titleButton: {
    width: 32,
    height: 32,
    objectFit: "contain",
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main
  },
  titleIcon: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    fill: "red",//theme.palette.primary,
    width: "100%",
    height: "100%",
  },
  volumeSlider: {
    color: "white"
  },
  vcenter:{
    alignItems:"center",
  }
});

const VolumeSlider = withStyles({
  root: {
    color: '#52af77',
    height: 4,
  },
  thumb: {
    height: 10,
    width: 10,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -3,
    marginLeft: -6,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
  },
})(MUISlider);


class PlayerPage extends Component {
  state = {
    obj: null,
    clipMode: false,
    url: null,
    adUrl: null,
    pip: false,
    playing: false,
    playingAd: false,
    controls: false,
    light: false,
    volume: 80,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    isInit: false,
    showBounding: false,
    progressInterval: 100,
    recording: false,
    showTagSearch: false,
    loading: true
  };

  constructor(props) {
    super(props);
    let content = props.content;
    //mappings
    this.currentElement = null;
    this.accessRequests = {};
    this.accessCompletes = {};
    this.prevTags = {};
    this.annotation = React.createRef();
    this.video = React.createRef();
    this.videoContainer = React.createRef();
    this.timelineObj = null;

    this.RenderControls = this.RenderControls.bind(this);
    this.RenderVolumeControl = this.RenderVolumeControl.bind(this);
    this.RenderSeekBar = this.RenderSeekBar.bind(this);
    this.RenderDVRControl = this.RenderDVRControl.bind(this);

    if(isEmpty(content) && !isEmpty(props.location.state)){
      if(!isEmpty(props.location.state.obj)){
        content = props.location.state.obj;
      }
      if(!isEmpty(props.location.state.recording)){
        content = props.location.state.recording;
        this.state.recording = true;
        this.state.recordingObj = content;
        console.log("Player loaded recording object.");
      }
    }

    console.log("Player page: " + JQ(content));
    if(!this.state.recording){
      this.state.obj = content;
    }

    /*

    try{
      if (!isEmpty(content)) {
        this.state.obj = content;
        if(!isEmpty(content.meta.choices) && content.meta.choices.length > 0){
          this.state.language = this.langToIso(content.meta.choices[0].name);
          let lang = Continuum.appData.getPrefLanguage();
          for(var index in content.meta.choices){
            let item = content.meta.choices[index];
            if(this.langToIso(item.name) === lang){
              this.state.language = lang;
              break;
            }
          }
      
        //this.start("parsing frame tags");
        Continuum.client.DownloadFile({
          objectId:content.id,
          versionHash:content.hash,
          filePath:"elv_media_platform_video_tags.json",
          format:"arrayBuffer"
        }).then(
        async result =>{
          try{
            console.log("tag: " + result);
            console.log("Finished downloading tags.");
            let tags = JSON.parse(ab2str(result));

            this.timelineObj = tags["elv_media_platform_video_tags"];
            if(!isEmpty(this.timelineObj)){
              this.frameTags = this.timelineObj["frame_level_tags"];
              if(isEmpty(this.frameTags)){
                console.log("no frame_level_tags");
              }
              this.fps = this.timelineObj.frame_per_sec;
              if(isEmpty(this.fps)){
                console.error("No frame_per_sec in elv_media_platform_video_tags");
              }
              this.videoTags = this.timelineObj["video_level_tags"];
            }else{
              console.log("No elv_media_platform_video_tags.");
            }
      
          }catch(error){
            console.error("Error parsing tags: " + JQ(error));
          }
        },
        error =>{
          console.log("No frame level tags found: " +  JQ(error));
        });

        try{
          console.log("Adding overlay: " + content.meta.overlay);
          if(!isEmpty(content.meta.overlay)){
            this.state.overlayImage=content.meta.overlay.imageUrl;
            this.state.overlayLink= content.meta.overlay.linkUrl;
          }
        }catch(error){
          console.error(JQ(error));
        }

        if(!isEmpty(content.meta.video_tags_index)){
          console.log("Found video_tags_index " + JQ(content.meta.video_tags_index))
          this.state.searchedTags = content.meta.video_tags_index;
        }

        }else{
          this.state.language = "en";
        }
      }
    }catch(error){
      console.error("Error parsing content: " + JQ(error));
    }
    */
  }
  
  async componentWillMount(){
    console.log("Player componentWillMount");
    if(!this.state.obj){
      return;
    }

    PubSub.subscribe(this,PubSub.TOPIC_REFRESH_RECORDINGS,async (msg,data)=>{
      console.log("PLAYER RECEIVED TOPIC_REFRESH_RECORDINGS: " + JQ(data));
      this.refreshRecordingInfo();
      this.refreshRecordings();
    });

    PubSub.subscribe(this, PubSub.TOPIC_NEED_REFRESH, (msg, data) => {
      console.log("PLAYER RECEIVED TOPIC_NEED_REFRESH");
      this.refresh();
    });

    PubSub.subscribe(this,PubSub.TOPIC_OPEN_SEARCH,async (msg,data)=>{
      console.log("PLAYER RECEIVED SEARCH KEYWORDS: " + JQ(data));
      if(!isEmpty(data)){
        this.searchTags(data, this.state.searchedTags);
        this.searchData = data;
      }else{
        this.searchData = "";
        this.setState({showTagSearch:false});
      }
    });

    PubSub.subscribe(this,PubSub.TOPIC_PLAY_RECORDING,async (msg,data)=>{
      console.log("PLAYER RECEIVED PLAY TOPIC_PLAY_RECORDING: " + JQ(data));
      if(this.video && this.state.avstream){
        this.loadRecording({obj:data});
      }
    });
  }

  hasMounted(){return this._ismounted === true;}

  refresh = async () => {
    // console.log("VideoList refresh hashMounted: " + this.hasMounted() + " isFetching: " + this.isFetching);
    if(!this.hasMounted() || this.isFetching){
      return;
    }
    this.isFetching = true;
    this.refreshRecordingInfo();
    this.refreshRecordings();
    this.isFetching = false;
  };

  refreshRecordingInfo = async (force) => {
    console.log("Refresh refreshRecordingInfo...");
    try{
      if(this.state.avstream && this.state.elvSchedule && this.state.recordingObj){
        this.start("Retrieving recording information");
        if(force || !this.recordingInfo || this.recordingInfo.objectId !== this.state.recordingObj.id){
          let recLibraryId = await Continuum.client.ContentObjectLibraryId({objectId:this.state.recordingObj.id});
          let recObjectId = this.state.recordingObj.id;
          let recAddr = await Continuum.client.CustomContractAddress({
            libraryId: recLibraryId,
            objectId: recObjectId
          });
          this.recordingInfo = {
            libraryId:recLibraryId,
            objectId: recObjectId,
            address: recAddr,
          };
        }

        if(this.recordingInfo){
          console.log("Provisioning...");
          console.log("Finding start and end times...");
          let result = await this.state.avstream.recordingTimes(this.recordingInfo.objectId);

          let {startTime, endTime} = result;
          let recordingObj = this.state.recordingObj;
          if(startTime.getFullYear() > 2000){
            recordingObj.startTime = startTime;
          }

          if(endTime.getFullYear() > 2000){
            recordingObj.endTime = endTime;
          }

          this.setState({recordingObj});
        }

        this.end();
      }
    }catch(error){
        console.error("Error refreshing recordings: " + JQ(error));
    }
  }

  refreshRecordings = async () => {
    if(this.refreshing){
      return;
    }
    this.refreshing = true;
    try{
      if(this.state.avstream && this.state.elvSchedule){
        console.log("Refresh recordings..");
        let recordingsList = await this.state.avstream.findRecordings(true);
        console.log("Number of Recordings: " + JQ(recordingsList.length))
        let recordable = await this.state.elvSchedule.isProgramRecordable();
        // console.log("IS PROGRAM RECORDABLE " + recordable);
        let newRecordings = [];
        for(var i=0; i < recordingsList.length; i++){
          let recording = recordingsList[i];
          if(isEmpty(recording)){
            continue;
          }

          let startTime = null;
          if(recording.meta 
            && recording.meta.live_offering
            && recording.meta.live_offering.media_struct
            ){
              startTime = recording.meta.live_offering.media_struct.requested_start_time_epoch_sec;
              if(startTime > 0){
                let date = new Date(0);
                date.setUTCSeconds(startTime);
                startTime = date;
              }
          }

          recording.startTime = startTime;

          // console.log(`Recording ${JQ(recording)}`);
          let endTime = await this.state.avstream.recordingEndTime(recording.id);
          if(endTime.getFullYear() > 2000){
            recording.endTime = endTime;
          };

          newRecordings.push(recording);
        }

        this.setState({recordingsList:newRecordings, recordable});
        console.log("Refresh schedule..");

        //Clears the cache
        await this.state.elvSchedule.getMetadata(true);
        let schedule = await this.state.elvSchedule.getScheduleByTime();
        // let date = new Date(schedule[0].start_time_epoch * 1000);

        if(schedule && schedule.length > 20){
          schedule = schedule.slice(0,20);
        }

        // console.log("SCHEDULE: " + JQ(schedule));
       
        let newSched = [];
        for(var i=0; i<schedule.length;i++){
          let program = schedule[i];
          //let pstartDate = new Date(0);
          //pstartDate.setUTCSeconds(program.start_time_epoch);
          // console.log("TESTING PROGRAM: " + program.program_id + " start: " +pstartDate.toString());
          program.recorded = await this.state.elvSchedule.isProgramRecorded(program);
          newSched.push(program);
        }
        schedule = newSched;

        //This controls color of the schedule and recording icon to red if the current proram
        //Is being recorded. Only should turn red on Live if it's being recorded.
        let recording = false;
        if(schedule.length > 0 && this.isLive()){
          recording = schedule[0].recorded;
        }
        let program = schedule[0];

        console.log("CurrentProgram: " + JQ(program));

        this.setState({recording,schedule, program});
      }
    }catch(error){
       console.error("Error refreshing recordings: " + JQ(error));
    }
    this.refreshing = false;
  }

  componentWillUnmount(){
    this.accessComplete();
    this._ismounted = false;
  }

  tagsForTime = (timecode,smooth=true) => {
    //console.log("tagForTime " + timecode + "frameTags: " + Object.keys(this.frameTags).length + " fps: " + this.fps);
    if(isEmpty(this.frameTags) || isEmpty(this.fps)){
      return "";
    }
    let framecalc = timecode * this.fps;
    //console.log("finding frame: " + framecalc);
    let frame = Math.floor(framecalc);
    //console.log("finding tag for frame: " + frame.toString());
    let tag = this.frameTags[frame.toString()];
    return tag;
  }

  clearAnnotationLayer(){
    try{
      console.log("Clear Annotation");
      var annotation = this.annotation.current;
      var ctx = annotation.getContext("2d");
      var width = annotation.width;
      var height = annotation.height;
      ctx.clearRect(0, 0, width, height);
    }catch(error){
      console.log(error);
    }
  }

  drawBounding = (timecode) => {
    if(isEmpty(timecode) || isEmpty(this.timelineObj)){
        return;
    }
    try{
        var annotation = this.annotation.current;
        var ctx = annotation.getContext("2d");
        var width = annotation.width;
        var height = annotation.height;
        this.clearAnnotationLayer();
        ctx.beginPath();
        let tagsContainer = this.tagsForTime(timecode);
        if(isEmpty(tagsContainer)){
          console.log("No tag for time: " + timecode);
          return;
        }
        let tags = tagsContainer.object_detection;
        this.prevTags[timecode.toString()] = tags;

        Object.keys(tags).forEach(function (tag) {
            let tagObjs = tags[tag];
            for(var i = 0; i < tagObjs.length; i++){
                var tagObj = tagObjs[i];
                if(tagObj.confidence < 0){
                    continue;
                }

                var x = Math.floor(tagObj.box.x1 * width);
                var y = Math.floor(tagObj.box.y1 * height);

                var w = Math.abs(x - tagObj.box.x2 * width);
                var h = Math.abs(y - tagObj.box.y2 * height);
                ctx.lineWidth = 3;
                ctx.strokeStyle = blue[400];
                ctx.fillStyle = blue[400];
                ctx.rect(x, y, w, h);
                ctx.font = "16px Arial";
                ctx.fillText(tag + " " + tagObj.confidence.toFixed(2), x+5, y-5);
                ctx.stroke();
                //console.log(tag + "w: " + w + " h:" +h+" : " + JQ(tagObj));
            }
        });
    }catch(error){
        console.log(error);
    }
  }

  async componentDidMount() {
    this._ismounted = true;
    if(this.isRecording() && !this.state.obj){
      if(!this.state.recordingObj || 
        !this.state.recordingObj.meta ||
        !this.state.recordingObj.meta.stream_id ||
        !this.state.recordingObj.meta.stream_library_id
        ){
          console.error("Error finding stream object from recording. Missing recording Obj metadata.");
          return;
      }
      let objectId = this.state.recordingObj.meta.stream_id;
      let libraryId = this.state.recordingObj.meta.stream_library_id;
      console.log("Getting stream meta: ");
      console.log("librayId: " + libraryId);
      console.log("objectId: " + objectId);

      try{
        this.state.obj = await Continuum.contentData.get({objectId, libraryId, validate:false});
      }catch(error){
         console.error("Error finding stream object from recording. " + JQ(error));
      }
      if(!this.state.obj){
        console.error("Error finding stream object from recording.");
      }
    }
    let obj = this.state.obj;
    console.log("Player componentDidMount " + JSON.stringify(this.state.obj));
    if(isEmpty(obj)){
      return;
    }

    let sponsorInfo = null;

    try {
      //Get customcontract
      this.start("Get Offering Contract Info");
      let offeringInfo = await Continuum.getOfferingContractInfo(
        obj.id,
        obj.hash
      );
      this.end();
      console.log("Offering info: " + JQ(offeringInfo));

      this.start("Querying user continuum.ads");
      if(!isEmpty(offeringInfo)){
        if (offeringInfo.mandatorySponsoring ||
          Continuum.appData.getPublicMeta("continuum.ads") === "view") {
          sponsorInfo = await Continuum.getSponsorInfo(obj);
          console.log("Sponsor info: " + JQ(sponsorInfo));
        }
      }
      this.end();

    } catch (err) {
      console.log("No offering information: " + JQ(err));
      //PubSub.publish(PubSub.TOPIC_ERROR, "Error finding offering info.");
    }

    //XXX: FIXME
/*
    Continuum.verifyContentObject(obj.hash).then( 
      result => {
        this.setState({verification:result});
      },
      error =>{
        console.error("Error getting verification information: " + error);
    });


    this.start("Get Schedule");
    if(!isEmpty(obj.meta.schedule)){
      Continuum.formatTagsCollection().then(result =>{
        this.userTags = result;
        let params={"tags":result};
        console.log("Retrieving resolved schedule for: "+ obj.hash + " user tags " + JQ(params));
        Continuum.callContentRep({ versionHash:obj.hash, 
          objectId:obj.id, rep:"schedule", params}).then( 
            async result => {
            if(result.ok){
              this.schedule = await result.json();
              console.log("Resolved schedule: " + JQ(this.schedule));
            }
          },error =>{
          console.error(error);
          });
      },error =>{
        console.error(error);
      });
    }
    this.end();

    */

    if (sponsorInfo) {
       this.loadAd({ adObj: sponsorInfo });
    } else {
      if(this.state.recording){
        this.loadRecording({obj:this.state.recordingObj});
      }else{
       this.load({ obj });
      }
    }
  }

  langToIso = (name) => {
    if(isEmpty(name)){
      return "";
    }
    if(name.toLowerCase() === "english"){
      return "en";
    }
    if(name.toLowerCase() === "german"){
      return "de";
    }
    if(name.toLowerCase() === "japanese"){
      return "ja";
    }
    if(name.toLowerCase() === "italian"){
      return "it";
    }
    return "";
  }

  isRecording = (obj) => {
    if(!obj){
      obj = this.state.recordingObj;
    }

    return Continuum.isRecording(obj);
  }

  isLive = (obj) => {
    if(this.isRecording(obj)){
      return false;
    }
    if(!obj){
      obj = this.state.obj;
    }

    return Continuum.isLive(obj);
  }

  isSiteAsset = (obj) => {
    //FIXME: Not very reliable.
    if(!isEmpty(obj.meta.asset_metadata)){
      return true;
    }

    return false;
  }

  initStreams = async (force) => {
    if(!Continuum.client){
      return;
    }
    if(this.isLive() || this.isRecording()){
      console.log("Checking avstream.");
      if(!this.state.avstream){
          const libraryId = await Continuum.client.ContentObjectLibraryId({objectId:this.state.obj.id});
          let avstream = new ElvAVStream({libraryId, objectId:this.state.obj.id, 
            typeHash:this.state.obj.type,ElvAVSchedule});
          avstream.setClient(Continuum.client);

          this.state.avstream = avstream;
          console.log("AVStream initialized.");

          console.log("Checking stream status...");
          let result = await avstream.checkStatus();
          console.log("Stream status result: " + result);
          this.setState({streamRunning:result});
      }

      console.log("Checking schedule.");
      if(!this.state.elvSchedule && this.state.obj.meta.schedule){
        console.log("Contains schedule: " + JQ(this.state.obj.meta.schedule));
        let schedLibraryId=this.state.obj.meta.schedule.library_id;
        let schedObjectId=this.state.obj.meta.schedule.object_id;
        let typeHash="schedule hash once defined.";
      
        let elvSchedule = new ElvAVSchedule({libraryId:schedLibraryId, 
          objectId:schedObjectId, 
          typeHash:typeHash, 
          client:Continuum.client, 
          ElvAVStream});

          this.state.elvSchedule = elvSchedule;
      }

      this.refreshRecordings();
      this.refreshRecordingInfo(force);
      if(!this.interval){
        this.interval = setInterval(this.refresh, REFRESH_MS);
      }

    }else{
      console.log("Not live or recording");
    }
  }

  loadRecording = async ({obj}) => {
    if(this.state.playing || this.state.playingRecording){
      this.accessComplete();
    }
    
    this.setState({loading:true, recordingObj:obj});
    await this.initStreams(true);
    await this.refreshRecordingInfo(true);

    if(!this.state.avstream){
      console.error("Error loading recording. AVStream not initialized.");
      PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
      this.setState({loading:false});
      return;
    }

    if(!this.recordingInfo){
      console.error("Error loading recording. Recording information not set.");
      PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
      this.setState({loading:false});
      return;
    }
    
    try{
      await this.state.avstream.getMetadata();
      let drms = await Continuum.availableDRMs();

      let protocols = ["hls"];
      let url = "";
      if(drms.includes("widevine")){
        protocols.push("dash");
      }
      let playoutOptions = await Continuum.client.PlayoutOptions({
        objectId: this.recordingInfo.objectId,
        protocols,
        drms: []
      });
      console.log("Recording PlayoutOptions: " + JQ(playoutOptions));
      if(!isEmpty(playoutOptions.hls)){
        if(!isEmpty(playoutOptions.hls.playoutUrl)){
          url = playoutOptions.hls.playoutUrl;
        }
      }

      if(isEmpty(url)){
        url = await this.state.avstream.getRecordingLiveUrl(
          this.recordingInfo.libraryId, 
          this.recordingInfo.objectId, 
          obj.hash);
      }

      PubSub.publish(PubSub.TOPIC_CURRENT_VIDEO, this.state.obj);

      console.log("Recording url: " + url);
      this.setState({
        recordingUrl:url,
        recordingObj:obj,
        loaded: 0,
        pip: false,
        clipMode: false,
        controls: true,
        playingAd: false,
        playingRecording: true,
        playing: true,
        light: false,
        isInit: true,
        loading:false
      });  
    } catch(e){
      console.error("Error loading recording: " + JQ(e));
    }
    this.setState({loading:false});
  }

  load = async ({ obj, lang }) => {
    if(this.state.playing || this.state.playingRecording){
      this.accessComplete();
    }
    console.log("Loading content: " + JQ(obj) + " lang: " + lang);
    let url = null;
    let requestID = "";
    PubSub.publish(PubSub.TOPIC_PROGRESS_START);
    this.setState({loading:true});

    await this.initStreams(true);

    if(this.isLive()){
      if(!this.state.streamRunning){
        PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
        this.setState({loading:false});
        return;
      }
    }

    //FIXME: widevine doesn't work yet
    // let drms = await Continuum.availableDRMs();
    // console.log("PlayoutOptions VersionHash: " + JQ(versionHash));

    try {

      console.log("ObjectId: " + obj.id);
      let accessInfo = await Continuum.client.AccessInfo({objectId:obj.id});

      console.log("AccessInfo: " + JQ(accessInfo));
      
      if(isEmpty(accessInfo)){
        console.error("AccessInfo is Empty.");
      }else if(accessInfo.accessCode !== 0 && 
        accessInfo.accessCode !== 100
        ){
          console.error("Access Denied.");
          this.setState({loading:false});
          PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
          return;
      }

      let drms = await Continuum.availableDRMs();
      let protocols = ["hls"];
      if(drms.includes("widevine")){
        protocols.push("dash");
      }

      let playoutOptions = {};
      if(this.isLive(obj)){
        console.log("Live Object.");
          playoutOptions = await Continuum.client.PlayoutOptions({
            objectId: obj.id,
            protocols,
            drms: []
          });
      } else if(Continuum.isLinear(obj)){
        playoutOptions = await Continuum.client.PlayoutOptions({
          objectId: obj.id,
          protocols,
          drms: []
        });

      }else if(this.isSiteAsset(obj)){
        console.log("Title object detected.");
        playoutOptions = await Continuum.client.PlayoutOptions({
          objectId: obj.id,
          protocols,
          drms: []
        });
      }else {
        console.log("Non live");
        playoutOptions = await Continuum.client.PlayoutOptions({
          objectId: obj.id,
          protocols,
          drms: drms
        });
      }
      if(!isEmpty(playoutOptions.hls)){
        if(!isEmpty(playoutOptions.hls.playoutUrl)){
          url = playoutOptions.hls.playoutUrl;
        }if(!isEmpty(playoutOptions.hls.playoutMethods.clear.playoutUrl)){
          url = playoutOptions.hls.playoutMethods.clear.playoutUrl;
        }
      }

      console.log("PlayoutOptions: " + JQ(playoutOptions));
 /*
      let choice = null;
      if(isEmpty(lang)){
        //console.log("Lang is empty! " + this.state.language);
        lang = this.state.language;
      }

     
      console.log("Loading choices...");
      if(!isEmpty(obj.meta.choices) && obj.meta.choices.length > 0){
        if(isEmpty(lang)){
          console.log("No language preference, loading first choice.");
          choice = obj.meta.choices[0];
        }else{
          for(var index in obj.meta.choices){
            let item = obj.meta.choices[index];
            console.log("Loading choice item " + JQ(item));
            if(this.langToIso(item.name) === lang){
              choice = item;
              break;
            }
          }
        }
      }else{
        console.log("No choices.");
      }
      */


/*
      console.log("Loading user tags.");
      try{
        if(isEmpty(this.userTags)){
          this.userTags = await Continuum.formatTagsCollection();
        }
      }catch(e){
        console.error("Could not retrieve user tags.");
        this.userTags = {};
      }
      let params = {"tags":this.userTags};
      console.log("Loaded user tags: " + JQ(this.userTags));

      
      if(!isEmpty(choice)){
        console.log("Loading url from selection list.");
        //We only want from /rep onwards
        let index = choice.dashManifestUrl.lastIndexOf("/rep/");
        if(index >= 0){
          let rep = choice.dashManifestUrl.substr(index + 5);
          console.log("Extracting rep: " + rep);
          this.start("Content rep " + rep);

          url = await Continuum.getContentRep({versionHash:obj.hash, 
            objectId: obj.id, 
            rep:rep,
            params});
          this.end();
        }
      }


      this.start("Content url.");
      if(!isEmpty(obj.meta["eluv.type"]) && obj.meta["eluv.type"] === "live"){
        let rep = "dash/raw/en.mpd";
       
        url = await Continuum.getContentRep({
          versionHash:obj.hash, 
          objectId: obj.id, 
          rep:rep,
          params,
        });
        
        console.log("Playing live content: " + url);
      }else{
        if(isEmpty(url)){
          console.log("Loading url manually.");
          //Regular video version
          if (obj.meta.video !== undefined) {
            
            url = await Continuum.getVideoUrlPart({
              versionHash: obj.hash,
              partHash: obj.meta.video
            });

          } else {
            console.log("Retreiving non live url.");
            let lang = Continuum.appData.getPrefLanguage();
            //imf
            url = await Continuum.getContentObjectVideoUrl({
              versionHash: obj.hash,
              objectId: obj.id,
              lang:lang,
              params
            });
          }
        }
        */
        this.end();

        console.log("Loading url: " + url);

        console.log("AccessRequest obj.hash: " + obj.hash + " obj.id: " + obj.id);
        if(this.state.playing){
          this.playPause();
        }

/*
        this.start("Access Request.");
        requestID = await Continuum.client.AccessRequest({
          objectId: obj.id,
          versionHash: obj.hash,
          noCache: false,
          args: [1, null, "pke requestor", [], []]
        });
        this.end();

        console.log("AccessRequest response: " + JQ(requestID));

*/
      

      //XXX: TEMP:
      // url = "samples/video1.mp4";
      PubSub.publish(PubSub.TOPIC_CURRENT_VIDEO, this.state.obj);

      this.setState({
        adUrl:"",
        obj,
        url,
        requestID,
        loaded: 0,
        pip: false,
        clipMode: false,
        controls: true,
        playingAd: false,
        playingRecording: false,
        playing: true,
        light: false,
        isInit: true,
        language: lang
      });
      PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
    } catch (err) {
      console.error("Loading failed: " + JQ(err));
      PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
      PubSub.publish(PubSub.TOPIC_ERROR, "Error loading content.");
    }
    this.setState({loading:false});
  };

  start(msg) {
    console.log("Start time measure: " + msg);
    this.startTime = new Date();
  };

  end() {
    this.endTime = new Date();
    var timeDiff = this.endTime - this.startTime; //in ms
    console.log("Elasped: " + timeDiff + " ms");
  };

  loadAd = async ({ adObj }) => {
    console.log("Loading ad: " + JQ(adObj));
    PubSub.publish(PubSub.TOPIC_PROGRESS_START);
    try {
      this.start("Ad Access Request.");
      let requestID = await Continuum.accessRequestSponsorInfo(adObj);
      this.end();
      console.log("AccessRequest id: " + JQ(requestID));

      let adVideoMetadata = adObj["ad_metadata"];
      let adImage = await Continuum.client.FabricUrl({
        libraryId: adObj.ad_library,
        objectId: adObj.ad_id,
        partHash: adVideoMetadata.image,
        noAuth: true
      });

      let adUrl = "";
      if (adVideoMetadata.video !== undefined) {
        adUrl = await Continuum.client.FabricUrl({
          libraryId: adObj.ad_library,
          objectId: adObj.ad_id,
          partHash: adVideoMetadata.video,
          noAuth: true
        });
      }else{
        let choice = adVideoMetadata.choices[0];
        let index = choice.dashManifestUrl.lastIndexOf("/rep/");
        if(index >= 0){
          let rep = choice.dashManifestUrl.substr(index + 5);
          console.log("Extracting rep: " + rep);
          this.start("Ad Content rep " + rep);
          adUrl = await Continuum.getContentRep({libraryId:adObj.ad_library, objectId: adObj.ad_id, rep:rep});
          this.end();
        }
        console.log("Ad Url from choice: " + adUrl);

        if(isEmpty(adUrl)){

          let lang = Continuum.appData.getPrefLanguage();
          //imf
          adUrl = await Continuum.getContentObjectVideoUrl({
            libraryId:adObj.ad_library,
            objectId: adObj.ad_id,
            lang:lang
          });

          console.log("Ad Url from lang prefs: " + adUrl);
        }
      }

      let overlayImage = null;
      let overlayLink = null;
      if(!isEmpty(adObj.sponsor_url)){
        overlayLink = adObj.sponsor_url;
        overlayImage = await Continuum.getContentObjectImageUrl({
          libraryId: adObj.ad_library,
          objectId: adObj.ad_id
        });
      }

      this.setState({
        overlayImage,
        overlayLink,
        adObj,
        adUrl,
        adImage,
        requestID,
        playingAd: true,
        playingRecording: false,
        played: 0,
        loaded: 0,
        pip: false,
        clipMode: false,
        controls: true,
        playing: true,
        light: false,
        isInit: true,
        url:""
      });
    } catch (err) {
      console.error("Error loading ad: " + JQ(err));
      PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
      PubSub.publish(PubSub.TOPIC_ERROR, "Error loading ad.");
    }
  };

  playPause = () => {
    this.setState({ playing: !this.state.playing });
  };
  stop = () => {
    this.setState({ url: null, playing: false });
  };
  toggleControls = () => {
    const url = this.state.url;
    this.setState(
      {
        controls: !this.state.controls,
        url: null
      },
      () => this.load(url)
    );
  };
  toggleLight = () => {
    this.setState({ light: !this.state.light });
  };
  toggleLoop = () => {
    this.setState({ loop: !this.state.loop });
  };
  toggleClipMode = () => {
    this.setState({ clipMode: !this.state.clipMode });
  };
  setVolume = e => {
    this.setState({ volume: parseFloat(e.target.value) });
  };
  toggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };
  setPlaybackRate = e => {
    this.setState({ playbackRate: parseFloat(e.target.value) });
  };
  togglePIP = () => {
    this.setState({ pip: !this.state.pip });
  };
  onPlay = () => {
    console.log("onPlay");
    this.setState({ playing: true });
    PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
    if(!isEmpty(this.state.played)){
      //FIXME: This seeks into the content on start after an ad.
      //this.video.seekTo(this.state.played);
    }
  };
  onEnablePIP = () => {
    console.log("onEnablePIP");
    this.setState({ pip: true });
  };
  onDisablePIP = () => {
    console.log("onDisablePIP");
    this.setState({ pip: false });
  };
  onPause = () => {
    console.log("onPause");
    this.setState({ playing: false });
  };

  onSeekChanged = (value) => {
    this.setState({
      seekValue:value,
      seeking:true
    });
  };

  onSeekMouseUp = value => {
    this.setState({seekValue:null, seeking: false });
    this.video.seekTo(parseFloat(value));
  };

  handleClickFullscreen = () => {
    if(!this.state.fullscreen){
      screenfull.request(findDOMNode(this.videoContainer.current));
      this.setState({fullscreen:true});
    }else{
      screenfull.exit();
      this.setState({fullscreen:false});
    }
  }

  openDvr = async (stream) => {
    let {elvSchedule,recordingsList,recordable} = this.state;
    if(!elvSchedule){
      console.error("No Schedule found.");
      return;
    }
    let time = Math.floor(elvSchedule.now() + this.state.playedSeconds);
    console.log(`played seconds ${time}`);
    let program = await elvSchedule.getProgramByTime(time);
    // console.log(`program: \n${JQ(program)}`);
    let recordings = recordingsList;
    let data = {
      obj:stream,
      program,
      elvSchedule,
      recordings,
      recordable
    };
    // console.log("handleDvrClick: " + JQ(data));
    PubSub.publish(PubSub.TOPIC_OPEN_DVR, data);
  }

  handleDvrClick = async () => {
    /*
    if(this.isRecording()){
      await this.initStreams();
      let stream = await this.getStreamFromRecording(this.state.obj);
       if(stream){
        await this.openDvr(stream);
     }
    }
    if(this.isLive()){
      await this.openDvr(this.state.obj);
    }
    */
    this.initStreams();
    await this.openDvr(this.state.obj);
  }

  handleLiveClick = async () => {
    /*
    if(this.video && this.isRecording() && this.state.obj){
      let stream = await this.getStreamFromRecording(this.state.obj);
      if(stream){
        this.state.obj = stream;
        this.load({obj:stream});
      }
    }

    if(this.video && this.isLive() && this.state.obj){
        this.load({obj:this.state.obj});
    }
    */

   this.load({obj:this.state.obj});
  }

  getStreamFromRecording = async (recording) => {
    if(recording.meta.stream_library_id && 
      recording.meta.stream_id){
        try{
          let libraryId = recording.meta.stream_library_id;
          let objectId = recording.meta.stream_id;
          let liveStream = await Continuum.contentData.get({libraryId, objectId, validate:false});
          if(liveStream){
            return liveStream;
          }
        }catch(e){
          console.error("Error getting live stream from recording: " + JQ(e));
        }
      }
      return null;
  }

  onProgress = async state => {
    // console.log("onProgress", state);
    // We only want to update time slider if we are not currently seeking
    try{
      if (!this.state.seeking) {
        let obj = this.state.obj;
        if(!this.state.playingAd ){
          if(!isEmpty(obj.meta.video_tags)){
            let tags = this.parseVideoTags(obj.meta.video_tags, state.playedSeconds);
            state.tagsString = tags;
          }

          try{
            this.callAccess(state.playedSeconds);
          }catch(err){
            console.error("Call Access Error: " +err);
          }

          if(this.state.showBounding){
            this.drawBounding(state.playedSeconds);
          }
        }
        this.setState(state);
      }
    }catch(err){
      console.error(err);
    }
  };

  callAccess = async (currentTime) =>{
    if(!isEmpty(this.schedule) && isEmpty(this.accessLock)){
      this.accessLock = true;
      try{
        console.log("Checking access calls...");
        //TODO: factor in publish_time once we are using live/dynamic type manifest
        let element = this.findScheduledElement(currentTime);
        console.log(`Found element for time ${currentTime}: ` + JQ(element.id));

        if(this.currentElement === null){
          console.log("Current element has not been set.");
          this.currentElement = element;
        }
        
        if(this.currentElement !== element && !(this.currentElement.objectId in this.accessCompletes)){
          let access = this.accessRequests[this.currentElement.objectId];
          let sponsor_info = access.sponsor_info;
          let completeResp = true;
          //We've switched to a new content, call access complete on old.
          if(!isEmpty(access) && !isEmpty(access.objectId)){
            if(isEmpty(sponsor_info)){
              completeResp = await Continuum.accessComplete2(this.currentElement.objectId)
              .then(result => {
                console.log("ACCESSCOMPLETE: " + JQ(result));
              })
              .catch(err => {
                console.error("AccessComplete: " + JQ(err));
              });
            }else{
              //Use the wait version just in case theres and error and we brick up the account with too many accesscomplete calls.
              completeResp= await Continuum.accessComplete(sponsor_info.ad_id, sponsor_info.requestID,true)
              .then(result => {
                console.log("AD ACCESSCOMPLETE: " + JQ(result));
              })
              .catch(err => {
                console.error("ACCESSCOMPLETE: " + JQ(err));
              });
            }
          }else{
            console.error("Access info was not saved correctly, could not call AccessComplete.");
          }

          this.accessCompletes[this.currentElement.objectId] = completeResp;
        }

        if(!(element.objectId in this.accessRequests)){
          this.currentElement = element;
          if(element.type === "ads" || element.type === "dynamic"){
            console.log(`Found ${element.type} content.`);
            let refId = "";
            if(!isEmpty(element.use_element_tags)){
              try{
                console.log("use_element_tags = true. Getting reference element.")
                refId = this.schedule.elements[element.use_element_tags[0]].objectId;
                console.log("Reference element: " + refId);
              }catch(err){
                console.log("Could not get objectId of reference content for user_element_tags");
              }
            }
            console.log("Formatting matched_info to sponsor_info." /*+ JQ(element.matching_info)*/);
            let sponsor_info = await Continuum.formatAdManagerResponse(element.matching_info,refId);
            if(!isEmpty(sponsor_info)){
              console.log("sponsor_info " + JQ(sponsor_info));
              this.start(`Access Request ${element.type}.`);
              let requestID = await Continuum.accessRequestSponsorInfo(sponsor_info);
              this.end();
              sponsor_info.requestID = requestID
              let access = {requestID,objectId:sponsor_info.ad_id, sponsor_info};
              console.log("Saving access request value: " + JQ(access));
              this.accessRequests[element.objectId] = access;
            }else{
              console.error("Could not format matching_info into sponsor_info for AccessRequest. aborting call...");
              let access = {requestID:"",objectId:element.objectId};
              this.accessRequests[element.objectId] = access;
            }
          }else{
            //regular content accessRequest
            this.start("Access Request Static Content: " + element.versionHash);
            let requestID = await Continuum.client.AccessRequest({
              objectId: element.objectId,
              versionHash: element.versionHash,
              noCache: false,
              args: [1, null, "pke requestor", [], []]
            });
            this.end();
            let access = {requestID,objectId:element.objectId};
            this.accessRequests[element.objectId] = access;
          }
        }
      }catch(error){
        console.error(JQ(error));
      }finally{
        console.log("Finished checking access for time: " + currentTime);
        this.accessLock = null;
      }
    }
  }

  //TODO: factor in publish_time once we are using live/dynamic type manifest
  findScheduledElement(currentTime){
    if(isEmpty(this.schedule)){
      return null;
    }

    for(let index in this.schedule.elements){
      let item = this.schedule.elements[index];
      if(!isEmpty(item) && !isEmpty(item.schedule_start_time) && !isEmpty(item.duration_sec)){
        let sst = toSeconds(parse(item.schedule_start_time));
        if(currentTime >= sst && currentTime < sst + item.duration_sec){
          return item;
        }
      }
      
    }
    return null;
  }

  parseVideoTags(videoTags, currentTimeSeconds){
    var startMS = parseFloat(currentTimeSeconds) * 1000;

    var sorted = this.sortVideoTags(videoTags, 10, 0.15,
      startMS, startMS);

    let tagsString ="";
    for(var i=0;i<sorted.length;i++){
        var obj = sorted[i];
        tagsString += obj.tag +  ", ";
    }

    tagsString = tagsString.slice(0,-2);
    return tagsString;
  }

  sortVideoTags(tagsArray, maxNum, minScore, startMS, endMS){
    var rangeOnly = true;
    if(startMS == null || endMS == null){
        rangeOnly = false;
    }

    var keyWords = [];
    var keyMap = {};
    for(var i=0;i<tagsArray.length;i++){
        var tagObj = tagsArray[i];
        var tags = tagObj["tags"];
        if(rangeOnly){
            var timeIn = this.timeToMS(tagObj.time_in);
            var timeOut = this.timeToMS(tagObj.time_out);

            if(timeIn !== null && timeOut !== null){
                //2 cases for not intersecting... left and right of timerange
                if((startMS < timeIn && endMS < timeIn) ||
                   (startMS > timeOut && endMS >timeOut)) {
                        continue;
                }
            }else{
              continue;
            }
        }

        for(var j=0;j<tags.length;j++){
            var obj = tags[j];
            var tag = obj["tag"];
            var score = parseFloat(obj["score"]);

            if(score && score >= minScore){
                if(!(tag.toLowerCase() in keyMap)){
                    keyWords.push({"tag":tag.toLowerCase(), "score":score});
                    keyMap[tag.toLowerCase()]=1;
                }
            }
        }
    }

    var sorted = keyWords.sort(function(a,b){return b.score - a.score;});
    return sorted.slice(0,maxNum);
}

//Time is in the format HH:MM:SS.MS
//Returns milliseconds
timeToMS(timeStamp){
  var segs = timeStamp.split(":");
  if(segs.length < 3){
      return null;
  }

  var secs = segs[2].split(".");
  var ms = 0;
  if(secs.length > 2){
      return null;
  }

  ms = secs[1];

  var h = parseInt(segs[0],10) * 3600000;
  var m = parseInt(segs[1],10) * 60000;
  var s = parseInt(segs[2],10) * 1000;

  var time = h + m + s + parseInt(ms,10);
  return time;
}

accessComplete = async () =>{
  if(!this.state.obj){
    return;
  }
  let obj = this.state.obj;
  if(this.state.playingRecording && this.state.recordingObj){
    obj = this.state.recordingObj;
    console.log("AccessComplete Recording: " + JQ(obj));
  }else{
    console.log("AccessComplete: " + JQ(obj));
  }

  let percentComplete = 0;
  if(this.state.duration && this.state.duration > 0){
    percentComplete = Math.round((this.state.playedSeconds / this.state.duration) * 100);
  }
  console.log("FinalizeStateChannelAccess " + obj.id);
  await Continuum.client.FinalizeStateChannelAccess({objectId:obj.id, 
    percentComplete});

}

  onEnded = async () => {
    console.log("onEnded");
    try {
      if (this.state.playingAd) {
        this.setState({playinAd:false, playing:false, adUrl:""});
        /*
        Continuum.accessComplete(this.state.adObj.ad_id, this.state.requestID)
          .then(result => {
            console.log("AD ACCESSCOMPLETE: " + JQ(result));
          })
          .catch(err => {
            console.error("ACCESSCOMPLETE: " + JQ(err));
        });
        */

        Continuum.client.FinalizeStateChannelAccess({objectId:this.state.adObj.ad_id, percentComplete:100});
        this.load({ obj: this.state.obj, url: this.state.url });
      } else {
        /*
        Continuum.accessComplete2(this.state.obj.id)
          .then(result => {
            console.log("ACCESSCOMPLETE: " + JQ(result));
          })
          .catch(err => {
            console.error("AccessComplete: " + JQ(err));
          });
          */
        // this.setState({ playing: this.state.loop });
        this.accessComplete();
      }
    } catch (err) {
      console.error("Error in onEnded: " + JQ(err));
      PubSub.publish(PubSub.TOPIC_ERROR, "Error calling access complete.");
    }
  };
  onDuration = async duration => {
    console.log("onDuration", duration);
    this.setState({duration})
  };

  onVerificationClicked = verification => {
    console.log("onVerificationClicked - " + JQ(verification));
    if (!isEmpty(verification)) {
      PubSub.publish(PubSub.TOPIC_OPEN_VERIFICATION, verification);
    }
  };

  onInfoClicked = obj => {
    console.log("onInfoClicked - " + JQ(obj));
    if (!isEmpty(obj)) {
      PubSub.publish(PubSub.TOPIC_OPEN_JSONVIEW, obj);
    }
  };

  onSliderChanged = async e => {
    console.log("onSliderChanged ");
    if (!isEmpty(this.video)) {
      console.log("onSliderChanged " + e.target.value);
    }
  };

  ref = video => {
    this.video = video;
  };

  handleLangChange = (e) => {
    let lang = e.target.value;
    console.log("Choice selection: " + JQ(lang));

    if(!this.state.playingAd){
      this.setState({ language:lang});
      this.load({obj:this.state.obj, lang:lang});
    }
  }

  intersect(a, b) {
    var setA = new Set(a);
    var setB = new Set(b);
    var intersection = new Set([...setA].filter(x => setB.has(x)));
    return Array.from(intersection);
  }

  searchTags = async (keywords, tags) => {
    if(isEmpty(tags) || isEmpty(keywords))
      return;
    let searchedResults = {};
    let keys = Object.keys(tags);
    for(var index in keys){
      var key = keys[index];
      var obj = tags[key];
      var lowerKeys = key.toLowerCase();
      var a = lowerKeys.split("_");
      var b = keywords.split(" ");
      var inter = this.intersect(a,b);
      if(inter.length > 0){
        console.log("search intersection: " + JQ(inter));
        searchedResults[key] = obj;
      }
    }
    console.log("search results: " + JQ(searchedResults));
    this.setState({searchedResults,showTagSearch:true});
  }

  formatSecToTime (sec_num) {
    try{
      var hours   = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      var seconds = sec_num - (hours * 3600) - (minutes * 60);

      if (hours   < 10) {hours   = "0"+hours;}
      if (minutes < 10) {minutes = "0"+minutes;}
      if (seconds < 10) {seconds = "0"+seconds;}
      return hours+':'+minutes+':'+seconds.toFixed(1);
    }catch(error){
      return ""
    }
  }

  RenderSeekBar(props){
    const {loadedSeconds, playing, seeking, seekValue, playedSeconds, duration, classes} = props;

    // console.log("Seekbar: " + playedSeconds + " / " + duration);
    return (
          <Slider
            min={0}
            max={duration || 0}
            value={seeking? seekValue : playedSeconds || 0}
            tipFormatter={value => `${value}s`}
            trackStyle={[{ backgroundColor: "white" }]}
            handleStyle={[
              { backgroundColor: "white", border: "solid 1px white" }
            ]}
            railStyle={{ backgroundColor: "grey" }}
            activeDotStyle={{
              borderColor: "white",
              backgroundColor: "white"
            }}
            width="100%"
            onChange = {this.onSeekChanged}
            onAfterChange = {this.onSeekMouseUp}
            className={classes.seekbar}
        />
    );
  }

  RenderDVRControl(props){
    const {recording, playing, recordable, classes} = props;
    return (
    <div className={classes.row} >
      {      
      recordable ?
      <IconButton
            aria-label="Fullscreen"
            onClick={this.handleDvrClick}
            className={classes.videoControlButton}
          >
            <DVRIcon className={recording ? classes.dvrOn : classes.dvrNormal} />
      </IconButton> : "" 
      }
    </div> 
    );
  }

  handleVolumeChange = (event, newValue) => {
    this.setState({volume:newValue})
  }

  RenderVolumeControl(props){
    const {muted, playing, volume, classes} = props;

    return (
      <div className={classes.volume_container} >
      {/* This empty IconButton is needed for some reason or else the VolumeSlider is cut off*/}
        <IconButton >
        </IconButton>
        <VolumeSlider 
              disabled={muted}
              value={volume} 
              onChange={this.handleVolumeChange} 
              className={classes.volumeSlider}
              aria-labelledby="continuous-slider" />
        <IconButton
              aria-label="Volume"
              onClick={this.toggleMuted}
              className={classes.videoControlButton}
            >
            {muted ?
              <VolumeMuteIcon className={classes.videoControlMUI} /> :
              <VolumeUpIcon className={classes.videoControlMUI} />
            }
        </IconButton>
      </div>
    );
  }

  RenderAdvancedControls(props){
    const {loadedSeconds, playedSeconds, muted, seekValue, played, seeking, playing, volume, loaded, playingAd, duration,classes,showBounding, rangeStart, rangeEnd, onSliderChange} = props;

    return (
      <div className={classes.range}>
        <Range
          min={0}
          max={Math.max(playedSeconds,duration)}
          defaultValue={[0, duration]}
          tipFormatter={value => `${value}s`}
          trackStyle={[{ backgroundColor: "white" }]}
          handleStyle={[
            { backgroundColor: "white", border: "solid 1px white" }
          ]}
          railStyle={{ backgroundColor: "grey" }}
          activeDotStyle={{
            borderColor: "white",
            backgroundColor: "white"
          }}
          width="100%"
          onChange = {onSliderChange}
        />
        <div className={classes.advanced_controls}>
          <Button
            variant="contained"
            size="small"
            aria-label="Bounding"
            className={classes.advanced_control_button}
            onClick={async e => {
              console.log("Bounding Box clicked: " + showBounding);
              this.setState({showBounding:!showBounding});
              this.clearAnnotationLayer();
            }}
          >
            {showBounding ? <BBoxCheckedIcon className={classes.ControlIcon}/> : <BBoxIcon className={classes.ControlIcon} />}
            Bounding Box
          </Button>

          <Button
            variant="contained"
            size="small"
            aria-label="Save"
            className={classes.advanced_control_button}
            onClick={async e => {

              try{
                let downloadUrl = await Continuum.getContentObjectDownloadUrl({
                  versionHash: this.state.obj.hash,
                  objectId: this.state.obj.id,
                  start: rangeStart,
                  end: rangeEnd
                });
                window.open(downloadUrl);
              }catch(e){
                console.error("Error donwloading video: " + JQ(e));
              }
            }}
          >
            <SaveIcon className={classes.ControlIcon} />
            Save
          </Button>
        </div>
      </div>
    );
  }

  RenderControls(props){
    const {visible, fullscreen, loadedSeconds, playedSeconds, muted, seekValue, recording, recordable, played, seeking, playing, volume, loaded, playingAd, duration,classes,showBounding, rangeStart, rangeEnd, onSliderChange} = props;

    return (
      <Fade in={visible}>
      <div className={classes.video_controls_container} >
        <div className={classes.video_controls_row} >
          <div className={classes.controls_left} >
            <IconButton
              aria-label="Fullscreen"
              onClick={this.playPause}
              className={classes.videoControlButton}
            >
            {
              playing ?
              <PlayerIcon.Pause className={classes.videoControl} /> :
              <PlayerIcon.Play className={classes.videoControl} />
            }
            </IconButton>
            {!this.isLive()?
            <div className={classes.videoTime}>
              <FormattedTime numSeconds={playedSeconds} /> 
              {
                duration && playedSeconds > 0 ?<>
              / <FormattedTime numSeconds={Math.max(playedSeconds,duration)} />
              </>
               : ""
              }
            </div> :
            <this.RenderDVRControl recordable={recordable} recording={recording} playing={playing} classes={classes} />
            }
          </div>
          <div className={classes.controls_right} >
              <this.RenderVolumeControl muted={muted} volume={volume} playing={playing} classes={classes} />
              <IconButton
                aria-label="Fullscreen"
                onClick={this.handleClickFullscreen}
                className={classes.videoControlButton}
              >
                {fullscreen ? <FullscreenExit className={classes.videoControlMUI} /> :
                <Fullscreen className={classes.videoControlMUI} /> }
              </IconButton>
          </div>
          {!this.isLive()?
          <this.RenderSeekBar 
              loadedSeconds={loadedSeconds} 
              playedSeconds={playedSeconds}
              duration={duration}
              onSeekChanged={this.onSeekChanged}
              onSeekMouseUp={this.onSeekMouseUp}
              seekValue = {seekValue}
              seeking = {seeking}
              playing={playing} classes={classes}
              /> : ""
          }
        </div>
      </div>
      </Fade>
    );
  }

  RenderSearchResults(props){
    const {searchedResults, showTagSearch, classes} = props;
    if(isEmpty(showTagSearch) || isEmpty(searchedResults)){
      return "";
    }

    return(
    <div>
      <Typography variant="subtitle1" color="textSecondary" className={classes.tags}>
          <b>Search Results: </b>
      </Typography> 
        <div className={classes.tagSearch}>
        {
          Object.keys(searchedResults).map((key) => {
            var obj = searchedResults[key];
            if(isEmpty(obj)) return "";
            return(
                <div className={classes.row}>
                <div>
                <Typography variant="subtitle1" color="textSecondary" className={classes.time_links}>{key}:</Typography>
                </div>
                {obj.map((range,index) => {
                  if(isEmpty(range)) return "";
                  let time = this.formatSecToTime(range.start);
                  if(isEmpty(time))
                  return ""
                  return (
                    <Link
                      className={classes.time_links}
                      component="button"
                      variant="subtitle1"
                      onClick={() => {
                        this.video.seekTo(range.start)
                      }}
                      
                    >
                      {time}
                    </Link>
                  );
                })}
                <br/>
                </div>
            );
        })}
        </div>
      </div>
    );
  }

  render() {
    let { obj, clipMode, tagsString, showBounding, searchedResults, controlsVisible} = this.state;
    const {
      url,
      playing,
      controls,
      light,
      volume,
      muted,
      loop,
      played,
      loaded,
      duration,
      playbackRate,
      playingAd,
      adUrl,
      pip,
      isInit,
      rangeStart,
      rangeEnd,
      playedSeconds,
      loadedSeconds,
      //downloadUrl,
      progressInterval,
      overlayImage,
      overlayLink,
      verification,
      showTagSearch,
      seeking,
      seekValue,
      recording,
      playingRecording,
      recordingObj,
      recordingUrl,
      fullscreen,
      schedule,
      recordable,
      elvSchedule,
      streamRunning,
      program
    } = this.state;

    let startTime = null;
    let endTime = null;


    if(isEmpty(obj)){
      // this.props.history.push('/');
      return "";
    }

    if(!isEmpty(program)){
      startTime = program.startTime;
      endTime = program.endTime;
    }

    let currentUrl = playingAd ? adUrl : playingRecording? recordingUrl : url;
    if(isEmpty(currentUrl)){
      currentUrl = "";
    }

    let title = obj.meta.name;
    let description = obj.meta.description;
    if(this.isSiteAsset(obj)){
      title = obj.meta.asset_metadata.title;
      description = obj.meta.asset_metadata.synopsis;
    }

    if(playingRecording && 
      !isEmpty(recordingObj)){
      title = recordingObj.meta.program_title ? recordingObj.meta.program_title: recordingObj.meta.name;
      description = recordingObj.meta.program_description;
      startTime = recordingObj.startTime;
      endTime = recordingObj.endTime;
    }

    if(program){
      title = program.title;
      description = program.description;
    }

    // console.log("Playing url: " + currentUrl);
    const { classes } = this.props;

    let verifiedClass = classes.unverified;
    if (!isEmpty(verification)) {
      if (verification.valid) {
        verifiedClass = classes.verified;
      } else {
        verifiedClass = classes.unverified;
      }
    }

    let choices = [];
    if(!isEmpty(obj)){
      if(isEmpty(choices)){
        choices = [];
      }else{
        choices = obj.meta.choices;
      }
    }else{
      this.props.history.push('/');
      return "";
    }

    return (
      <div className={classNames(classes.player_root,classes.padding)}>
        <Card className={classes.videoCard}>
          <div ref={this.videoContainer} className={classes.videoContainer}>
            {!isInit ? (
              <div className={classes.video1} >
                {
                  !this.state.loading ? 
                  <Typography align='center' className={classes.videoMessage}>{this.isLive() ? "Stream unavailable..." : "Video unavailable"} </Typography>
                : <Typography align='center' className={classes.videoMessage}>{"Loading..."} </Typography>
              }
              </div>
            ) : (
              <div className={classes.video_container} 
                    onMouseEnter={()=>{this.setState({controlsVisible:true})}}
                    onMouseMove={()=>{this.setState({controlsVisible:true})}}
                    onTouchMove={()=>{this.setState({controlsVisible:true})}}
                    onMouseLeave={()=>{this.setState({controlsVisible:false})}}
                    >
              <this.RenderControls 
                duration={duration} 
                classes={classes} 
                showBounding={showBounding} 
                rangeStart={rangeStart} 
                rangeEnd={rangeEnd}
                played={played}
                playing={playing}
                playingAd={playingAd}
                loadedSeconds={loadedSeconds}
                playedSeconds={playedSeconds}
                loaded={loaded}
                volume={volume}
                muted={muted}
                seeking={seeking}
                seekValue = {seekValue}
                recording = {recording}
                onSliderChange={this.onSliderChange}
                visible={controlsVisible}
                recordable={recordable}
                fullscreen={fullscreen}
              />
              <canvas className={classes.annotation_layer} id="annotation" width="1280" height="720" ref={this.annotation}></canvas>
              <ReactPlayer
                ref={this.ref}
                width="100%"
                height="100%"
                url={currentUrl}
                className={classes.video}
                pip={pip}
                playing={playing}
                controls={false}
                light={light}
                loop={loop}
                playbackRate={playbackRate}
                volume={volume / 100.0}
                muted={muted}
                onReady={() => console.log("onReady")}
                onStart={() => console.log("onStart")}
                onPlay={this.onPlay}
                onEnablePIP={this.onEnablePIP}
                onDisablePIP={this.onDisablePIP}
                onPause={this.onPause}
                onBuffer={() => console.log("onBuffer")}
                onSeek={e => console.log("onSeek", e)}
                onEnded={this.onEnded}
                onError={e => console.log("onError", e)}
                onProgress={this.onProgress}
                onDuration={this.onDuration}
                progressInterval={progressInterval}
		            config={{
                  file:{
                    dashVersion:"2.9.3",
                    hlsOptions:{
                      // initialLiveManifestSize: 3,
                      nudgeOffset: 0.2,
                      nudgeMaxRetry: 30,
                      // startFragPrefetch:true
                    },
                    hlsVersion:"0.12.4"
                  },

                }}
              />
              {
              !isEmpty(overlayLink)?
              <div className={classes.ad_container}>

                <div className={classes.grow} />
                <div className={classes.ad_layer}>
                <a href={overlayLink} target="_blank"  rel="noopener noreferrer">
                <img className={classes.ad_img} src={overlayImage} alt=""/> 
                </a> </div></div>:""
              }
              </div>
            )}
          </div>
        </Card>
          <div className={classes.controls}>
            <Typography
              noWrap
              gutterBottom
              variant="h5"
              color="primary"
              className={classes.playerTitle}
            >
              {title}
            </Typography>
              {/*<IconButton aria-label="Add to favorites">
                <FavoriteIcon />
              </IconButton>
              <IconButton aria-label="Share">
                <ShareIcon />
              </IconButton> */}
              <FormControl className={classes.formControl} disabled={playingAd || !isInit}>
              {!choices || choices.length === 0 ? "" : <Select
                value={this.state.language}
                onChange={this.handleLangChange}
                input={<Input name="language" id="language-select" />}
                name="lang"
                className={classes.selectEmpty}
              >
              { choices.map((choice) => (
                <MenuItem key={choice.name} value={this.langToIso(choice.name)}>{choice.name}</MenuItem>
              ))}
              </Select> }
            </FormControl>
            { playingRecording ?
              <IconButton
                aria-label="Info"
                color="primary"
                disabled={playingAd || !isInit || !(this.isLive() || this.isRecording())}
                onClick={e => {
                  this.handleLiveClick();
                }}
              >
                <svg className={classes.titleButton} height="597pt" viewBox="-18 -128 597.33331 597" width="597pt" xmlns="http://www.w3.org/2000/svg"><path d="m560 22.832031c0-16.566406-13.433594-30-30-30h-500c-16.566406 0-30 13.433594-30 30v280c0 16.570313 13.433594 30 30 30h500c16.566406 0 30-13.429687 30-30zm-410 230h-80c-5.523438 0-10-4.476562-10-10v-160c0-5.519531 4.476562-10 10-10s10 4.480469 10 10v150h70c5.523438 0 10 4.480469 10 10 0 5.523438-4.476562 10-10 10zm60-10c0 5.523438-4.476562 10-10 10s-10-4.476562-10-10v-160c0-5.519531 4.476562-10 10-10s10 4.480469 10 10zm159.371094-156.488281-60 160c-1.464844 3.90625-5.199219 6.496094-9.371094 6.488281h-.277344c-4.269531-.117187-7.996094-2.921875-9.273437-7l-50-160c-1.65625-5.273437 1.273437-10.894531 6.550781-12.550781 5.273438-1.652344 10.894531 1.277344 12.550781 6.554688l41.308594 132.207031 49.769531-132.738281c1.933594-5.175782 7.695313-7.804688 12.871094-5.871094s7.804688 7.695312 5.871094 12.871094zm100.628906 66.488281c5.523438 0 10 4.480469 10 10 0 5.523438-4.476562 10-10 10h-50v60h70c5.523438 0 10 4.480469 10 10 0 5.523438-4.476562 10-10 10h-80c-5.523438 0-10-4.476562-10-10v-160c0-5.519531 4.476562-10 10-10h80c5.523438 0 10 4.480469 10 10 0 5.523438-4.476562 10-10 10h-70v60zm0 0"/></svg>
              </IconButton> : ""

            }
              <IconButton
                aria-label="DVR"
                className={recording ? classes.scheduleRecording : classes.scheduleNormal}
                disabled={playingAd || !isInit || !(this.isLive() || this.isRecording())}
                onClick={e => {
                  this.handleDvrClick(obj);
                }}
              >
                <ScheduleIcon />
              </IconButton>

              <IconButton disabled={playingAd || !isInit} aria-label="Clip" onClick={this.toggleClipMode}>
                <ClipIcon />
              </IconButton>
              <IconButton
                aria-label="verification"
                className={classNames(verifiedClass)}
                disabled={isEmpty(verification) || playingAd || !isInit}
                onClick={e => {
                  this.onVerificationClicked(verification);
                }}
              >
                <VerifyIcon />
              </IconButton>
              <IconButton
                aria-label="Info"
                color="primary"
                disabled={playingAd || !isInit}
                onClick={e => {
                  this.onInfoClicked(playingRecording? recordingObj: obj);
                }}
              >
                <InfoIcon />
              </IconButton>
          </div> 
          { 
            !isEmpty(endTime) && !isEmpty(startTime)?
            <div className={classes.datetime_container}>
              <DateTimePicker
                  disableToolbar
                  label="Start Date"
                  value={startTime}
                  onError={console.log}
                  readOnly
                  format={localeDateTime()}
                  className={classes.datetime}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  locale={getUserLocale()}
                />

                <DateTimePicker
                  disableToolbar
                  label="End Date"
                  value={endTime}
                  onError={console.log}
                  readOnly
                  format={localeDateTime()}
                  className={classes.datetime}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  locale={getUserLocale()}
                />
            </div>
            
            : ""
          }
          { 
            !isEmpty(tagsString) ?
            <Typography variant="subtitle1" color="textSecondary" className={classes.tags}>
                <b>Tags: </b> {tagsString}
            </Typography> : ""
          }
          {
              this.RenderSearchResults({classes,searchedResults, showTagSearch})
          }
          <Typography variant="subtitle1" color="textSecondary" className={classes.description}>
            {description}
          </Typography>

          <Guide streamRunning={streamRunning} elvSchedule={elvSchedule} recordable={recordable} recordings={this.state.recordingsList} obj={this.state.obj} schedule={schedule} />
      </div>
    );
  }
}

PlayerPage.propTypes = {
  obj: PropTypes.object.isRequired
};

export default withStyles(styles)(PlayerPage);
