import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter, Route, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import HomePage from '../homepage/homechannels'
import HomeSites from '../homesites'
import Site from '../site'
import WalletPage from '../wallet'
import SettingsPage from '../settings'
import PreferencesPage from '../preferences'
import PlayerPage from '../playerpage'
import ChannelPage from '../channelpage'
import SearchPage from '../searchpage'
import PublisherPage from '../publisherpage'
import MyRecordings from '../myrecordings'
import classNames from 'classnames';
import PubSub from '../../pubsub'
import NotFound from '../App/notfound'
import WelcomePage from '../App/welcome'
import MyVideos from '../myvideos'
import MySites from '../sites'
import Continuum from '../../clients/continuum';
import {JQ} from "../../utils/helpers"
const drawerWidth = 210;

const styles = theme => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  content: {
    display: "block",
    height: "100%",
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 60,
    marginRight: 0,
    marginTop: theme.spacing.unit*8,
  },
  contentShift: {
    height: "100%",
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
    marginRight: 0,
  },
  layout: {
    width: '100%',
    marginLeft: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 5,
  },
  listItem: {
    width: '100%',
    marginLeft: theme.spacing.unit * 2,
    //marginTop: theme.spacing.unit,
    //marginBottom: theme.spacing.unit * 2,
    overflow: "auto",
  },
  secondaryText: {
    color: theme.palette.text.secondary
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    backgroundColor: theme.palette.background.paper,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  media: {
    // ⚠️ object-fit is not supported by IE 11.
    objectFit: 'cover',
  },
  divider: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  title: {
    flexGrow: 1,
    padding: theme.spacing.unit*3,
    //color: "black",
  },
  subheading: {
    flexGrow: 1,
    marginTop: theme.spacing.unit,
    color: "grey",
  },
  updatedTime: {
    position: "fixed",
    bottom:0,
    right:0,
    // marginBottom: theme.spacing(2),
    marginRight: theme.spacing(4),
    fontSize:".5em",
    color:"lightgray"
  }
});

class Content extends Component  {
  state = {
    open: false,
    login: false,
    searchData:""
  };

  constructor(props) {
    super(props);
    //console.log("Content constructor() " + JSON.stringify(props));

    PubSub.subscribe(this,PubSub.TOPIC_VIEW_LIST_TOGGLE,(msg,data)=>{
      this.setState({open: !this.state.open});
    });

    /*
    PubSub.subscribe(this,PubSub.TOPIC_OPEN_SIGNIN,(msg,data)=>{
      this.setState({login:true});
    });

    PubSub.subscribe(this,PubSub.TOPIC_CURRENT_USER,(msg,data)=>{
      console.log("NEW USER: " + JQ(data));
      this.setState({login:false});
    });
    */

    PubSub.subscribe(this,PubSub.TOPIC_OPEN_SEARCH,async (msg,data)=>{
      console.log("CONTENT RECEIVED SEARCH KEYWORDS: " + JQ(data));
      PubSub.publish(PubSub.TOPIC_PROGRESS_START);
      try{
        let path = this.props.location.pathname;
        console.log("Current route: " + path);
        if(path === "/" || path === "/searchpage"){
          let results = await Continuum.videoSearch(data, 10);
          if(this.props.history !== undefined){
            if(this.props.history.location.pathname !== '/searchpage'){
                this.props.history.push({pathname:'/searchpage'});
            }
          }else{
              console.error("History object is undefined in searchpage");
          }
          this.setState({searchData:results});
        }
      }catch(err){
        console.error("Search error: " + JQ(err));
      }finally {
        PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
      }
    });

  }

  render() {
    const { classes, appState } = this.props;
    const {open, login, searchData} = this.state;
    appState.openSidebar = open;
    //console.log("Content render() " + JSON.stringify(open));
    if(login){
      //return <SignInDialog open={true} appState={appState}/>;
    }

    let currentAccount = Continuum.getCurrentAccount();
    if(!currentAccount){
      return "";
    }

    appState.searchData = searchData;

    return (
      <main
        className={classNames(classes.content,{ 
          [classes.contentShift]: open, 
        })}
      >
        <Switch>
          <Route exact path="/" render={(props) => <HomePage {...props} classes={classes} appState={appState}/> } />
          <Route exact path="/sites" render={(props) => <HomeSites {...props} classes={classes} appState={appState}/> } />
          <Route path='/wallet' render={(props) => <WalletPage {...props} classes={classes} appState={appState}/> } />
          <Route path='/site' render={(props) => <Site {...props} classes={classes} appState={appState}/> } />
          <Route path='/preferences' render={(props) => <PreferencesPage {...props} classes={classes} appState={appState}/> } />
          <Route path='/settings' render={(props) => <SettingsPage {...props} classes={classes} appState={appState}/> } />
          <Route path='/playerpage' render={(props) => <PlayerPage {...props} classes={classes} appState={appState}/> } />
          <Route path='/channelpage' render={(props) => <ChannelPage {...props} classes={classes} appState={appState}/> } />
          <Route path='/recordings' render={(props) => <MyRecordings {...props} classes={classes} appState={appState}/> } />  
          <Route path='/publisherpage' render={(props) => <PublisherPage {...props} classes={classes} appState={appState}/> } />
          <Route path='/searchpage' render={(props) => <SearchPage {...props} classes={classes} appState={appState} videos={searchData}/> } />          
          <Route path='/mypage' render={(props) => <MyVideos {...props} classes={classes} appState={appState}/> } />
          <Route path='/mysites' render={(props) => <MySites {...props} classes={classes} appState={appState}/> } />
          <Route path='/welcome' component={WelcomePage} />
          <Route component={NotFound} />
        </Switch>
        <div className={classes.updatedTime}>12/20/2019 3:40PM PST</div>        
      </main>
    );
  }
}

Content.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles, { withTheme: true })(Content));