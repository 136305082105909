import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import TitleIcon from "@material-ui/icons/Schedule";
import ScheduleIcon from "@material-ui/icons/Schedule";
import RecordIcon from "@material-ui/icons/PlaylistPlay";
import DeleteIcon from "@material-ui/icons/Clear";
import Tabs from '@material-ui/core/Tabs';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Tab from '@material-ui/core/Tab';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import Grid from '@material-ui/core/Grid';
import {ElvAVStream} from "../../clients/elv-avstream-module"
import Continuum from "../../clients/continuum";
import PubSub from "../../pubsub";
import "./dialogs.css";
import { JQ, isEmpty, localeDateTime } from "../../utils/helpers";
import { DateTimePicker } from "@material-ui/pickers";
import getUserLocale from 'get-user-locale';
import {ElvAVSchedule} from "../../clients/elv-schedule-module"

const maxImageWidth = 64;
const SUBTITLE_SCHEDULE = "Schedule your start and end times.";
const SUBTITLE_RECORDINGS = "View your recordings.";
const RECORDING_POLLING_MS = 1000*10;
const SCHEDULING_TEXT = "Scheduling...";

const styles = theme => ({
    title: {
        marginTop: theme.spacing.unit*2,
        fontSize:"1.2em",
        color: theme.palette.primary.main
    },
    subtitle: {
      marginTop: theme.spacing.unit,
      marginRight: theme.spacing.unit*5,
      fontSize:"1em",
      color: "gray",
      marginBottom: theme.spacing(4)
    },
    fab: {
        width: 70,
        height: 70,
        backgroundColor: "transparent",
        margin: "auto",
        padding: theme.spacing.unit
    },
    image: {
      objectFit:"cover",
      width:400,
      height:200,
      margin: theme.spacing.unit*3,
      marginRight: theme.spacing.unit*5
    },
    accountName: {
        margin: "auto",
        align: 'center',
        maxWidth: 100,
        color: "grey"
    },
    dialog_message: {
        display: "flex",
        marginLeft: "auto",
        marginRight: "auto",
        width: "90%",
        paddingBottom: 0,
        flexShrink:2,
    },
    dialog_actions: {
      display: "flex",
      alignItems: "flex-end",
      flexShrink:2,
      flexGrow:0,
  },
    nomargin: {
        margin: "auto",
        padding: 0,
    },
    dialog_content: {
        display: "flex",
        flexFlow: "wrap",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        flexGrow: 3,
        marginTop: theme.spacing.unit,
        //marginLeft: theme.spacing.unit*2,
       //  marginRight: theme.spacing.unit*2,
        marginBottom: theme.spacing.unit*2,
        overflow:"auto",
        width:"100%"
    },
    noDisplay: {
        display:"none"
    },
    dialog_root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflow:"hidden",
        height: "100%"
    },
    dialog: {
      maxHeight: 600,
      maxWidth: 1000,
      minWidth: 900,
      overflow:"hidden",
    },
    recordings:{
      width:"100%",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    textField: {
        width: "100%",
        height: "100%",
        overflow: "auto"
    },
    imageCard: {
        display: 'flex',
        position: 'relative',
        height: maxImageWidth,
        maxWidth: maxImageWidth,
        minWidth: maxImageWidth,
        marginTop: theme.spacing.unit*2,
        //float: "left",
    },
    cover: {
        objectFit: "contain",
        alignItems: 'center',
    },
    coverCard: {
        display: 'flex',
        position: 'relative',
        height: maxImageWidth,
        maxWidth: "100%",
        marginTop: theme.spacing.unit*2,
        float: "left",
        objectFit: "cover"
    },
    marginTop: {
      marginTop: theme.spacing.unit*2,
    },
    timeButton: {
      marginTop: theme.spacing.unit,
      marginRight: theme.spacing.unit,
    },
    row: {
        display:"flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems:"flex-start",
        width: "100%",
    },
    buttonRow: {
      display:"flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems:"flex-start",
      marginTop: theme.spacing(4),
      width: "95%",
    },
    column: {
        display:"flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignItems: "flex-start",
        width: "100%",
        alignSelf:"flex-start",
        //margin: theme.spacing.unit*2,
    },
    subheading:{
      // marginBottom: theme.spacing.unit*2,
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      fontSize: "1.2em"
    },
    tab_button:{
      maxWidth: 200,
    },
    tabs:{
    },
    button: {
        marginRight: theme.spacing.unit,
        //flexGrow: 0,
    },
    right: {
      display: "flex",
      justifyContent: "flex-end",
      marginRight: theme.spacing.unit * 5,
      width:"100%",
      flexGrow:2
    },
    recording_title: {
      flexGrow: 1,
      fontSize:"1em",
      color: "darkgrey",
      margin: theme.spacing.unit,
      alignSelf:"flex-start"
    },
    feature_description: {
      flexGrow: 1,
      fontSize:"0.8em",
      color: "grey",
      margin: theme.spacing.unit,
      alignSelf:"flex-start",
      marginBottom: theme.spacing(4)
    },
    deleteButton: {
      flexGrow:0,
      alignItems:"flex-start",
      padding: theme.spacing.unit * 0.5,
      margins: theme.spacing.unit,
      color:"grey",
      width: 28,
      height: 28,
      float: "right"
    },
    datetime_container: {
      display: "flex",
      flexWrap: "noWrap",
      fontWeight: 200,
      fontSize: "0.7em",
      flexGrow: 0,
      width: "100%",
      marginBottom: theme.spacing(1),
      paddingLeft:theme.spacing.unit
    },
    datetime: {
      fontWeight: 200,
      fontSize: "0.7em",
      marginRight: theme.spacing(2)
    },
    update_container:{
      display:"flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems:"center",
      width: "100%",
      margins: theme.spacing(1),
      marginLeft: theme.spacing(2)
    },
    refreshing_text:{
      marginLeft: theme.spacing(2)
    }
});


const DialogTitle = withStyles(theme => ({
  root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing.unit * 2,
  },
  closeButton: {
      position: 'absolute',
      right: theme.spacing.unit,
      top: theme.spacing.unit,
      color: theme.palette.grey[500],
  },

}))(props => {
  const { children, classes, onClose } = props;
  return (
      <MuiDialogTitle disableTypography className={classes.root}>
          <div >{children}</div>
          {onClose ? (
              <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                  <CloseIcon />
              </IconButton>
          ) : null}
      </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
      display: "flex",
      flexDirection: "column",
      overflow:"hidden",
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit
  }
}))(MuiDialogActions);

class DVRDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      message: "",
      error: "",
      page:0,
      disableButtons: false,
      title: props.title || "Record Content",
      subtitle: props.subtitle || "Make a recording with the options below.",
      recordings: props.recordings || [],
      obj: props.obj
      //selected content objs
    };
    PubSub.subscribe(this, PubSub.TOPIC_OPEN_DVR, (msg, data) => {
      if(!data || this.state.open){
        return;
      }

      if(!data.obj &&  !data.objectId){
        console.log("DVRDialog error: must supply obj or objectId.");
        return;
      }

      if(!data.elvSchedule){
        console.log("DVRDialog error: must supply elvSchedule");
        return;
      }

      let page = 0;
      if(data.pageIndex === 1){
        page=1;
      }

      let objectId = data.objectId;
      let obj = data.obj;
      let program = data.program;
      let elvSchedule = data.elvSchedule;

      let startTimeEpoch = program.start_time_epoch;
      let durationSec = program.duration_sec;

      let startDate = new Date(0); // The 0 there is the key, which sets the date to the epoch
      startDate.setUTCSeconds(startTimeEpoch);

      let endDate = new Date(0);
      endDate.setUTCSeconds(startTimeEpoch + durationSec);


      if(!objectId){
        objectId = obj.id;
      }

      PubSub.publish(PubSub.TOPIC_PROGRESS_START);
      
  
      Continuum.client.ContentObjectLibraryId({objectId}).then(
        async libraryId => {
          if(!obj){
            obj = await Continuum.contentData.get({libraryId,objectId,validate:false
            })
          }

          console.log(`Initializing AVStream: ${libraryId}, ${objectId}, ${obj.type}`);
          let avstream = new ElvAVStream({libraryId, objectId, typeHash:obj.type, client: this.client, ElvAVSchedule: ElvAVSchedule});
          avstream.setClient(Continuum.client);
          this.setState({avstream});
          console.log("AVStream set up.");
          this.refresh();
        }
      );

      let orgStartDate = startDate;
      let orgEndDate = endDate;

      try{
        this.setState({
          page,
          open: true,
          checked: [],
          videos: [],
          error: "",
          message: "",
          disableButtons: false,
          orgStartDate,
          orgEndDate,
          startDate,
          endDate,
          recordings:data.recordings,
          program,
          elvSchedule,
          recordable: data.recordable
        });
      }catch(e){
        console.log("Error checking stream status: " + JQ(e));
      }

      PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);

    });
    PubSub.subscribe(this, PubSub.TOPIC_CLOSE_DIALOG, (msg, data) => {
      this.setState({ open: false, disableButtons: false });
    });

    PubSub.subscribe(this, PubSub.TOPIC_REFRESH, (msg, data) => {
      this.refresh();
    });

    /*if(!this.recordingInterval){
      this.recordingInterval = setInterval(this.refresh, RECORDING_POLLING_MS);
    }*/
  }

  componentDidMount() {}

  /*
  handleRecordSeries = async event => {
    if(!this.state.program || !this.state.program.series_id || !this.state.elvSchedule){
      return;
    }

    let program = this.state.program;
    let elvSchedule = this.state.elvSchedule;

    PubSub.publish(PubSub.TOPIC_PROGRESS_START);
    let recData = null;
    //try{
      recData = await elvSchedule.recordSeries(program.series_id);

      console.log("Recording created: " + recData);
    //}catch(e){
      if(!recData){
        console.error("Recording error");
        this.setState({error:"Could not create recording."});
      }
    //}

    if(recData && recData.recordingHash){
      //Get
      await this.refresh();
      this.setState({page:1});
      PubSub.publish(PubSub.TOPIC_REFRESH_RECORDINGS);
    }
    PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
  };

  handleRecordProgram = async event => {
    if(!this.state.program || !this.state.program.program_id || !this.state.elvSchedule || !this.state.avstream){
      return;
    }

    let program = this.state.program;
    let elvSchedule = this.state.elvSchedule;

    PubSub.publish(PubSub.TOPIC_PROGRESS_START);
    let recData = null;
    try{
      console.log("Recording program: " + JQ(program));
      recData = await elvSchedule.recordProgram(program.program_id);
      console.log("Recording created: " + recData);
    }catch(e){
      console.error("Recording error: " + JQ(e));
      this.setState({error:"Could not create recording."});
    }

    if(recData && recData.recordingHash){
      //Get
      await this.refresh();
      this.setState({page:1});
      PubSub.publish(PubSub.TOPIC_REFRESH_RECORDINGS);
    }
    PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
  };
*/

  handleRecordTime = async event => {
    if(!this.state.avstream){
      return;
    }
    try{
      this.setState({operation:SCHEDULING_TEXT});
      let program = this.state.program;
      let recData = null;
      let programId = program.program_id;

      recData = await this.state.avstream.createProgramRecording(
        programId,
        this.state.startDate, 
        this.state.endDate);
      if(!recData){
        console.error("Recording error");
        this.setState({error:"Could not create recording."});
      }
      console.log("Recording created: " + JQ(recData));
      if(recData){
        this.refresh();
        let recordings = this.state.recordings;
        let {startTime, endTime} = await this.state.avstream.recordingTimes(recData.id);

        if(startTime.getFullYear() > 2000){
          recData.startTime = startTime;
        }
        
        if(endTime.getFullYear() > 2000){
          recData.endTime = endTime;
        };

        recordings.push(recData);

        this.setState({page:1, recordings});
      }

    }catch(e){
      console.error("Recording error: " + JQ(e));
      this.setState({error:"Could not create recording."});
    }
    this.setState({operation:null});
  };

  handleClose = event => {
    this.setState({ open: false });
  };

  handleStartDateChange = (date) => {
    this.setState({startDate:date});
  }

  handleEndDateChange = (date) => {
    this.setState({endDate:date});
  }

  handleChangePage = (event, newValue) => {
    let subtitle = "";
    switch(newValue){
    case 0:
        subtitle = SUBTITLE_SCHEDULE;
        break;
    default:
        {
          subtitle = SUBTITLE_RECORDINGS;
        }
    }
    this.setState({
        page:newValue,
        error: "",
        subtitle
    });
    this.refresh();
  }

  onInfoClicked = obj => {
    console.log("onInfoClicked - " + JQ(obj));
    if (!isEmpty(obj)) {
      PubSub.publish(PubSub.TOPIC_OPEN_JSONVIEW, obj);
    }
  };

  handleRecordingClick = obj => {
    console.log("handleRecordingClick - " + JQ(obj));
    if (!isEmpty(obj)) {
      PubSub.publish(PubSub.TOPIC_PLAY_RECORDING, obj);
    }
    this.handleClose();
  };

  onDeleteRecordingClicked = async obj => {
    console.log("onDeleteRecordingClicked - " + JQ(obj));
    if (!isEmpty(obj) && this.state.avstream) {
      this.setState({operation:"Deleting..."});
      try{
        await Continuum.deleteObject({
          libraryId: await this.state.avstream.getRecordingsLibraryId(), 
          objectId:obj.id});
        
        let recordings = this.state.recordings;
        if(!isEmpty(recordings)){
          recordings = recordings.filter(function( recording ) {
            return recording.id !== obj.id;
          });
          this.setState({recordings});
        }
      }catch(e){
        console.error("Error deleting recording: " + JQ(e));
      }
      this.setState({operation:null});
    }
  };

  refresh = async () => {
    if(!this.state.open || this.isFetching){
      return;
    }
    this.isFetching = true;
    this.setState({operation:"Refreshing..."});
    console.log("DVRDialog refresh.");
    try {
      if(this.state.elvSchedule){
        this.state.elvSchedule.isProgramRecordable().then(recordable => {
          console.log("IS PROGRAM RECORDABLE " + recordable);
          this.setState({recordable});
        });
      }

      if(this.state.avstream){
        let recordings = await this.state.avstream.findRecordings(true);
        console.log(`Found ${recordings.length} recordings`);
        let newRecordings = [];
        for(var i=0; i < recordings.length; i++){
          let recording = recordings[i];
          if(isEmpty(recording)){
            continue;
          }

          let {startTime, endTime} = await this.state.avstream.recordingTimes(recording.id);

          if(startTime.getFullYear() > 2000){
            recording.startTime = startTime;
          }
          
          if(endTime.getFullYear() > 2000){
            recording.endTime = endTime;
          };

          newRecordings.push(recording);
        }
        this.setState({operation:null,recordings:newRecordings});
      }
    }catch(error){
      console.log("Error refreshing recordings: " + JQ(error));
    }
    this.setState({operation:null});
    this.isFetching = false;
    console.log("DVRDialog refresh finished.");
  }

  renderSchedule = () => {
    const { classes } = this.props;
    let { startDate, endDate, program, recordable, operation } = this.state;
    if(!program){
      return "";
    }
    let disableButtons = false;
    if(!isEmpty(operation) && operation === SCHEDULING_TEXT){
      disableButtons = true;
    }

    disableButtons |= !recordable;

    return (
      <div className={classes.row}>
        <CardMedia
          className={classes.image}
          image={program.program_image_url + "?sig="+program.program_id}
          title={program.title}
        />
        <div className={classes.column}>
          <Typography className={classes.title}>
            {program.title + (!recordable ? " (Recording Disabled)" : "")}
          </Typography>
          <Typography className={classes.subtitle}>
            {program.description}
          </Typography>
          <Grid container spacing={3} className={classes.dialog_content}>
            <Grid item xs={6}>
              <KeyboardDateTimePicker
                disabled={disableButtons}
                disableToolbar
                label="Start Date"
                value={startDate}
                onChange={this.handleStartDateChange}
                onError={console.log}
                // disablePast
                format={localeDateTime()}
                locale={getUserLocale()}
              />
              <div className={classes.row}>
                <Button disabled={disableButtons} 
                    onClick={()=>{
                      this.setState({startDate:this.state.orgStartDate});
                    }} 
                    className={classes.timeButton}
                    size="small"
                    variant="outlined"
                    color="primary">
                    Reset
                </Button>
                <Button disabled={disableButtons} 
                  onClick={()=>{
                    this.setState({startDate:new Date()});
                  }}
                  className={classes.timeButton}
                  size="small"
                  variant="outlined"
                  color="primary">
                    Now
                </Button>
              </div>
            </Grid>
            <Grid item xs={6}>
            <KeyboardDateTimePicker
              disabled={disableButtons}
              disableToolbar
              variant="inline"
              label="End Date"
              value={endDate}
              onChange={this.handleEndDateChange}
              onError={console.log}
              // disablePast
              format={localeDateTime()}
              locale={getUserLocale()}
            />
            <div className={classes.row}>
                <Button disabled={disableButtons} 
                    onClick={()=>{
                      this.setState({endDate:this.state.orgEndDate});
                    }} 
                    className={classes.timeButton}
                    size="small"
                    variant="outlined"
                    color="primary">
                    Reset
                </Button>
                <Button disabled={disableButtons} 
                  onClick={()=>{
                    var diff = 1;
                    var newDateObj = new Date(this.state.endDate.getTime() + diff*60000);
                    this.setState({endDate:newDateObj});
                  }}
                  className={classes.timeButton}
                  size="small"
                  variant="outlined"
                  color="primary">
                    +1
                </Button>

                <Button disabled={disableButtons} 
                  onClick={()=>{
                    var diff = 5;
                    var newDateObj = new Date(this.state.endDate.getTime() + diff*60000);
                    this.setState({endDate:newDateObj});
                  }}
                  className={classes.timeButton}
                  size="small"
                  variant="outlined"
                  color="primary">
                    +5
                </Button>
              </div>
            </Grid>
          </Grid>
          <div className={classes.buttonRow}>
            <Button disabled={disableButtons} className={classes.button} variant="contained" onClick={this.handleRecordTime} color="primary">
                  Schedule Recording
            </Button>
            <Button disabled={true/*disableButtons*/} className={classes.button} variant="contained" onClick={this.handleRecordSeries} color="primary">
                  Schedule Series
            </Button>
          </div>
        </div>
      </div>
    );
  };

  renderRecordings = ({classes, heading, recordings, disableButtons}) => {
    if(!recordings || recordings.length === 0){
      return (
        <Typography noWrap gutterBottom variant="h5" className={classes.subheading}>
            No recordings.
        </Typography>
      );
    }

    console.log("render recordings");

    return (
      <List disabled={disableButtons} className={classNames(classes.noMargins, classes.recordings)}>
      <div />
        {recordings.map((obj,index) => (
        <ListItem key={index} className={classNames(classes.row, classes.marginTop)}>
            <Card className={classNames(classes.row)}>
              <IconButton
                  aria-label="Info"
                  color="primary"
                  onClick={e => {
                      this.onInfoClicked(obj);
                  }}>
                  <InfoIcon />
              </IconButton>

              <Button 
                onClick={e=>{
                    this.handleRecordingClick(obj);
                }}
                className={classes.row}
              >
        
              <div className={classNames(classes.column)}>
                <Typography noWrap gutterBottom variant="subtitle1" className={classes.recording_title}>
                    {obj.meta.program_title ? obj.meta.program_title : obj.meta.name}
                </Typography>
                { 
                  !isEmpty(obj.endTime) && !isEmpty(obj.startTime)?
                  <div className={classes.datetime_container}>
                      <DateTimePicker
                        disableToolbar
                        label="Start Date"
                        value={obj.startTime}
                        onError={console.log}
                        readOnly
                        format={localeDateTime()}
                        className={classes.datetime}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        locale={getUserLocale()}
                      />
                      <DateTimePicker
                        disableToolbar
                        label="End Date"
                        value={obj.endTime}
                        onError={console.log}
                        readOnly
                        format={localeDateTime()}
                        className={classes.datetime}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        locale={getUserLocale()}
                      />

                  </div>
                  
                  : ""
                }
                {obj.meta.description ? 
                <Typography component="p" className={classes.feature_description}>
                    {obj.meta.description}
                </Typography>
                : ""}
              </div>
              </Button>
              <IconButton 
                className={classes.deleteButton}
                disabled={disableButtons}
                onClick={e => {
                    this.onDeleteRecordingClicked(obj);
                }}
              >
              <DeleteIcon              
                color="grey"
                />
              </IconButton>
            </Card>
        </ListItem>
        ))}
      </List>
    );
  }

  render() {
    const {
      open,
      message,
      title,
      recordings,
      recordable,
      operation,
      page,
      error,
    } = this.state;
    const { classes } = this.props;
    if (!open) {
      return "";
    }

    let disableButtons = false;
    if(!isEmpty(operation)){
      disableButtons = true;
    }

    return (
      <Dialog
        disableBackdropClick
        open={open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialog}}
      >
      <div className={classes.dialog_root}>
      <DialogTitle id="form-dialog-title">
          <div className={classNames(classes.row, classes.marginTop)} >
              <IconButton
                  aria-label="verification"
                  color="primary"
              >
                  <TitleIcon />
              </IconButton> 
          <Typography gutterBottom variant="h2" className={classes.title}>
              {title}
          </Typography>
          </div>
          <div className={classes.tabs}>
              <Tabs
                  value={page}
                  onChange={this.handleChangePage}
                  variant="standard"
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="tabs"
                  disabled={disableButtons}
              >
                  <Tab icon={<ScheduleIcon />} label="Schedule" className={classes.tab_button}/>
                  <Tab icon={<RecordIcon />} label="Recordings" className={classes.tab_button}/>
              </Tabs>
          </div>
      </DialogTitle>
      <DialogContent className={classes.dialog_content}>
          <div className={classes.nomargin}>
            {page === 0 ? this.renderSchedule() : 
            this.renderRecordings({classes,
            heading:"Recordings",
            recordings,
            disableButtons
            }) 
            }
          </div>
      </DialogContent>
        <div className={classes.dialog_message}>
          <Typography align='center' gutterBottom noWrap variant="subtitle1" color="textSecondary">
              {message}
          </Typography>
          <Typography align='center' variant="subtitle1" color="error">
              {error}
          </Typography>
        </div>
        <DialogActions className={classes.dialog_actions}>
          {disableButtons && operation?
            <div className={classes.update_container}>
              <Typography className={classes.refreshing_text} align='left' variant="subtitle1" color="primary">
                {operation}
              </Typography>
            </div> : ""
          }
          <Button onClick={this.handleClose} color="primary">
              Close
          </Button>
        </DialogActions>
        </div>
      </Dialog>
    );
  }
}

DVRDialog.propTypes = {
  open: PropTypes.bool.isRequired
};

export default withStyles(styles)(DVRDialog);
