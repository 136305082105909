import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import PubSub from '../../pubsub'
import UserIcon from '@material-ui/icons/Person';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CloseIcon from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import Continuum from "../../clients/continuum"
import Accounts from "../../storage/accounts"
import DeleteIcon from "@material-ui/icons/Clear";
import { isEmpty, JQ } from '../../utils/helpers';
import Fade from '@material-ui/core/Fade';

const styles = theme => ({
    title: {
        flexGrow: 1,
        marginTop: theme.spacing.unit
    },
    fab: {
        width: 70,
        height: 70,
        backgroundColor: "transparent",
        margin: "auto",
        padding: theme.spacing.unit
    },
    image: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
    },
    accountName: {
        margin: "auto",
        align: 'center',
        maxWidth: 100,
        color: "grey"
    },
    dialog_message: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: "90%",
        paddingBottom: 0,
    },
    nomargin: {
        margin: "auto",
        padding: 0,
    },
    left: {
        float: "left"
    },
    dialog_content: {
        display: "flex",
        flexFlow: "wrap",
        flexDirection: "row",
        justifyContent: "spaceBetween",
        flexGrow: 1,
        width: "100%",
        maxHeight: 350,
    },
    dialog_root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "spaceBetween",
        //maxWidth: 350,
        //maxHeight: 500,
        //overflow: "hidden"
    },
    textField: {
        //display: "block",
        width: "100%"
    },
    deleteButton: {
        position:"absolute",
        flexGrow:0,
        alignItems:"flex-start",
        padding: theme.spacing.unit * 0.5,
        margins: theme.spacing.unit,
        color:"grey",
        width: 28,
        height: 28,
        float: "right",
        top:0,
        right:0
      },
});

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },

}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <div >{children}</div>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing.unit * 5
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit,
    },
}))(MuiDialogActions);

class SignInDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            page: 0,
            message: "",
            error: "",
            selectedAccount: "",
            accounts:{},
            confirmed: false,
        };
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleMetaMask = this.handleMetaMask.bind(this);
        this.handleNewAccount = this.handleNewAccount.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleAccountSelected = this.handleAccountSelected.bind(this);
        this.handleDeleteAccount = this.handleDeleteAccount.bind(this);

        PubSub.subscribe(this,PubSub.TOPIC_OPEN_SIGNIN, (msg, data) => {
            if(!Continuum.isFrameClient){
                this.setState({ open: true, error: "", message: "", account: null, confirmed: false });
                this.loadAccounts();
            }
        });
        PubSub.subscribe(this,PubSub.TOPIC_CLOSE_DIALOG, (msg, data) => {
            this.setState({ open: false });
        });
    }

    async loadAccounts(){
        let accounts = Accounts.getAccounts();
        console.log("Found accounts: " + JQ(accounts));
        this.setState({accounts:accounts});
    }

    async componentWillMount() {
        console.log("Signin componentWillMount");
    }

    handlePasswordChange(event) {
        this.setState({ password: event.target.value })
    }

    async handleConfirm(event) {
        console.log("Signin handleConfirm " + JQ(this.state.selectedAccount));
        if (isEmpty(this.state.selectedAccount))
            return;

        try{
            await Continuum.addSignerFromEncrypted(
                this.state.selectedAccount.name,
                this.state.selectedAccount.image,
                this.state.selectedAccount.encryptedPrivateKey,
                this.state.password);
            this.handleClose(event);
        }catch(e){
            console.log("Could not add account: " + JQ(e));
            this.setState({error:"Password incorrect."});
        }

    }

    handleAccountSelected(account) {
        console.log("Account selected: " + account.name);
        this.setState({
            selectedAccount:account,
            message: account.address
        });
    }

    handleClose(event) {
        this.setState({ open: false });
    }

    handleMetaMask(event) {
        this.getAccount();
        this.setState({selectedAccount:"MetaMask"});
    }

    handleNewAccount(event) {
        this.setState({ open: false });
        PubSub.publish(PubSub.TOPIC_OPEN_ADDACCOUNT);
    }

    handleDeleteAccount(account) {
        console.log("Account Delete");
        if(!account){
            return;
        }
        Accounts.deleteAccount(account.address);
        this.loadAccounts();
    }

    componentDidMount() {
        console.log("Singin componentDidMount()");
    }

    RenderAccountsSelection = (props) =>{
        const {state,classes, handleAccountSelected, handleDeleteAccount, handleNewAccount} = props;
        const { accounts, selectedAccount, hovered} = state;
        // console.log("RenderAccountsSelection " + JQ(selectedAccount));
        // console.log("state: " + JQ(state));
        if(isEmpty(classes) || isEmpty(accounts))
            return "";

        return (
            <div className={classes.dialog_content}>
            {/*
                <Tooltip title="Use the selected MetaMask account." aria-label="MetaMask">
                    <List>
                        <ListItem button selected ={"MetaMask" === selectedAccount} className={classes.fab}
                            onClick={() => handleMetaMask()}
                        >
                            <img src={MetaMaskImage} alt='Meta Mask' className={classes.image}></img>
                        </ListItem>
                        <ListItem>
                            <Typography noWrap className={classes.accountName}>MetaMask</Typography>
                        </ListItem>
                    </List>
                </Tooltip>
                */}
                {
                    Object.keys(accounts).map( address => {
                        let accountObj = accounts[address];
                        return (
                            <Tooltip title={accountObj.address} aria-label={accountObj.name} >
                                <List>
                                    <ListItem 
                                        button
                                        selected={accountObj.address === selectedAccount} 
                                        className={classes.fab}
                                        onClick={(event) => {
                                            console.log("ListItem onClick: " + this.state.deleteHovered);
                                            this.state.deleteHovered? handleDeleteAccount(accountObj) : handleAccountSelected(accountObj);
                                            }}
                                        onMouseEnter={()=>{this.setState({hovered:true})}}
                                        onMouseMove={()=>{this.setState({hovered:true})}}
                                        onTouchMove={()=>{this.setState({hovered:true})}}
                                        onMouseLeave={()=>{this.setState({hovered:false})}}
                                        >
                                        {isEmpty(accountObj.image) ? 
                                            <UserIcon className={classes.image} color="primary" /> :
                                            <img src={accountObj.image} alt={accountObj.name} className={classes.image}></img>
                                        }
                                        <Fade in={hovered} >
                                        <IconButton 
                                            className={classes.deleteButton} 
                                            onClick={(event) => {handleDeleteAccount(accountObj);}}
                                            onMouseEnter={()=>{this.setState({deleteHovered:true})}}
                                            onMouseMove={()=>{this.setState({deleteHovered:true})}}
                                            onTouchMove={()=>{this.setState({deleteHovered:true})}}
                                            onMouseLeave={()=>{this.setState({deleteHovered:false})}}
                                            >
                                            <DeleteIcon              
                                            color="grey"
                                            />
                                        </IconButton>
                                    </Fade>
                                    </ListItem>
                                   
                                    <ListItem>
                                        <Typography noWrap className={classes.accountName}>{accountObj.name}</Typography>
                                    </ListItem>
                                </List>
                            </Tooltip>
                        );
                    })
                }
                <Tooltip title="Create or Import Account" aria-label="Add">
                    <List>
                        <ListItem button aria-label="Add" className={classes.fab}
                                onClick={handleNewAccount}
                            >
                                <Add className={classes.image} color="primary" />
                        </ListItem>
                        <ListItem className={classes.accountName}>
                            <Typography noWrap className={classes.accountName}>Add</Typography>
                        </ListItem>
                    </List>
                </Tooltip>
            </div>
        );
    };  

    RenderPage = () => {
        const {classes} = this.props;
        return (
            <this.RenderAccountsSelection 
                classes={classes} 
                state={this.state} 
                handleMetaMask = {this.handleMetaMask}
                handleAccountSelected = {this.handleAccountSelected}
                handleNewAccount = {this.handleNewAccount}
                handleDeleteAccount = {this.handleDeleteAccount}
                />
        );
    }

    render() {
        const { open, message, error} = this.state;
        const {classes} = this.props;

        console.log("SignIn Render " + JSON.stringify(open));
        if (!open) {
            return "";
        }   

        return (
            <Dialog
                disableBackdropClick
                open={open}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <div className={classes.dialog_root}>
                    <DialogTitle id="form-dialog-title">
                        <Typography gutterBottom variant="h5" className={classes.title}>
                            Sign In
                        </Typography>
                            <Typography gutterBottom variant="subtitle1" color="textSecondary">
                                Please select the following options to access your content.
                    </Typography>
                    </DialogTitle>
                    <DialogContent className={classes.nomargin}>
                        <this.RenderPage />
                    </DialogContent>
                    <div className={classes.dialog_message}>
                        <Typography align='center' variant="subtitle1" color="error">
                            {error}
                        </Typography>
                        {this.state.selectedAccount ? <div>
                       <TextField
                            label="Selected Address"
                            className={classes.textField}
                            value={message}
                            margin="normal"
                            disabled={!this.state.selectedAccount}
                        />

                        <TextField
                            label="Password"
                            placeholder="Enter Password"
                            type = "password"
                            className={classes.textField}
                            value={this.state.password || ""}
                            onChange={this.handlePasswordChange}
                            disabled={!this.state.selectedAccount}
                            margin="normal"
                        /> </div>: ''
                        }
                    </div>
                    <DialogActions>
                        <Button disabled={!this.state.selectedAccount} onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button disabled={!this.state.selectedAccount} onClick={this.handleConfirm} color="primary">
                            Confirm
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        );
    }
}


SignInDialog.propTypes = {
    open: PropTypes.bool.isRequired
};

export default withStyles(styles)(SignInDialog);