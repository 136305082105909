import ps from 'pubsub-js'
import { isEmpty } from '../utils/helpers';
//import { JQ } from '../utils/helpers';

//Publish Subscribe class for this app. TODO: pubsub across server
class PubSub{
    subscriptions={

    }
    constructor(){
        this.TOPIC_CURRENT_VIDEO = Symbol('CURRENT_VIDEO');
        this.TOPIC_CURRENT_USER = Symbol('CURRENT_USER');
        this.TOPIC_VIEW_LIST_TOGGLE= Symbol('VIEW_LIST_TOGGLE');
        this.TOPIC_OPEN_SIGNIN= Symbol('OPEN_SIGIN');
        this.TOPIC_OPEN_ADDACCOUNT= Symbol('OPEN_ADDACCOUNT');
        this.TOPIC_CLOSE_DIALOG= Symbol('CLOSE_DIALOG');
        this.TOPIC_OPEN_NEWCHANNEL= Symbol('OPEN_NEWCHANNEL');
        this.TOPIC_OPEN_UPLOAD= Symbol('OPEN_UPLOAD');
        this.TOPIC_OPEN_IMPORT= Symbol('OPEN_IMPORT');
        this.TOPIC_OPEN_SAVE= Symbol('OPEN_SAVE');
        this.TOPIC_OPEN_VERIFICATION= Symbol('OPEN_VERIFICATION');    
        this.TOPIC_OPEN_JSONVIEW = Symbol('OPEN_JSONVIEW');
        this.TOPIC_OPEN_SEARCH = Symbol('OPEN_SESAME');
        this.TOPIC_OPEN_DVR= Symbol('TOPIC_OPEN_DVR');
        this.TOPIC_CREATE_OFFERING= Symbol('CREATE_OFFERING');
        this.TOPIC_CREATE_CHANNEL= Symbol('CREATE_CHANNEL');
        this.TOPIC_IMPORT_CONTENT= Symbol('IMPORT_CONTENT');
        this.TOPIC_NEED_REFRESH= Symbol('TOPIC_NEED_REFRESH');
        this.TOPIC_PROGRESS_START= Symbol('TOPIC_PROGRESS_START');
        this.TOPIC_PROGRESS_DONE= Symbol('TOPIC_PROGRESS_DONE');
        this.TOPIC_OPEN_DVR= Symbol('TOPIC_OPEN_DVR');
        this.TOPIC_ERROR= Symbol('TOPIC_ERROR');
        this.TOPIC_PLAY_RECORDING= Symbol('TOPIC_PLAY_RECORDING');
        this.TOPIC_REFRESH_RECORDINGS= Symbol('TOPIC_REFRESH_RECORDINGS');

        this.state = {
            isListPanelOpen: true,
        };
    }

    publish(msg,data){
        if(msg === this.TOPIC_VIEW_LIST_TOGGLE){
            this.state.isListPanelOpen = !this.state.isListPanelOpen;
        }
        return ps.publish(msg,data);
    }

    subscribe(receiver, msg, func){
        if(isEmpty(msg)){
            console.warn("Pubsub: Attempting to subscribe to null message.");
            return;
        }
        ps.subscribe(msg,func);
    }
}

let pubsub = new PubSub();
export default pubsub