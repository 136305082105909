import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import LinkIcon from '@material-ui/icons/InsertLink';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Continuum from "../../clients/continuum"
import PubSub from "../../pubsub"
import './dialogs.css';
import { JQ, isEmpty } from '../../utils/helpers';

const maxImageWidth = 64;

const styles = theme => ({
    title: {
        flexGrow: 1,
        marginTop: theme.spacing.unit
    },
    fab: {
        width: 70,
        height: 70,
        backgroundColor: "transparent",
        margin: "auto",
        padding: theme.spacing.unit
    },
    image: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
    },
    accountName: {
        margin: "auto",
        align: 'center',
        maxWidth: 100,
        color: "grey"
    },
    paper:{
        //width: "100%",
        //backgroundColor: "white"
        //...theme.mixins.gutters(),
        //paddingTop: theme.spacing.unit * 2,
        //paddingBottom: theme.spacing.unit * 2,
        height: 40,
        marginTop: theme.spacing.unit * 2
    },
    search_container:{
        //width: "100%",
        //backgroundColor: "white"
        //...theme.mixins.gutters(),
        //paddingTop: theme.spacing.unit * 2,
        //paddingBottom: theme.spacing.unit * 2,
        //position:"fixed",
        //width: "90%",
        height: 40,
        marginTop: theme.spacing.unit * 2,
        //backgroundColor: "blue"
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing.unit * 2,
        marginLeft: theme.spacing.unit * 2,
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
    },
    inputHash: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        transition: theme.transitions.create('width'),
        flexGrow: 1,
      },
    dialog_message: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: "90%",
        paddingBottom: 0,
    },
    nomargin: {
        margin: 0,
        padding: 0,
    },
    dialog_content: {
        display: "flex",
        flexFlow: "wrap",
        flexDirection: "row",
        justifyContent: "spaceBetween",
        margin: theme.spacing.unit*2,
        flexGrow: 1,
    },
    grow: {
        flexGrow: 5,
    },
    shrink: {
        flexGrow: 0,
    },
    noDisplay: {
        display:"none"
    },
    dialog_root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "spaceBetween",
        //maxWidth: 350,
        //maxHeight: 500,
        //overflow: "hidden"
        height:"100%"
    },
    dialog: {
        height:"80%",
        minWidth: 600
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "spaceBetween",
    },

    textField: {
        //display: "block",
        width: "100%"
    },
    imageCard: {
        display: 'flex',
        position: 'relative',
        height: maxImageWidth,
        maxWidth: maxImageWidth,
        minWidth: maxImageWidth,
        float: "left",
    },
    cover: {
        objectFit: "contain",
        alignItems: 'center',
    },
    coverCard: {
        display: 'flex',
        position: 'relative',
        height: maxImageWidth,
        maxWidth: "100%",
        marginTop: theme.spacing.unit*2,
        float: "left",
        objectFit: "cover"
    },
    row: {
        marginTop: theme.spacing.unit*2,
        display:"flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        overflow:"auto",
        width:"100%"
    },
    rowItem: {
        margin:30
    },
    column: {
        marginTop: theme.spacing.unit*2,
        display:"flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignItems: "space-between",
        padding:theme.spacing.unit,
        overflow:"auto",
    },
    feature_title: {
        flexGrow: 0,
        fontWeight: 400,
        fontSize: "0.8em"
      },
    feature_description: {
        color: "grey",
        fontWeight: 200,
        fontSize: "0.7em",
        flexGrow: 1
    },
    tab_button:{
        maxWidth: 40,
    },
    tabs:{
    },
    button: {
        margin: theme.spacing.unit,
        flexGrow: 0,
    }
});


const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },

}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <div >{children}</div>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        //padding: theme.spacing.unit * 5,
        // height: 100,
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit,
    },
}))(MuiDialogActions);

const SUBTITLE_SEARCH = "Search for published content.";
const SUBTITLE_IMPORT = "Specify a specific content version hash.";

class ImportDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            message: "",
            error: "",
            title: props.title,
            subtitle: SUBTITLE_SEARCH,
            selectedChannelId: "",
            page:0,
            //selected content objs
            checked: [],
            //Result
            videos: [

            ],
        }
        PubSub.subscribe(this,PubSub.TOPIC_OPEN_IMPORT, (msg,data) => {
            this.setState({open:true,
                checked:[],
                videos:[],
                error:"",
                message:"",
                selectedChannelId: data.channelId,
            });

            console.log("Import open: " + JSON.stringify(this.state));
        });
        PubSub.subscribe(this,PubSub.TOPIC_CLOSE_DIALOG, (msg, data) => {
            this.setState({ open: false });
        });
    }

    componentDidMount(){

    }

    handleConfirm = async event => {
        console.log("Import: HandleConfirm " + this.state.selectedChannelId);
        console.log(" Checked for import: " + JQ(this.state.checked.length));
        if(this.state.checked.length > 0){
            PubSub.publish(PubSub.TOPIC_IMPORT_CONTENT,{parentId:this.state.selectedChannelId, imports:this.state.checked});
        }
        this.handleClose(event);
    }

    handleClose = event => {
        this.setState({ open: false });
    }

    handleHashInputChange = async e => {
        console.log("Content hash: " + e.target.value);
        let content = await Continuum.contentData.getByHash(e.target.value);
        console.log("Content: " + JQ(content));
        if(!isEmpty(content)){
            this.setState({
                videos : [content]
            })
        }
    }

    handleSearchChange = async e => {
        this.setState({
            search : e.target.value,checked:[]
        });
        this.handleSearchClick(e.target.value);
    }

    keyPress = async e =>{
        if(e.keyCode === 13){
            this.setState({checked:[]});
            let keywords = e.target.value;
            this.handleSearchClick(keywords);
        }
     }

    handleSearchClick = keywords => {
        let that = this;

        PubSub.publish(PubSub.TOPIC_PROGRESS_START);
        Continuum.find({searchString:keywords}).then(results => {
            console.log("Searched finished: " + JQ(results.length));
            if(!isEmpty(results)){
                that.setState({
                    videos : results
                })
            }else{
                that.setState({
                    videos : []
                })
            }
        PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
        }).catch(err => {
            console.error("Search error: " + JQ(err));
            PubSub.publish(PubSub.TOPIC_PROGRESS_DONE);
        })
    }

    handleImageClick = event => {
        console.log("Image Clicked");
        let path = "./samples/channel1.png";
        this.handleImageChange(path);
    }

    handleChangePage = (event, newValue) => {
        let subtitle = "";
        switch(newValue){
            case 1:
                subtitle = SUBTITLE_IMPORT;
                break;
            default:
                subtitle = SUBTITLE_SEARCH;
        }
        this.setState({
            page:newValue,
            subtitle
        });
    }


    renderHashContents = () => {
        const { checked, videos} = this.state;
        const { classes } = this.props;
        let that = this;

        function ContentList({heading, lib, onClick}) {
            if(!lib || lib.length === 0){
              return "";
            }
            return (
              <List className={classes.noMargins}>
              <Typography noWrap gutterBottom variant="h5" className={classNames(classes.subheading)}>
              {heading}
              </Typography>
                {lib.map((obj,index) => (
                <ListItem key={index} className={classes.row}>
                    <Checkbox
                            checked={Boolean(checked[index])}
                            onChange={e=>{
                                if(checked[index] == null){
                                    checked[index]= obj;
                                }else{
                                    checked[index]= null;
                                }
                                that.setState({checked:checked});
                                }}
                            className={classes.checkbox}
                    />
                    <Card className={classNames(classes.imageCard, classes.checkbox)}>
                        <CardMedia
                                component="img"
                                alt="Thumbnail"
                                className={classes.cover}
                                image= {obj.meta.imageUrl}
                                title="Image"
                        />
                    </Card>
                    <div className={classNames(classes.details,classes.checkbox)}>
                        <CardContent>
                        <Typography noWrap gutterBottom variant="h5" component="h2" className={classes.feature_title}>
                            {obj.meta.name ? obj.meta.name : ""}
                        </Typography>
                        <Typography component="p" className={classes.feature_description}>
                            {obj.meta.description ? obj.meta.description : ""}
                        </Typography>
                        </CardContent>
                    </div>
                </ListItem>  
                ))}
              </List>
            );
        }

        return (
        <div position="relative">
            <Paper className={classes.search_container} elevation={1}>
            <div className={classes.search}>
                <InputBase
                    placeholder="hq__..."
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputHash,
                    }}
                    onChange={this.handleHashInputChange}
                    onKeyDown={this.keyPress}
                />
            </div>
            </Paper>
            <div className={classes.dialog_content}>
                {<ContentList heading="Search Results" lib={videos}/>}
            </div>
        </div>
        );
    }

    renderSearchContents = () => {
        const { checked, videos} = this.state;
        const { classes } = this.props;
        let that = this;

        function ContentList({heading, lib, onClick}) {
            if(!lib || lib.length === 0){
              return "";
            }
            return (
              <List className={classes.noMargins}>
              <Typography noWrap gutterBottom variant="h5" className={classNames(classes.subheading)}>
              {heading}
              </Typography>
                {lib.map((obj,index) => (
                <ListItem key={index} className={classes.row}>
                    <Checkbox
                            checked={Boolean(checked[index])}
                            onChange={e=>{
                                if(checked[index] == null){
                                    checked[index]= obj;
                                }else{
                                    checked[index]= null;
                                }
                                that.setState({checked:checked});
                                }}
                            className={classes.checkbox}
                    />
                    <Card className={classNames(classes.imageCard, classes.checkbox)}>
                        <CardMedia
                                component="img"
                                alt="Thumbnail"
                                className={classes.cover}
                                image= {obj.meta.imageUrl}
                                title="Image"
                        />
                    </Card>
                    <div className={classNames(classes.details,classes.checkbox)}>
                        <CardContent>
                        <Typography noWrap gutterBottom variant="h5" component="h2" className={classes.feature_title}>
                            {obj.meta.name ? obj.meta.name : ""}
                        </Typography>
                        <Typography component="p" className={classes.feature_description}>
                            {obj.meta.description ? obj.meta.description : ""}
                        </Typography>
                        </CardContent>
                    </div>
                </ListItem>  
                ))}
              </List>
            );
        }

        return (
        <div position="relative">
            <Paper className={classes.search_container} elevation={1}>
            <div className={classes.search}>
                  <div className={classes.searchIcon}>
                      <SearchIcon />
                  </div>
                  <InputBase
                      placeholder="Search…"
                      classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                      }}
                      onChange={this.handleSearchChange}
                      onKeyDown={this.keyPress}
                  />
            </div>
            </Paper>
            <div className={classes.dialog_content}>
                {<ContentList heading="Search Results" lib={videos}/>}
            </div>
        </div>
        );
    }

    render() {
        const { open, message, title, subtitle, error, page } = this.state;
        const { classes } = this.props;
        //console.log("SignIn Render " + JSON.stringify(open));
        if (!open) {
            return "";
        }

        return (
            <Dialog
                disableBackdropClick
                open={open}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
                classes={{ paper: classes.dialog}}
            >
            <div className={classes.dialog_root}>
            <DialogTitle id="form-dialog-title">
                <Typography gutterBottom variant="h5" className={classes.title}>
                    {title}
                </Typography>
                <Typography gutterBottom variant="subtitle1" color="textSecondary">
                    {subtitle}
                </Typography>
                <div className={classes.tabs}>
                <Tabs
                    value={page}
                    onChange={this.handleChangePage}
                    variant="standard"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="tabs"
                >
                    <Tab icon={<SearchIcon />} label="Search" className={classes.tab_button}/>
                    <Tab icon={<LinkIcon />} label="Hash" className={classes.tab_button}/>
                </Tabs>
                </div>
            </DialogTitle>
            <DialogContent classes={classes.grow}>
                {page === 0 ? this.renderSearchContents() : this.renderHashContents() }
            </DialogContent>
            <div className={classes.dialog_message}>
                <Typography align='center' gutterBottom noWrap variant="subtitle1" color="textSecondary">
                    {message}
                </Typography>
                <Typography align='center' variant="subtitle1" color="error">
                    {error}
                </Typography>
            </div>
            <div className={classes.shrink}>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={false} onClick={this.handleConfirm} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </div>
            </div>
            </Dialog>
        );
    }
}


ImportDialog.propTypes = {
    open: PropTypes.bool.isRequired
};

export default withStyles(styles)(ImportDialog);