//import React from 'react';
//import {SignInDialog} from '../dialogs'

function WelcomePage(props){
    return (
        //<SignInDialog/>
        ""
    );
}

export default WelcomePage;