import React, { Component } from 'react';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import VideoList from '../contentviews/videolist'
import PubSub from '../../pubsub'
import {JQ,isEmpty} from '../../utils/helpers'
import Continuum from '../../clients/continuum'


const styles = theme => ({

});

class MySites extends Component {

  constructor(props){
    super(props);
    const account = Continuum.getCurrentAccount();
    let name = null;
    if(account){
      name = account.name;
    }
    this.state = {
      account: account,
      title:name? `${name}'s Page`: "",
      sites: [],
    };
    PubSub.subscribe(this, PubSub.TOPIC_NEED_REFRESH, (msg, data) => {
      console.log("My Videos TOPIC_NEED_REFRESH received");
      this.refresh();
    });
  }

  handleNewSite = event => {
    PubSub.publish(PubSub.TOPIC_OPEN_NEWCHANNEL,this.state.selectedChannelId);
  }

  componentWillMount() {
    this.refresh();
  }

  refresh = async () => {
    if(this.isFetching){
      return;
    }
    this.isFetching = true;

    try{
      // let sites = await Continuum.getSites();
      // this.setState({sites});
      
    }catch (err) {
      console.error("Could not fetch channel data: " + JSON.stringify(err));
      return;
    }
    this.isFetching = false;
  };

  render() {
    const { appState } = this.props;
    const { classes } = this.props;
    const { currentAccount } = appState;
    let {sites} = this.state;

    return (
      <div className={classes.root}>
          <Paper className={classNames(classes.paper, classes.column)}>
              <div className={classes.headerContainer}>
                <div className={classes.buttonContainer}>
                  <Button color="primary" className={classes.button}
                    onClick={() => this.handleNewSite()}
                  > 
                    New Site
                  </Button>
                </div>
              </div>
          </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(MySites);